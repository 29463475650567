import React from 'react'
import { Button, Col, Form, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import { AccountXeroOrg, Calendar as CalendarData, Employee } from '../../../../back-end/utilities/apiDefinitions'
import * as AppDefinitions from '../../App.d'

// utillites
import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'
import moment from 'moment'

// components
import * as Messages from '../../components/ui/messages/messages'
import { Edit, Trash2, UserPlus } from 'react-feather'
import LoadingImage from '../../images/Finlert-loading-GIF_100px.gif'
import { MessageAction } from '../ui/messages/message'

interface EmployeesListFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	updateCurrentCalendar: (cal: CalendarData) => void
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

interface EmployeeRowProps {
	appState: AppDefinitions.AppState
	updateOriginalData: React.Dispatch<React.SetStateAction<Employee[]>>
	employees: Employee[]
	updateMessages: (e: MessageAction) => void
	updateEmployees: React.Dispatch<React.SetStateAction<Employee[]>>
	updateCurrentCalendar: (cal: CalendarData) => void
	originalData: Employee[]
	showTitleAndGroup: boolean
	employee: Employee
	index: number
	currentCalendar: CalendarData
	currentOrg: AccountXeroOrg
	isSelected: boolean
}

const initialCustomEmployeeData = {
	firstName: '',
	lastName: '',
	jobTitle: '',
	groupName: '',
	alias: '',
	typeIsCustom: true,
	isActive: true,
	employeeID: '',
	startDate: undefined,
	dateOfBirth: undefined,
}

interface customEmployeeCardProps {
	employee: Employee
	currentCalendar: CalendarData
	currentOrg: AccountXeroOrg
	appState: AppDefinitions.AppState
	show: boolean
	employees: Employee[]
	updateMessages: (e: MessageAction) => void
	type: 'Add' | 'Edit'
	updateCurrentCalendar: (cal: CalendarData) => void
	handleClose: () => void
	handleConfirm?: () => void
}

const CustomEmployeeCard = (props: customEmployeeCardProps) => {
	const [employee, updateEmployee] = React.useState<Employee>(props.type === 'Add' ? initialCustomEmployeeData : props.employee)
	const [originalDataEmployee] = React.useState<Employee>(props.type === 'Add' ? initialCustomEmployeeData : props.employee)

	const deleteCustomEmployee = async () => {
		try {
			await Request.del(`employee/${employee.employeeID}`, props.appState.authState)
			props.updateCurrentCalendar({
				...props.currentCalendar,
				employees: props.employees.filter((e) => e.employeeID !== employee.employeeID),
			})
			props.updateMessages(Messages.addMessage('success', 'Successfully deleted team member'))
			props.handleClose()
		} catch (err) {
			console.log('Add team Member Error: ', err)
			props.updateMessages(Messages.addMessage('danger', 'Failed to delete team member'))
		}
	}

	const updateCustomEmployee = async () => {
		try {
			const req = await Request.put(`employee/${employee.employeeID}`, employee, props.appState.authState)
			const newEmployees = props.employees.map((e: Employee) => {
				if (e.employeeID === props.employee.employeeID) {
					return { ...req.data.employees[0], isActive: true }
				}
				return e
			})

			props.updateMessages(Messages.addMessage('success', 'Successfully updated team member'))
			props.updateCurrentCalendar({
				...props.currentCalendar,
				employees: newEmployees,
			})
			props.handleClose()
		} catch (err) {
			console.log('Update team Member Error: ', err)
			props.updateMessages(Messages.addMessage('danger', 'Failed to update team member'))
		}
	}

	const addCustomEmployee = async () => {
		try {
			const newEmployee = {
				...employee,
				xeroOrgID: props.currentOrg.xeroOrg!.xeroOrgID,
			}
			const req = await Request.post('employee', newEmployee, props.appState.authState)
			props.updateCurrentCalendar({
				...props.currentCalendar,
				employees: props.employees.concat({
					...req.data.employees[0],
					isActive: true,
				}),
			})
			props.updateMessages(Messages.addMessage('success', 'Successfully added team member'))
			props.handleClose()
		} catch (err) {
			console.log('Add team Member Error: ', err)
			props.updateMessages(Messages.addMessage('danger', 'Failed to add team member'))
		}
	}

	return (
		<Modal
			show={props.show}
			onHide={() => {
				updateEmployee(originalDataEmployee)
				props.handleClose()
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title>{props.type === 'Add' ? 'Add Team Member' : 'Edit Team Member'}</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ whiteSpace: 'pre-wrap' }}>
				<div style={{ margin: '10px' }}>
					<Form.Group>
						<Row style={{ paddingBottom: '10px' }}>
							<Col sm="4">
								<Form.Label style={{ paddingTop: '8px' }}>First Name</Form.Label>
							</Col>
							<Col sm="6">
								<Form.Control
									type="text"
									id={`${employee.employeeID}-firstName`}
									name="firstName"
									placeholder=""
									readOnly={false}
									onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
										const value = event.target.value
										updateEmployee({ ...employee, firstName: value })
									}}
									value={employee.firstName}
									style={{ display: 'inline', paddingLeft: '0px' }}
								/>
							</Col>
							{props.type === 'Edit' ? (
								<Col sm="2">
									<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-delete-event`}>Delete team member</Tooltip>}>
										<div style={{ textAlign: 'right' }}>
											<Button style={{ padding: '0px', borderWidth: '0px' }} onClick={() => deleteCustomEmployee()}>
												<Trash2 style={{ fontSize: 'smaller' }} />
											</Button>
										</div>
									</OverlayTrigger>
								</Col>
							) : null}
						</Row>
						<Row style={{ paddingBottom: '10px' }}>
							<Col sm="4">
								<Form.Label style={{ paddingTop: '8px' }}>Last Name</Form.Label>
							</Col>
							<Col sm="6">
								<Form.Control
									type="text"
									id={`${employee.employeeID}-lastName`}
									name="lastName"
									placeholder=""
									readOnly={false}
									onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
										const value = event.target.value
										updateEmployee({ ...employee, lastName: value })
									}}
									value={employee.lastName}
									style={{ display: 'inline', paddingLeft: '0px' }}
								/>
							</Col>
						</Row>
						<Row style={{ paddingBottom: '10px' }}>
							<Col sm="4">
								<Form.Label style={{ paddingTop: '8px' }}>Title</Form.Label>
							</Col>
							<Col sm="6">
								<Form.Control
									type="text"
									id={`${employee.employeeID}-title`}
									name="title"
									placeholder=""
									readOnly={false}
									onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
										const value = event.target.value
										updateEmployee({ ...employee, jobTitle: value })
									}}
									value={employee.jobTitle}
									style={{ display: 'inline', paddingLeft: '0px' }}
								/>
							</Col>
						</Row>
						<Row style={{ paddingBottom: '10px' }}>
							<Col sm="4">
								<Form.Label style={{ paddingTop: '8px' }}>Employee Group</Form.Label>
							</Col>
							<Col sm="6">
								<Form.Control
									type="text"
									id={`${employee.employeeID}-group`}
									name="groupName"
									placeholder=""
									readOnly={false}
									onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
										const value = event.target.value
										updateEmployee({ ...employee, groupName: value })
									}}
									value={employee.groupName}
									style={{ display: 'inline', paddingLeft: '0px' }}
								/>
							</Col>
						</Row>
						<Row style={{ paddingBottom: '10px' }}>
							<Col sm="4">
								<Form.Label style={{ paddingTop: '8px' }}>Alias</Form.Label>
							</Col>
							<Col sm="6">
								<Form.Control
									type="text"
									id={`${employee.employeeID}-alias`}
									name="alias"
									placeholder=""
									readOnly={false}
									onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
										const value = event.target.value
										updateEmployee({ ...employee, alias: value })
									}}
									value={employee.alias}
									style={{ display: 'inline', paddingLeft: '0px' }}
								/>
							</Col>
						</Row>
						<Row style={{ paddingBottom: '10px' }}>
							<Col sm="4">
								<Form.Label style={{ paddingTop: '8px' }}>Date of Birth</Form.Label>
							</Col>
							<Col sm="6">
								<Form.Control
									type="date"
									id={`${employee.employeeID}-dateOfBirth`}
									name="dateOfBirth"
									placeholder=""
									readOnly={false}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										const value = event.target.value
										const date = moment.utc(new Date(value))
										updateEmployee({
											...employee,
											dateOfBirth: date.isValid() ? date.format('yyyy-MM-DD hh:mm:ss') : null,
										})
									}}
									value={employee.dateOfBirth?.substring(0, 10)}
									style={{ paddingLeft: '0px' }}
								/>
							</Col>
						</Row>
						<Row style={{ paddingBottom: '10px' }}>
							<Col sm="4">
								<Form.Label style={{ paddingTop: '8px' }}>Start Date</Form.Label>
							</Col>
							<Col sm="6">
								<Form.Control
									type="date"
									id={`${employee.employeeID}-startDate`}
									name="startDate"
									placeholder=""
									readOnly={false}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										const value = event.target.value
										const date = moment.utc(new Date(value))
										updateEmployee({
											...employee,
											startDate: date.isValid() ? date.format('yyyy-MM-DD hh:mm:ss') : null,
										})
									}}
									value={employee.startDate?.substring(0, 10)}
									style={{ paddingLeft: '0px' }}
								/>
							</Col>
						</Row>
					</Form.Group>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant={'secondary'}
					onClick={() => {
						updateEmployee(originalDataEmployee)
						props.handleClose()
					}}
				>
					{'Cancel'}
				</Button>
				<Button
					variant={'success'}
					onClick={() => {
						props.type === 'Add' ? addCustomEmployee() : updateCustomEmployee()
					}}
				>
					{'Confirm'}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

const EmployeeRow = (props: EmployeeRowProps) => {
	const [isAliasDisabled, updateAliasDisabled] = React.useState<boolean>(true)
	const [showCustomEmployeeCard, updateShowCustomEmployeeCard] = React.useState<boolean>(false)

	const postAliasChange = async () => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			try {
				await Request.put(`employee/${props.employee.employeeID}`, props.employee, props.appState.authState)
				props.updateOriginalData(props.employees)
				props.updateMessages(Messages.addMessage('success', 'Successfully updated employee alias'))
			} catch (err) {
				console.log('Update Error: ', err)
				props.updateMessages(Messages.addMessage('danger', 'Failed to update employee alias'))
			}
		} else {
			props.updateMessages(Messages.addMessage('danger', 'You do no have permission to update an employee alias'))
		}
	}
	const resetAlias = async () => {
		props.updateEmployees(props.originalData)
	}

	const handleEmployeeCheck = async () => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			try {
				await Request.put(
					`employee/calendar`,
					{
						employeeID: props.employee.employeeID,
						isActive: !props.employee.isActive,
						calendarID: props.currentCalendar.calendarID,
					},
					props.appState.authState
				)
				const newEmpls = props.currentCalendar.employees!.map((e) => {
					if (e.employeeID === props.employee.employeeID) {
						return { ...e, isActive: !props.employee.isActive }
					}
					return e
				})
				props.updateCurrentCalendar({
					...props.currentCalendar,
					employees: newEmpls,
				})
				props.updateMessages(Messages.addMessage('success', 'Successfully updated employee'))
			} catch (err) {
				console.log('Update Error: ', err)
				props.updateMessages(Messages.addMessage('danger', 'Failed to update employee'))
			}
		} else {
			props.updateMessages(Messages.addMessage('danger', 'You do no have permission to update an employee'))
		}
	}
	return (
		<tr key={props.employee.employeeID} className={`stripe-row ${props.index % 2 !== 0 ? 'stripe-row-body' : ''}`}>
			{props.currentCalendar.isCustomGroup && !props.currentCalendar.employeeGroupName ? (
				//checkboxes before names and alias on read only otherwise normal
				<td>
					<Form.Check
						type={'checkbox'}
						id={props.employee.employeeID}
						label={`${props.employee.firstName} ${props.employee.lastName}`}
						checked={props.isSelected}
						onChange={handleEmployeeCheck}
						style={{ padding: '12px', marginLeft: '10px' }}
					/>
				</td>
			) : (
				<td>
					{props.employee.firstName} {props.employee.lastName}
				</td>
			)}

			{props.showTitleAndGroup ? (
				<>
					<td>{props.employee.jobTitle}</td>
					<td>{props.employee.groupName}</td>
				</>
			) : null}
			<td>
				{props.currentCalendar.isCustomGroup ? (
					<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-alias`}>Set your alias in a &lsquo;All employees&rsquo; calendar</Tooltip>}>
						<span style={{ display: 'inline', width: '50%', paddingLeft: '0px' }}>{props.employee.alias || ''}</span>
					</OverlayTrigger>
				) : (
					<>
						<Form.Control
							type="text"
							id={props.employee.employeeID}
							name="alias"
							placeholder=""
							readOnly={isAliasDisabled || I.have('general', props.appState.permission[props.appState.context])}
							plaintext={isAliasDisabled || I.have('general', props.appState.permission[props.appState.context])}
							onClick={() => updateAliasDisabled(false)}
							onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
								const value = event.target.value
								const newEmployees = props.employees.map((e: Employee) => {
									if (e.employeeID === props.employee.employeeID) {
										return { ...e, alias: value === '' ? undefined : value }
									}
									return e
								})
								props.updateEmployees(newEmployees)
							}}
							value={props.employee.alias || ''}
							style={{ display: 'inline', width: '50%', paddingLeft: '0px' }}
							className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : ''}
						/>
						{!isAliasDisabled && !I.have('general', props.appState.permission[props.appState.context]) ? (
							<Button
								type="button"
								style={{ margin: '-5px 5px 0px 5px' }}
								variant="success"
								onClick={() => {
									updateAliasDisabled(true)
									postAliasChange()
								}}
							>
								Save
							</Button>
						) : null}
						{!isAliasDisabled && !I.have('general', props.appState.permission[props.appState.context]) ? (
							<Button
								type="button"
								style={{ margin: '-5px 5px 0px 5px' }}
								onClick={() => {
									updateAliasDisabled(true)
									resetAlias()
								}}
							>
								Cancel
							</Button>
						) : null}
					</>
				)}
			</td>
			<td>{props.employee.typeIsCustom ? 'LeaveCal' : props.currentOrg.xeroOrg?.apiProvider === 'keypay' ? 'Keypay' : 'Xero'}</td>
			{I.have('admin', props.appState.permission[props.appState.context]) &&
			props.employee.typeIsCustom &&
			!props.currentCalendar.isCustomGroup &&
			(props.currentCalendar.employeeGroupName === null || props.currentCalendar.employeeGroupName === undefined) ? (
				<td>
					<Button
						type="button"
						onClick={() => {
							updateShowCustomEmployeeCard(true)
						}}
					>
						<Edit /> Edit
					</Button>
				</td>
			) : (
				<td />
			)}
			<CustomEmployeeCard
				employee={props.employee}
				show={showCustomEmployeeCard}
				type={'Edit'}
				handleClose={() => updateShowCustomEmployeeCard(false)}
				updateMessages={props.updateMessages}
				appState={props.appState}
				currentCalendar={props.currentCalendar}
				currentOrg={props.currentOrg}
				employees={props.employees}
				updateCurrentCalendar={props.updateCurrentCalendar}
			/>
		</tr>
	)
}

const EmployeesList = (props: EmployeesListFrameProps) => {
	const [employees, updateEmployees] = React.useState<Employee[] | []>([])
	const [originalData, updateOriginalData] = React.useState<Employee[] | []>([])
	const [messages, updateMessages] = Messages.useMessageReducer([])
	const [showCustomEmployeeCard, updateShowCustomEmployeeCard] = React.useState<boolean>(false)

	React.useEffect(
		() => {
			const fetchData = async () => {
				updateEmployees(props.currentCalendar.employees!.sort((a, b) => (a.firstName! > b.firstName! ? 1 : -1)))
				updateOriginalData(props.currentCalendar.employees!.sort((a, b) => (a.firstName! > b.firstName! ? 1 : -1)))
				if (props.show) {
					props.updatePageStatus('Finished')
				}
			}

			if (props.currentCalendar.calendarID !== '') {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.currentCalendar.calendarID, props.currentOrg, props.currentCalendar]
	)

	if (props.show) {
		if (props.pageStatus === 'Finished') {
			return (
				<>
					<Form.Group style={{ padding: '30px' }}>
						<Table borderless responsive className="no-wrap">
							<thead>
								<tr className="stripe-row-body">
									<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
										Team Member
									</th>
									{props.currentOrg.xeroOrg?.version === 'AU' || props.currentOrg.xeroOrg?.version === 'AUONRAMP' ? (
										<>
											<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
												Title
											</th>
											<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
												Employee Group
											</th>
										</>
									) : null}
									<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
										Alias
									</th>
									<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
										Team Member Type
									</th>
									{I.have('admin', props.appState.permission[props.appState.context]) &&
									!props.currentCalendar.isCustomGroup &&
									props.currentCalendar.smartEmploymentBasis === null &&
									(props.currentCalendar.employeeGroupName === null || props.currentCalendar.employeeGroupName === undefined) ? (
										<>
											<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
												<Button
													type="button"
													onClick={() => {
														updateShowCustomEmployeeCard(true)
													}}
												>
													<UserPlus /> Add Team Member
												</Button>{' '}
											</th>
										</>
									) : (
										<th />
									)}
								</tr>
								<CustomEmployeeCard
									employee={initialCustomEmployeeData}
									show={showCustomEmployeeCard}
									type={'Add'}
									handleClose={() => updateShowCustomEmployeeCard(false)}
									updateMessages={updateMessages}
									appState={props.appState}
									currentCalendar={props.currentCalendar}
									employees={employees}
									currentOrg={props.currentOrg}
									updateCurrentCalendar={props.updateCurrentCalendar}
								/>
							</thead>
							<tbody>
								{employees.length > 0 ? (
									(employees as Employee[]).map((e: Employee, index: number) => (
										<EmployeeRow
											key={e.employeeID}
											employees={employees}
											index={index}
											employee={e}
											currentCalendar={props.currentCalendar}
											updateCurrentCalendar={props.updateCurrentCalendar}
											isSelected={e.isActive!}
											showTitleAndGroup={props.currentOrg.xeroOrg?.version === 'AU' || props.currentOrg.xeroOrg?.version === 'AUONRAMP'}
											updateEmployees={updateEmployees}
											originalData={originalData}
											currentOrg={props.currentOrg}
											updateOriginalData={updateOriginalData}
											updateMessages={updateMessages}
											appState={props.appState}
										/>
									))
								) : (
									<tr className={`stripe-row-body`}>
										<td colSpan={3}>No team members.</td>
									</tr>
								)}
							</tbody>
						</Table>
					</Form.Group>
					<Messages.Messages messages={messages} updateMessage={updateMessages} />
				</>
			)
		} else {
			return (
				<div className="loading-gif">
					<img src={LoadingImage} alt="Loading ..." />
				</div>
			)
		}
	} else {
		return null
	}
}

export default EmployeesList
