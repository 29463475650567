import React from 'react'

// definitions
import * as AppDefinitions from '../../../App.d'

// components
import { Col, Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { AccountXeroOrg, Calendar as CalendarData, LeaveType } from '../../../../../back-end/utilities/apiDefinitions'

// utillites
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { Edit } from 'react-feather'
import { MessageAction } from '../../ui/messages/message'
import { LeaveTypePopUp } from './leaveTypePopup'

interface LeaveTypeRowProps {
	leaveType: LeaveType
	appState: AppDefinitions.AppState
	updateMessages: (message: MessageAction) => void
	updateXeroLeaveTypes: (lv: LeaveType[]) => void
	onChecked: (xeroOrgLeaveTypeID: string) => Promise<void>
	onUnChecked: (xeroOrgLeaveTypeID: string) => Promise<void>
	xeroLeaveTypes: LeaveType[]
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	updateCalendar: (cal: CalendarData) => void
	isCustom: boolean
}

export const LeaveTypeRow = (props: LeaveTypeRowProps) => {
	const [showEditLeaveTypeDialog, updateShowEditLeaveTypeDialog] = React.useState<boolean>(false)
	const checked = props.currentCalendar.leaveTypes
		? props.currentCalendar.leaveTypes.some((lt) => lt.xeroOrgLeaveTypeID === props.leaveType.xeroOrgLeaveTypeID)
		: false

	return (
		<Col sm="auto" style={{ flex: '1', textAlign: 'left', paddingLeft: '20px' }}>
			<OverlayTrigger
				placement="auto"
				delay={{ hide: 1000, show: 0 }}
				overlay={
					<Popover id={`tooltip-edit-custom-type`}>
						<Button onClick={() => updateShowEditLeaveTypeDialog(true)}>
							Edit <Edit className={'settings-plus'}></Edit>
						</Button>
					</Popover>
				}
			>
				<FormControlLabel
					control={
						<Checkbox
							color={'default'}
							name={props.leaveType.xeroOrgLeaveTypeID}
							checked={checked}
							disabled={!props.leaveType.isActive}
							onClick={() =>
								checked ? props.onChecked(props.leaveType.xeroOrgLeaveTypeID!) : props.onUnChecked(props.leaveType.xeroOrgLeaveTypeID!)
							}
						/>
					}
					label={
						<div style={{ flex: 'auto' }}>
							<div
								style={{
									color: `${props.leaveType.hexColour}`,
									width: '20px',
									height: '20px',
									border: 'solid 10px',
									float: 'left',
									margin: '2px 5px 0px 0px',
								}}
							/>
							<div>
								{props.leaveType.name}
								{props.isCustom ? '' : <i style={{ opacity: '0.5' }}>{`  ${props.leaveType.unitType}`}</i>}
							</div>
						</div>
					}
				/>
			</OverlayTrigger>
			<LeaveTypePopUp
				show={showEditLeaveTypeDialog}
				handleClose={() => updateShowEditLeaveTypeDialog(false)}
				titleText={'Edit'}
				type={'Edit'}
				updateMessages={props.updateMessages}
				updateXeroLeaveTypes={props.updateXeroLeaveTypes}
				updateCalendar={props.updateCalendar}
				appState={props.appState}
				leaveTypes={props.xeroLeaveTypes}
				xeroOrgID={props.currentOrg.xeroOrg!.xeroOrgID!}
				calendarID={props.currentCalendar.calendarID!}
				currentLeaveType={props.leaveType}
				calendar={props.currentCalendar}
				isCustom={props.isCustom}
			/>
		</Col>
	)
}
