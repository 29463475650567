import React from 'react'
import { Alert, Col, Row, Table } from 'react-bootstrap'
import { AccountXeroOrg, Calendar as CalendarData, CalendarWithBalances, LeaveApplication } from '../../../../../back-end/utilities/apiDefinitions'
import * as AppDefinitions from '../../../App.d'

// utillites
import * as Request from '../../../utilities/request'
import Moment from 'moment'
import * as I from '../../../utilities/me'
import * as XeroProducts from '../../../constants/products'

// components
import * as Messages from '../../ui/messages/messages'
import LoadingImage from '../../../images/Finlert-loading-GIF_100px.gif'
import { getCalendarsWithBalances } from '../leaveBalances'
import { AlertCircle } from 'react-feather'
import { UnapprovedLeaveApplication, UnapprovedLeaveRow } from './unapprovedComponent'
import { checkScopes } from '../../../utilities/scope'
import { BigLeaveCalendar } from '../../ui/bigCalendar/bigCalendar'
import { LeavePeriodStatus, getCalendarLeaveEvents, getEmployeeAnniversaryEvents, getEmployeeBirthdayEvents } from '../../ui/bigCalendar/utilities'

interface UnapprovedLeavesListFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	updateCurrentCalendar: (cal: CalendarData) => void
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

const thStyle = { fontWeight: 'bold' }

const UnapprovedLeavesList = (props: UnapprovedLeavesListFrameProps) => {
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
	const [leaveApplications, updateLeaveApplications] = React.useState<LeaveApplication[]>([])
	const [unapprovedLeaves, updateUnapprovedLeaves] = React.useState<UnapprovedLeaveApplication[]>([])
	const [approvedLeaves, updateApprovedLeaves] = React.useState<UnapprovedLeaveApplication[]>([])
	const [, updateBalances] = React.useState<CalendarWithBalances>({
		employees: [],
	})
	const [xeroConsentUrl, updateXeroConsentUrl] = React.useState<string | null>(null)
	const [messages, updateMessages] = Messages.useMessageReducer([])

	React.useEffect(
		() => {
			const fetchData = async () => {
				const uniqueEmployees = props.currentCalendar.employees!.map((e) => e.employeeID!)
				const sixtyDaysAgo = Moment.utc().subtract(60, 'days').format('YYYY-MM-DD')
				const twelveMonthsInFuture = Moment().add(1, 'year').format('YYYY-MM-DD')
				const leaveTypeIDs = props.currentCalendar.leaveTypes!.map((clt) => clt.xeroOrgLeaveTypeID)
				const [leaveAppXero, leaveBalanceData, upcomingApprovedLeaves, xeroUrl, xeroLeaveAppRes, nonXeroLeaveAppRes] = await Promise.all([
					Request.get(
						`leave?where=LeaveApplicationIsNonXero==0&where=EmployeeID=in(${uniqueEmployees.join(
							','
						)})&where=LeavePeriodStatus==2&order=LeaveApplicationStartDate=ASC&chicken`,
						props.appState.authState
					),
					Request.get(`leavebalance?where=EmployeeID=in(${uniqueEmployees.join(',')})`, props.appState.authState),
					Request.get(
						`leave?where=LeaveApplicationIsNonXero==0&where=EmployeeID=in(${uniqueEmployees.join(
							','
						)})&where=LeavePeriodStatus==0&where=LeaveApplicationStartDate>${Moment().format('YYYY-MM-DD')}&order=LeaveApplicationStartDate=ASC`,
						props.appState.authState
					),
					Request.get('xero/url/leavecal', props.appState.authState),
					Request.get(
						`leave?where=LeaveApplicationStartDate>${sixtyDaysAgo}&where=LeaveApplicationIsNonXero==0&where=EmployeeID=in(${uniqueEmployees.join(
							','
						)})&where=XeroOrgLeaveTypeID=in(${leaveTypeIDs.join(',')})`,
						props.appState.authState
					),
					Request.get(
						`leave?where=LeaveApplicationStartDate>${sixtyDaysAgo}&where=LeaveApplicationStartDate<=${twelveMonthsInFuture}&where=LeaveApplicationIsNonXero==1&where=EmployeeID=in(${uniqueEmployees.join(
							','
						)})&where=XeroOrgLeaveTypeID=in(${leaveTypeIDs.join(',')})`,
						props.appState.authState
					),
				])

				const currentCalBalances = getCalendarsWithBalances(
					props.currentCalendar,
					leaveAppXero.data.leaveApplications,
					leaveBalanceData.data.employeeLeaveBalances,
					props.currentOrg!.xeroOrg!.version!
				)

				updateBalances(currentCalBalances)
				updateUnapprovedLeaves(
					leaveAppXero.data.leaveApplications.map((la: LeaveApplication): UnapprovedLeaveApplication => {
						const leaveTypeBalances =
							currentCalBalances
								.employees!.find((e) => e.employeeID === la.employeeID)
								?.leaveBalance.find((lb) => lb.xeroOrgLeaveTypeID === la.leaveType?.xeroOrgLeaveTypeID) || null
						return {
							...la,
							leaveTypeBalance: leaveTypeBalances ? `${leaveTypeBalances.value} ${leaveTypeBalances.units}` : '-',
						}
					})
				)
				updateApprovedLeaves(
					upcomingApprovedLeaves.data.leaveApplications.map((la: LeaveApplication): UnapprovedLeaveApplication => {
						const leaveTypeBalances =
							currentCalBalances
								.employees!.find((e) => e.employeeID === la.employeeID)
								?.leaveBalance.find((lb) => lb.xeroOrgLeaveTypeID === la.leaveType?.xeroOrgLeaveTypeID) || null
						return {
							...la,
							leaveTypeBalance: leaveTypeBalances ? `${leaveTypeBalances.value} ${leaveTypeBalances.units}` : '-',
						}
					})
				)
				updateLeaveApplications([...xeroLeaveAppRes.data.leaveApplications, ...nonXeroLeaveAppRes.data.leaveApplications])

				if (xeroUrl && xeroUrl.data.url) {
					updateXeroConsentUrl(xeroUrl.data.url)
				}
				if (props.show) {
					props.updatePageStatus('Finished')
				}
			}

			if (props.currentCalendar.calendarID !== '') {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.currentCalendar.calendarID, props.currentOrg, props.currentCalendar]
	)

	const updateLeaveStatus = React.useCallback(
		async (leave: UnapprovedLeaveApplication, action: string) => {
			setIsSubmitting(true)
			const leaveReq = await Request.post(
				`leave/${leave.leaveApplicationID}/${action}`,
				{
					calendarID: props.currentCalendar.calendarID,
					userAccountXeroOrgAuthID: props.currentOrg.userAccountXeroOrgAuth?.id,
					xeroOrgID: props.currentOrg.xeroOrg?.xeroOrgID,
				},
				props.appState.authState
			)

			if (leaveReq.data.success) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: `Successfully ${action === 'approve' ? 'approved' : 'rejected'} the leave application`,
						timeout: 3000,
						dismissible: true,
					},
				})

				const leaveApplicationsReq: LeaveApplication[] = leaveReq.data.leaveApplications
				if (
					action === 'approve' &&
					leaveApplicationsReq.length > 0 &&
					leaveApplicationsReq[0].leavePeriod?.some((lp) => lp.status === LeavePeriodStatus.approved)
				) {
					updateUnapprovedLeaves(unapprovedLeaves.filter((ul) => ul.leaveApplicationID !== leave.leaveApplicationID))
					updateApprovedLeaves(approvedLeaves.concat(leaveReq.data.leaveApplications))
					updateLeaveApplications(
						leaveApplications.filter((ul) => ul.leaveApplicationID !== leave.leaveApplicationID).concat(leaveReq.data.leaveApplications)
					)
				}
				if (action === 'reject' && leaveApplicationsReq.length === 0) {
					updateUnapprovedLeaves(unapprovedLeaves.filter((ul) => ul.leaveApplicationID !== leave.leaveApplicationID))
					updateLeaveApplications(leaveApplications.filter((l) => l.leaveApplicationID !== leave.leaveApplicationID))
				}
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `Unable to ${action} the leave application`,
						timeout: 3000,
						dismissible: true,
					},
				})
			}
			setIsSubmitting(false)
		},
		[props.appState.authState, props.currentOrg, updateMessages, setIsSubmitting]
	)

	const leaveEvents = React.useMemo(() => {
		return getCalendarLeaveEvents(props.currentCalendar, props.currentOrg, leaveApplications)
	}, [props.currentCalendar, props.currentOrg, leaveApplications])
	const employeeBirthdays = React.useMemo(() => {
		return getEmployeeBirthdayEvents(props.currentCalendar)
	}, [props.currentCalendar])
	const employeeAnniversaries = React.useMemo(() => {
		return getEmployeeAnniversaryEvents(props.currentCalendar)
	}, [props.currentCalendar])
	const hasLeaveApprovalProduct = React.useMemo(() => {
		return props.currentOrg.xeroOrg?.products?.some((product) => product.productID === XeroProducts.LeaveApproval.productID) || false
	}, [props.currentOrg])

	if (!props.show || !I.have('admin', props.appState.permission[props.appState.context])) {
		return null
	}
	if (props.pageStatus !== 'Finished') {
		return (
			<div className="loading-gif">
				<img src={LoadingImage} alt="Loading ..." />
			</div>
		)
	}
	if (!hasLeaveApprovalProduct) {
		return <Alert variant="info">You need the Leave Approval product to access this page</Alert>
	}
	return (
		<div className="unapproved-leave-card">
			{props.currentOrg.userAccountXeroOrgAuth && !checkScopes(props.currentOrg.userAccountXeroOrgAuth.scopes) ? (
				<Row>
					<Col>
						<div className="fade alert alert-danger show" style={{ display: 'flex', marginLeft: '20px', marginTop: '20px', alignItems: 'center' }}>
							<AlertCircle style={{ marginRight: '10px' }} />
							<p style={{ marginTop: '15px' }}>
								You will need to reauthenticate with Xero to access this feature.{' '}
								<a href={xeroConsentUrl || ''} style={{ color: 'inherit', fontStyle: 'italic' }}>
									Re-auth here.
								</a>
							</p>
						</div>
					</Col>
				</Row>
			) : null}
			<Table borderless className="mt-3">
				<thead>
					<tr className="stripe-row-header">
						<th className="header-sticky-top" style={thStyle}>
							Employee
						</th>
						{/* <th className="header-sticky-top" style={thStyle}>
								Type
							</th> */}
						<th className="header-sticky-top" style={thStyle}>
							Leave Type
						</th>
						<th className="header-sticky-top" style={thStyle}>
							Status
						</th>
						<th className="header-sticky-top" style={thStyle}>
							Start Date
						</th>
						<th className="header-sticky-top" style={thStyle}>
							End Date
						</th>
						<th className="header-sticky-top" style={thStyle}>
							Total number of units
						</th>
						<th className="header-sticky-top" style={thStyle}>
							Balance after request
						</th>
						<th className="header-sticky-top" style={thStyle}>
							Description
						</th>
						<th className="header-sticky-top" style={thStyle}>
							Approved by
						</th>
						<th className="header-sticky-top"></th>
					</tr>
				</thead>
				<tbody>
					{unapprovedLeaves.length > 0 || approvedLeaves.length > 0 ? (
						(unapprovedLeaves as UnapprovedLeaveApplication[])
							.map((ul: UnapprovedLeaveApplication, index: number) => (
								<UnapprovedLeaveRow
									key={ul.leaveApplicationID}
									unapprovedLeaves={unapprovedLeaves}
									index={index}
									unapprovedLeave={ul}
									currentCalendar={props.currentCalendar}
									updateCurrentCalendar={props.updateCurrentCalendar}
									currentOrg={props.currentOrg}
									updateMessages={updateMessages}
									appState={props.appState}
									updateLeaveStatus={updateLeaveStatus}
									isSubmitting={isSubmitting}
								/>
							))
							.concat([
								<tr key="x" className="stripe-row-header">
									<td colSpan={10}>Recently Approved Leave</td>
								</tr>,
								...(approvedLeaves as UnapprovedLeaveApplication[]).map((al: UnapprovedLeaveApplication, index: number) => (
									<UnapprovedLeaveRow
										key={al.leaveApplicationID}
										unapprovedLeaves={approvedLeaves}
										index={index}
										unapprovedLeave={al}
										currentCalendar={props.currentCalendar}
										updateCurrentCalendar={props.updateCurrentCalendar}
										currentOrg={props.currentOrg}
										updateMessages={updateMessages}
										appState={props.appState}
										updateLeaveStatus={updateLeaveStatus}
										isSubmitting={isSubmitting}
									/>
								)),
							])
					) : (
						<tr className={`row-body`}>
							<td colSpan={3}>No unapproved leave.</td>
						</tr>
					)}
				</tbody>
			</Table>
			<Row>
				<Col>
					<BigLeaveCalendar
						events={[...leaveEvents, ...employeeAnniversaries, ...employeeBirthdays]}
						firstDayOfWeek={props.currentCalendar.firstDayOfWeek || 0}
						deleteEventPopUp={() => {}}
						selectedEvent={() => {}}
						editEventPopUp={() => {}}
						updateShowAddCalendarEntryDialog={() => {}}
						updateSelectedDateForEvent={() => {}}
						readOnly={true}
						employees={props.currentCalendar.employees?.filter((e) => e.isActive) || []}
						currentCalendar={props.currentCalendar}
						currentUserID={props.appState.authState.attributes!['custom:userId'] || ''}
					/>
				</Col>
			</Row>
			<Messages.Messages messages={messages} updateMessage={updateMessages} />
		</div>
	)
}

export default UnapprovedLeavesList
