import React from 'react'
import { Row, FormLabel, Col, Button, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ChevronDown, ChevronUp, Copy } from 'react-feather'
import Moment from 'moment'

// definitions
import { AccountXeroOrg, Calendar as CalendarData, LeaveApplication, LeaveType } from '../../../../../back-end/utilities/apiDefinitions'
import * as AppDefinitions from '../../../App.d'
import { S3Config } from '../../../constants/config'
import * as Product from '../../../constants/products'

// components
import * as Messages from '../../ui/messages/messages'
import { BigLeaveCalendar } from '../../ui/bigCalendar/bigCalendar'
import LoadingImage from '../../../images/Finlert-loading-GIF_100px.gif'

// utillites
import * as Request from '../../../utilities/request'
import * as I from '../../../utilities/me'
import { getCalendarLeaveEvents, getCalendarTrialEvent, getEmployeeAnniversaryEvents, getEmployeeBirthdayEvents } from '../../ui/bigCalendar/utilities'
import { CalendarEntryPopUp } from './calendarEntryPopup'
import { HandleEventPopUp } from './handleEventPopup'

interface CalendarComponentFrameProps {
	xeroOrgCalendars: CalendarData[]
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

const CalendarComponent = (props: CalendarComponentFrameProps) => {
	const [messages, updateMessages] = Messages.useMessageReducer([])
	const [leaveApplications, updateLeaveApplications] = React.useState<LeaveApplication[] | []>([])
	const [showAddCalendarEntryDialog, updateShowAddCalendarEntryDialog] = React.useState<boolean>(false)
	const [showEditCalendarEntryDialog, updateShowEditCalendarEntryDialog] = React.useState<boolean>(false)
	const [nonXeroLeaveTypes, updateNonXeroLeaveTypes] = React.useState<LeaveType[] | null>(null)
	const [showDeleteEventDialog, updateShowDeleteEventDialog] = React.useState<boolean>(false)
	const [selectedLeaveIDForEvent, updateSelectedLeaveIDForEvent] = React.useState<string | null>(null)
	const [selectedDateForEvent, updateSelectedDateForEvent] = React.useState<string | null>(null)

	const [showInfo, updateShowInfo] = React.useState<boolean>(false)

	React.useEffect(
		() => {
			const fetchData = async () => {
				const sixtyDaysAgo = Moment.utc().subtract(60, 'days').format('YYYY-MM-DD')
				const twelveMonthsInFuture = Moment().add(1, 'year').format('YYYY-MM-DD')
				const uniqueEmployees = props.currentCalendar.employees!.map((e) => e.employeeID!)
				const leaveTypeIDs = props.currentCalendar.leaveTypes!.map((clt) => clt.xeroOrgLeaveTypeID)
				const [nonXeroLeaveTypes, calendarLeaveTypesData] = await Promise.all([
					Request.get(
						`leavetype?where=XeroOrgID==${props.currentOrg.xeroOrg!.xeroOrgID}&where=XeroOrgLeaveTypeIsNonXero==1`,
						props.appState.authState
					),
					Request.get(`calendar/${props.currentCalendar.calendarID}/leavetype`, props.appState.authState),
				])
				updateNonXeroLeaveTypes(
					nonXeroLeaveTypes.data.leaveTypes.filter(
						(lt: { isActive: boolean; xeroOrgID: string; xeroOrgLeaveTypeID: string }) =>
							lt.isActive &&
							lt.xeroOrgID === props.currentOrg.xeroOrg!.xeroOrgID &&
							calendarLeaveTypesData.data.calendarLeaveTypes.some(
								(clt: { xeroOrgLeaveTypeID: string }) => clt.xeroOrgLeaveTypeID === lt.xeroOrgLeaveTypeID
							)
					)
				)
				const nonXeroTypeIDs = nonXeroLeaveTypes.data.leaveTypes
					.filter(
						(lt: { isActive: boolean; xeroOrgID: string; xeroOrgLeaveTypeID: string }) =>
							lt.isActive &&
							lt.xeroOrgID === props.currentOrg.xeroOrg!.xeroOrgID &&
							calendarLeaveTypesData.data.calendarLeaveTypes.some(
								(clt: { xeroOrgLeaveTypeID: string }) => clt.xeroOrgLeaveTypeID === lt.xeroOrgLeaveTypeID
							)
					)
					.map((clt: { xeroOrgLeaveTypeID: string }) => clt.xeroOrgLeaveTypeID)
				const [leaveAppXero, leaveAppCustom] = await Promise.all([
					Request.get(
						`leave?where=LeaveApplicationStartDate>${sixtyDaysAgo}&where=LeaveApplicationIsNonXero==0&where=EmployeeID=in(${uniqueEmployees.join(
							','
						)})&where=XeroOrgLeaveTypeID=in(${leaveTypeIDs.concat(nonXeroTypeIDs).join(',')})`,
						props.appState.authState
					),
					Request.get(
						`leave?where=LeaveApplicationStartDate>${sixtyDaysAgo}&where=LeaveApplicationStartDate<=${twelveMonthsInFuture}&where=LeaveApplicationIsNonXero==1&where=EmployeeID=in(${uniqueEmployees.join(
							','
						)})&where=XeroOrgLeaveTypeID=in(${leaveTypeIDs.concat(nonXeroTypeIDs).join(',')})`,
						props.appState.authState
					),
				])

				updateLeaveApplications([...leaveAppXero.data.leaveApplications, ...leaveAppCustom.data.leaveApplications])

				// If this is a new calendar, show info
				if (localStorage.getItem('newCalendarID') && props.currentCalendar.calendarID === localStorage.getItem('newCalendarID')) {
					updateShowInfo(true)
					localStorage.removeItem('newCalendarID')
				}
				if (props.show) {
					props.updatePageStatus('Finished')
				}
			}

			if (props.currentCalendar.calendarID !== '') {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.currentCalendar.calendarID, props.currentOrg, props.show, leaveApplications.length]
	)

	const activeEmployees = React.useMemo(() => {
		return props.currentCalendar.employees?.filter((e) => e.isActive) || []
	}, [props.currentCalendar])

	const leaveEvents = React.useMemo(() => {
		return getCalendarLeaveEvents(props.currentCalendar, props.currentOrg, leaveApplications)
	}, [props.currentCalendar, props.currentOrg, leaveApplications])
	const anniversaryEvents = React.useMemo(() => {
		return getEmployeeAnniversaryEvents(props.currentCalendar)
	}, [props.currentCalendar])
	const birthdayEvents = React.useMemo(() => {
		return getEmployeeBirthdayEvents(props.currentCalendar)
	}, [props.currentCalendar])
	const trialEvent = React.useMemo(() => {
		return getCalendarTrialEvent(props.currentOrg)
	}, [])

	if (props.show) {
		if (props.pageStatus === 'Finished') {
			return (
				<>
					<FormGroup style={{ padding: '30px' }}>
						<Row className="calendar-alerts-card" style={{ paddingBottom: '0px' }}>
							<Col>
								<Row style={{ paddingLeft: '20px' }}>
									<FormLabel className="emails-label" style={{ paddingTop: '25px' }}>
										Team Calendar
									</FormLabel>
									<>
										{props.currentOrg!.xeroOrg!.products!.some((pr) => pr.productID === Product.CustomLeaveTypes.productID) ? (
											<>
												<Col sm="auto" className="position-right" style={{ padding: '20px' }}>
													<OverlayTrigger
														placement="auto"
														overlay={
															<Tooltip id={`tooltip-xero`}>
																{nonXeroLeaveTypes && nonXeroLeaveTypes.length !== 0
																	? 'Add custom calendar entry'
																	: 'You need to add a custom leave type in settings'}
																{/* Premium Feature - contact support@finlert.com to get started */}
															</Tooltip>
														}
													>
														<div>
															<Button
																type="button"
																onClick={() => {
																	updateShowAddCalendarEntryDialog(true)
																}}
															>
																Add Entry
															</Button>
														</div>
													</OverlayTrigger>
												</Col>
												{showAddCalendarEntryDialog && (
													<CalendarEntryPopUp
														show={showAddCalendarEntryDialog}
														handleClose={() => updateShowAddCalendarEntryDialog(false)}
														titleText={'Update Calendar'}
														types={nonXeroLeaveTypes}
														employees={activeEmployees}
														leaveApplications={leaveEvents}
														updateLeaveApplications={updateLeaveApplications}
														updateMessages={updateMessages}
														appState={props.appState}
														currentCalendar={props.currentCalendar}
														xeroOrgCalendars={props.xeroOrgCalendars}
														currentOrg={props.currentOrg}
														selectedDateForEvent={selectedDateForEvent}
													/>
												)}
											</>
										) : (
											<Col sm="auto" className="position-right" style={{ padding: '20px' }}>
												<OverlayTrigger
													placement="auto"
													overlay={
														<Tooltip id={`tooltip-xero`}>Premium Feature - contact support@finlert.com to get started.</Tooltip>
													}
												>
													<div>
														<Button
															type="button"
															onClick={() => {
																updateShowAddCalendarEntryDialog(true)
															}}
														>
															Add Entry
														</Button>
													</div>
												</OverlayTrigger>
											</Col>
										)}
									</>
									{props.currentCalendar.outputToIcs || props.currentCalendar.outputToHtml ? (
										<Col className="position-right" style={{ paddingTop: '30px' }}>
											{showInfo ? (
												<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-xero`}>Show Less</Tooltip>}>
													<ChevronUp onClick={() => updateShowInfo(!showInfo)} />
												</OverlayTrigger>
											) : (
												<OverlayTrigger placement="left" overlay={<Tooltip id={`tooltip-xero`}>Show More</Tooltip>}>
													<ChevronDown onClick={() => updateShowInfo(!showInfo)} />
												</OverlayTrigger>
											)}
										</Col>
									) : null}
								</Row>
							</Col>
						</Row>

						<Row className="calendar-alerts-card ">
							<Col>
								{I.have('admin', props.appState.permission[props.appState.context]) &&
								showInfo &&
								(props.currentCalendar.outputToIcs || props.currentCalendar.outputToHtml) ? (
									<div style={{ backgroundColor: 'white', padding: '20px' }}>
										{props.currentCalendar.outputToIcs && (
											<>
												<Row style={{ marginTop: '-5px' }}>
													<p>
														Share your LeaveCal Calendar in all major Calendar Apps - including Google Calendar, Microsoft Outlook
														and Apple Calendar. Use the button below to copy a link which you can subscribe to. Follow our{' '}
														<a
															href={'https://help.finlert.com/leavecal/adding-leavecal-to-your-calendar/'}
															target="_blank"
															rel="noopener noreferrer"
														>
															simple installation instructions
														</a>
														!
													</p>
												</Row>
												<Row>
													<Col sm="auto">
														<Button
															type="button"
															onClick={() => {
																navigator.clipboard.writeText(
																	`https://${S3Config.BaseUrl}/${props.currentCalendar.fileLocation}.ics`
																)
																updateMessages({
																	type: 'add',
																	data: {
																		severity: 'success',
																		message: 'Copied URL to clipboard',
																		timeout: 3000,
																		dismissible: true,
																	},
																})
															}}
														>
															<Copy /> Copy Calendar Feed Link
														</Button>
													</Col>
												</Row>
											</>
										)}
										{props.currentCalendar.outputToHtml && (
											<>
												<Row>
													<Col sm="auto" style={{ paddingLeft: '0px' }}>
														<p style={{ margin: '10px 0px' }}>
															Get a link you can embed in Microsoft teams or your intranet.{' '}
															<a
																href={
																	'https://help.finlert.com/leavecal/viewing-leavecal-with-microsoft-teams-or-your-intranet/'
																}
																target="_blank"
																rel="noopener noreferrer"
															>
																Instructions can be found here.
															</a>
														</p>
													</Col>
												</Row>
												<Row>
													<Col sm="auto" style={{ textAlign: 'center' }}>
														<Button
															type="button"
															onClick={() => {
																navigator.clipboard.writeText(
																	`https://${S3Config.BaseUrl}/${props.currentCalendar.fileLocation}.html`
																)
																updateMessages({
																	type: 'add',
																	data: {
																		severity: 'success',
																		message: 'Copied URL to clipboard',
																		timeout: 3000,
																		dismissible: true,
																	},
																})
															}}
														>
															<Copy /> Copy Graphical Calendar Link
														</Button>
													</Col>
												</Row>
											</>
										)}
									</div>
								) : null}
								<Row
									style={{
										width: '100%',
										padding: '20px',
										marginLeft: '0px',
										display: 'inline',
									}}
								>
									<BigLeaveCalendar
										events={[...leaveEvents, ...anniversaryEvents, ...birthdayEvents, ...trialEvent]}
										firstDayOfWeek={props.currentCalendar.firstDayOfWeek || 0}
										deleteEventPopUp={updateShowDeleteEventDialog}
										selectedEvent={updateSelectedLeaveIDForEvent}
										editEventPopUp={updateShowEditCalendarEntryDialog}
										updateShowAddCalendarEntryDialog={updateShowAddCalendarEntryDialog}
										updateSelectedDateForEvent={updateSelectedDateForEvent}
										readOnly={!I.have('admin', props.appState.permission[props.appState.context])}
										employees={props.currentCalendar.employees?.filter((e) => e.isActive) || []}
										currentCalendar={props.currentCalendar}
										currentUserID={props.appState.authState.attributes!['custom:userId'] || ''}
									/>
									{showDeleteEventDialog && (
										<HandleEventPopUp
											show={showDeleteEventDialog}
											popUpEventType={'Delete'}
											leaveApplicationID={selectedLeaveIDForEvent}
											leaveApplications={leaveEvents}
											handleClose={() => updateShowDeleteEventDialog(false)}
											updateMessages={updateMessages}
											appState={props.appState}
											updateLeaveApplications={updateLeaveApplications}
											calendar={props.currentCalendar}
											xeroOrgCalendars={props.xeroOrgCalendars}
										/>
									)}
									{showEditCalendarEntryDialog && (
										<CalendarEntryPopUp
											leaveApplicationID={selectedLeaveIDForEvent}
											leaveApplications={leaveEvents}
											updateMessages={updateMessages}
											appState={props.appState}
											updateLeaveApplications={updateLeaveApplications}
											showCalendar={showAddCalendarEntryDialog}
											types={nonXeroLeaveTypes}
											employees={props.currentCalendar.employees?.filter((e) => e.isActive)}
											currentCalendar={props.currentCalendar}
											xeroOrgCalendars={props.xeroOrgCalendars}
											currentOrg={props.currentOrg}
											show={showEditCalendarEntryDialog}
											handleClose={() => updateShowEditCalendarEntryDialog(false)}
											titleText={'Edit Calendar Event'}
										/>
									)}
								</Row>
							</Col>
						</Row>
					</FormGroup>
					<Messages.Messages messages={messages} updateMessage={updateMessages} />
				</>
			)
		} else {
			return (
				<div className="loading-gif">
					<img src={LoadingImage} alt="Loading ..." />
				</div>
			)
		}
	} else {
		return null
	}
}

export default CalendarComponent
