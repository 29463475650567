import React from 'react'
import { Button, Col, Form, FormLabel, Row, FormGroup, Spinner } from 'react-bootstrap'
import { Microsoft } from '../../../../back-end/utilities/apiDefinitions'
import { MicrosoftLoginButton } from 'react-social-login-buttons'

// definitions
import * as I from '../../utilities/me'

// components
import * as Messages from '../../components/ui/messages/messages'

// utillites
import * as Request from '../../utilities/request'
import { AppContext } from '../../App'

const MicrosoftIntegration = () => {
	const { appState } = React.useContext(AppContext)

	const [microsoftUrl, updateMicrosoftUrl] = React.useState<string>('')
	const [microsoft, updateMicrosoft] = React.useState<Microsoft | null>(null)
	const [isLoading, updateIsLoading] = React.useState<boolean>(true)
	const [messages, updateMessages] = Messages.useMessageReducer([])

	React.useEffect(() => {
		const fetchData = async () => {
			const [url, microsoft] = await Promise.all([Request.get('microsoft/url', appState.authState), Request.get('microsoft', appState.authState)])
			updateMicrosoftUrl(url.data.url)
			updateMicrosoft(microsoft.data.microsoft)
			updateIsLoading(false)
		}

		if (I.have('admin', appState.permission[appState.context])) {
			fetchData()
		}
	}, [appState.authState, appState.permission[appState.context]]) // eslint-disable-line

	const disconnectMicrosoft = async () => {
		const microsoft = await Request.del('microsoft', appState.authState)
		updateMicrosoft(microsoft.data.microsoft)
	}

	if (isLoading) {
		return <Spinner animation="border" />
	} else if (microsoft) {
		return (
			<>
				<Form.Group style={{ padding: '30px' }}>
					<Row className="calendar-alerts-card ">
						<Col>
							<FormLabel style={{ marginTop: '-5px' }}>
								You&apos;re already integrated with Microsoft! Turn on Microsoft sync in settings
							</FormLabel>
						</Col>
						<Col>
							<Button variant="danger" onClick={disconnectMicrosoft}>
								Disconnect
							</Button>
						</Col>
					</Row>
				</Form.Group>
				<Messages.Messages messages={messages} updateMessage={updateMessages} />
			</>
		)
	} else {
		return (
			<>
				<FormGroup style={{ padding: '30px' }}>
					<Row className="calendar-alerts-card ">
						<Col xs="12" sm="4">
							<p>You are not connected to microsoft</p>
						</Col>
						<Col xs="12" sm="4">
							<MicrosoftLoginButton onClick={() => (window.location.href = microsoftUrl)} />
						</Col>
					</Row>
				</FormGroup>
			</>
		)
	}
}

export default MicrosoftIntegration
