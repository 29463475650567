import React from 'react'

// definitions
import * as AppDefinitions from '../../../App.d'
import * as RecordStatus from '../../../types/recordStatus'
import * as Product from '../../../constants/products'

// components
import { Col, Row, FormLabel, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AccountXeroOrg, Calendar as CalendarData, CalendarLeaveType, Google, LeaveType, Microsoft } from '../../../../../back-end/utilities/apiDefinitions'
import * as Messages from '../../ui/messages/messages'

// utillites
import * as Request from '../../../utilities/request'
import * as I from '../../../utilities/me'
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core'
import { Trash2 } from 'react-feather'
import { Dialog } from '../../ui/dialog/dialog'
import { Plus, Info } from 'react-feather'
import LoadingImage from '../../../images/Finlert-loading-GIF_100px.gif'
import { CalendarName } from './calendarName'
import { CalendarFirstDayOfWeek } from './calendarFirstDayOfWeek'
import { OrgHoursInDay } from './orgHoursInDay'
import { LeaveTypeRow } from './leaveTypeRow'
import { LeaveTypePopUp } from './leaveTypePopup'
import { CustomFeedPopUp } from './customFeedPopup'
import { ApproverSettingsPanel } from './approverSettingsPanel'

interface SettingsFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	updateCurrentCalendar: (cal: CalendarData) => void
	updateCurrentOrg: (xero: AccountXeroOrg) => void
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

const SettingsFrame = (props: SettingsFrameProps) => {
	const [leaveTypes, updateLeaveTypes] = React.useState<LeaveType[] | null>(null)
	const [nonXeroLeaveTypes, updateNonXeroLeaveTypes] = React.useState<LeaveType[] | null>(null)
	const [calendarLeaveTypes, updateCalendarLeaveTypes] = React.useState<CalendarLeaveType[] | null>(null)
	const [messages, updateMessages] = Messages.useMessageReducer([])
	const [calendarSettings, updateCalendarSettings] = React.useState<CalendarData | null>(null)
	const [showDialog, updateShowDialog] = React.useState<boolean>(false)
	const [googleIntegration, updateGoogleIntegration] = React.useState<Google | null>(null)
	const [microsoftIntegration, updateMicrosoftIntegration] = React.useState<Microsoft | null>(null)

	const [showAddCustomTypeDialog, updateShowAddCustomTypeDialog] = React.useState<boolean>(false)
	const [showAddCustomFeedDialog, updateShowAddCustomFeedDialog] = React.useState<boolean>(false)

	React.useEffect(
		() => {
			const fetchData = async () => {
				const [leaveTypesData, calendarLeaveTypesData, googleReq, microsoftReq] = await Promise.all([
					Request.get(
						`leavetype?where=XeroOrgID==${props.currentOrg.xeroOrg!.xeroOrgID}&where=AccountXeroOrgID==${props.currentOrg.accountXeroOrgID}`,
						props.appState.authState
					),
					Request.get(`calendar/${props.currentCalendar.calendarID}/leavetype`, props.appState.authState),
					I.have('admin', props.appState.permission[props.appState.context]) ? Request.get('google', props.appState.authState) : { data: [] },
					I.have('admin', props.appState.permission[props.appState.context]) ? Request.get('microsoft', props.appState.authState) : { data: [] },
				])

				updateLeaveTypes(leaveTypesData.data.leaveTypes.filter((lt: LeaveType) => !lt.isNonXero))
				updateNonXeroLeaveTypes(
					leaveTypesData.data.leaveTypes
						.filter((lt: LeaveType) => lt.isNonXero)
						.sort((a: { name: string }, b: { name: string }) => a.name!.localeCompare(b.name!))
				)
				updateCalendarLeaveTypes(calendarLeaveTypesData.data.calendarLeaveTypes)
				updateCalendarSettings(props.currentCalendar)
				if (I.have('admin', props.appState.permission[props.appState.context]) && googleReq && googleReq.data) {
					updateGoogleIntegration(googleReq.data.google)
				}
				if (I.have('admin', props.appState.permission[props.appState.context]) && microsoftReq && microsoftReq.data) {
					updateMicrosoftIntegration(microsoftReq.data.microsoft)
				}

				if (props.show) {
					props.updatePageStatus('Finished')
				}
			}

			if (props.appState.authState.isLoggedIn && props.currentCalendar.calendarID && props.currentOrg.xeroOrg) {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.currentCalendar.calendarID, props.currentOrg.xeroOrg, props.currentCalendar, props.currentOrg.accountXeroOrgID]
	)

	/** Custom Types Column */
	const handleCustomTypeAdd = async (xeroOrgLeaveTypeID: string) => {
		const req = await Request.post(
			`calendar/${props.currentCalendar.calendarID}/leavetype`,
			{ xeroOrgLeaveTypeID, recordStatusID: RecordStatus.Active },
			props.appState.authState
		)

		if (req.data.success) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Successfully updated the information to be included',
					timeout: 3000,
					dismissible: true,
				},
			})
			const copyCalendar = JSON.parse(JSON.stringify(props.currentCalendar)) as CalendarData
			copyCalendar.leaveTypes!.push({ xeroOrgLeaveTypeID })
			props.updateCurrentCalendar(copyCalendar)
		} else {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to update the information to be included',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const handleCustomTypeRemove = async (xeroOrgLeaveTypeID: string) => {
		const req = await Request.put(
			`calendar/${props.currentCalendar.calendarID}/leavetype/${xeroOrgLeaveTypeID}`,
			{ recordStatusID: RecordStatus.Deleted },
			props.appState.authState
		)

		if (req.data.success) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Successfully updated the information to be included',
					timeout: 3000,
					dismissible: true,
				},
			})
			const copyCalendar = JSON.parse(JSON.stringify(props.currentCalendar)) as CalendarData
			copyCalendar.leaveTypes = copyCalendar.leaveTypes!.filter((lt) => lt.xeroOrgLeaveTypeID !== xeroOrgLeaveTypeID)
			props.updateCurrentCalendar(copyCalendar)
		} else {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to update the information to be included',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	/** Leave Types Column */

	const checkIfAllLeaveSelected = () => {
		const leaveTypeIDs = leaveTypes!.map((lt) => lt.xeroOrgLeaveTypeID!)
		const calendarLeaveTypeIDs = calendarLeaveTypes!.map((lt) => lt.xeroOrgLeaveTypeID!)
		const containsAll = leaveTypeIDs!.every((element) => {
			return calendarLeaveTypeIDs!.includes(element)
		})
		return containsAll
	}

	const checkIfAllIncludedInfoSelected = () => {
		return (
			props.currentCalendar.showAmount &&
			props.currentCalendar.showLeaveType &&
			props.currentCalendar.showPayrollDescription &&
			props.currentCalendar.showCustomLeaveDescription &&
			((props.currentOrg.xeroOrg && props.currentOrg.xeroOrg.version !== 'AU' && props.currentOrg.xeroOrg.version !== 'AUONRAMP') ||
				(props.currentCalendar.showTitle && props.currentCalendar.showGroup))
		)
	}

	const newRecordStatus = (option: string) => {
		if (option === 'all') {
			if (checkIfAllLeaveSelected()) {
				return RecordStatus.Deleted
			} else {
				return RecordStatus.Active
			}
		} else {
			if (calendarLeaveTypes!.some((clt) => clt.xeroOrgLeaveTypeID === option)) {
				return RecordStatus.Deleted
			} else {
				return RecordStatus.Active
			}
		}
	}

	const handleLeaveTypeChange = async (option: string) => {
		if (option === 'all') {
			try {
				if (checkIfAllLeaveSelected()) {
					// deselect them all
					await Promise.all(
						calendarLeaveTypes!.map(async (clt) => {
							const result = await Request.put(
								`calendar/${props.currentCalendar.calendarID}/leavetype/${clt.xeroOrgLeaveTypeID}`,
								{ recordStatusID: RecordStatus.Deleted },
								props.appState.authState
							)
							return result.data.calendarLeaveTypes
						})
					)
					updateCalendarLeaveTypes([])

					updateMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: 'Successfully updated leave type selections',
							timeout: 3000,
							dismissible: true,
						},
					})
				} else {
					//select the unselected
					const unselected = leaveTypes!.filter((lt) => !calendarLeaveTypes!.some((clt) => clt.xeroOrgLeaveTypeID === lt.xeroOrgLeaveTypeID))
					await Promise.all(
						unselected.map(async (xolt) => {
							const result = await Request.post(
								`calendar/${props.currentCalendar.calendarID}/leavetype`,
								{
									calendarID: props.currentCalendar.calendarID,
									xeroOrgLeaveTypeID: xolt.xeroOrgLeaveTypeID,
									recordStatusID: RecordStatus.Active,
								},
								props.appState.authState
							)

							return result.data.calendarLeaveTypes
						})
					)
					const final = await Request.get(`calendar/${props.currentCalendar.calendarID}/leavetype`, props.appState.authState)
					updateCalendarLeaveTypes(final.data.calendarLeaveTypes)

					updateMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: 'Successfully updated leave type selections',
							timeout: 3000,
							dismissible: true,
						},
					})
				}
			} catch (err) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update leave type selections',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		} else if (option !== 'all') {
			try {
				const newRecordStatusID = newRecordStatus(option)
				if (newRecordStatusID === RecordStatus.Active) {
					//we are adding a leave type
					await Request.post(
						`calendar/${props.currentCalendar.calendarID}/leavetype`,
						{
							calendarID: props.currentCalendar.calendarID,
							xeroOrgLeaveTypeID: option,
							recordStatusID: RecordStatus.Active,
						},
						props.appState.authState
					)
				} else {
					//we are deleting it
					await Request.put(
						`calendar/${props.currentCalendar.calendarID}/leavetype/${option}`,
						{ recordStatusID: RecordStatus.Deleted },
						props.appState.authState
					)
				}

				const final = await Request.get(`calendar/${props.currentCalendar.calendarID}/leavetype`, props.appState.authState)
				updateCalendarLeaveTypes(final.data.calendarLeaveTypes)

				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated leave type selections',
						timeout: 3000,
						dismissible: true,
					},
				})
			} catch (err) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update leave type selections',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		}
	}

	const handleCalendarChange = async (option: string) => {
		if (option === 'all') {
			const updatedCalendar =
				props.currentCalendar.showAmount &&
				props.currentCalendar.showGroup &&
				props.currentCalendar.showLeaveType &&
				props.currentCalendar.showTitle &&
				props.currentCalendar.showPayrollDescription &&
				props.currentCalendar.showCustomLeaveDescription
					? {
							showAmount: false,
							showGroup: false,
							showLeaveType: false,
							showTitle: false,
							showPayrollDescription: false,
							showCustomLeaveDescription: false,
					  }
					: {
							showAmount: true,
							showGroup: true,
							showLeaveType: true,
							showTitle: true,
							showPayrollDescription: true,
							showCustomLeaveDescription: true,
					  }

			const req = await Request.put(`calendar/${props.currentCalendar.calendarID}`, updatedCalendar, props.appState.authState)

			if (req.data.success) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated the information to be included',
						timeout: 3000,
						dismissible: true,
					},
				})
				props.updateCurrentCalendar({ ...props.currentCalendar, ...updatedCalendar })
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update the information to be included',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		} else if (option !== 'all') {
			const updatedCalendar: Partial<CalendarData> = {}
			const calendarProperty = option as keyof Pick<
				CalendarData,
				| 'showAmount'
				| 'showTitle'
				| 'showLeaveType'
				| 'outputToIcs'
				| 'outputToHtml'
				| 'outputToGoogle'
				| 'outputToMicrosoft'
				| 'showUnapprovedLeave'
				| 'showPayrollDescription'
				| 'showCustomLeaveDescription'
			>
			updatedCalendar[calendarProperty] = !props.currentCalendar[calendarProperty]

			const req = await Request.put(`calendar/${props.currentCalendar.calendarID}`, updatedCalendar, props.appState.authState)

			if (req.data.success) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated the information to be included',
						timeout: 3000,
						dismissible: true,
					},
				})
				props.updateCurrentCalendar({ ...props.currentCalendar, ...updatedCalendar })
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update the information to be included',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		}
	}

	/** Celebrate Column */

	const checkIfAllCelebrationsSelected = () => {
		return props.currentCalendar.showAnniversary && props.currentCalendar.showBirthday
	}

	const handleCelebrationChange = async (option: string) => {
		if (option === 'all') {
			const updatedCalendar =
				props.currentCalendar.showAnniversary && props.currentCalendar.showBirthday
					? {
							showAnniversary: false,
							showAnniversaryYears: false,
							showDayOfBirthday: true,
							showBirthday: false,
							newEmployee1Month: false,
							newEmployee3Months: false,
							newEmployee6Months: false,
							newEmployee18Months: false,
					  }
					: {
							showAnniversary: true,
							showAnniversaryYears: true,
							showDayOfBirthday: false,
							showBirthday: true,
							newEmployee1Month: true,
							newEmployee3Months: true,
							newEmployee6Months: true,
							newEmployee18Months: true,
					  }

			const req = await Request.put(`calendar/${props.currentCalendar.calendarID}`, updatedCalendar, props.appState.authState)

			if (req.data.success) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated celebrate',
						timeout: 3000,
						dismissible: true,
					},
				})
				props.updateCurrentCalendar({ ...props.currentCalendar, ...updatedCalendar })
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update celebrate',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		} else if (option !== 'all') {
			const updatedCalendar = {
				showAnniversary: option === 'showAnniversary' ? !props.currentCalendar.showAnniversary : props.currentCalendar.showAnniversary,
				showAnniversaryYears:
					option === 'showAnniversaryYears' ? !props.currentCalendar.showAnniversaryYears : props.currentCalendar.showAnniversaryYears,
				showDayOfBirthday: option === 'showDayOfBirthday' ? !props.currentCalendar.showDayOfBirthday : props.currentCalendar.showDayOfBirthday,
				showBirthday: option === 'showBirthday' ? !props.currentCalendar.showBirthday : props.currentCalendar.showBirthday,
				newEmployee1Month: option === 'newEmployee1Month' ? !props.currentCalendar.newEmployee1Month : props.currentCalendar.newEmployee1Month,
				newEmployee3Months: option === 'newEmployee3Months' ? !props.currentCalendar.newEmployee3Months : props.currentCalendar.newEmployee3Months,
				newEmployee6Months: option === 'newEmployee6Months' ? !props.currentCalendar.newEmployee6Months : props.currentCalendar.newEmployee6Months,
				newEmployee18Months: option === 'newEmployee18Months' ? !props.currentCalendar.newEmployee18Months : props.currentCalendar.newEmployee18Months,
			}

			const req = await Request.put(`calendar/${props.currentCalendar.calendarID}`, updatedCalendar, props.appState.authState)

			if (req.data.success) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated celebrate',
						timeout: 3000,
						dismissible: true,
					},
				})
				props.updateCurrentCalendar({ ...props.currentCalendar, ...updatedCalendar })
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update celebrate',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		}
	}

	const handleGenericCalendarChange = async (updatedCalendar: Partial<CalendarData>) => {
		const req = await Request.put(`calendar/${props.currentCalendar.calendarID}`, updatedCalendar, props.appState.authState)

		if (req.data.success) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Successfully updated celebrate',
					timeout: 3000,
					dismissible: true,
				},
			})
			props.updateCurrentCalendar({ ...props.currentCalendar, ...updatedCalendar })
		} else {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to update celebrate',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	// Delete calendar
	const deleteCurrentCalendar = async () => {
		try {
			const req = await Request.del(`calendar/${props.currentCalendar.calendarID}`, props.appState.authState)

			if (req.data.success) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully deleted current calendar',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
			localStorage.removeItem('selectedCalendarID')
			window.location.reload()
		} catch (err) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to delete current calendar',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const changeEmptyLeaveAlertPreference = async (option: boolean) => {
		try {
			const updatedCalendar = {
				sendEmptyLeaveAlert: option,
			}
			const req = await Request.put(`calendar/${props.currentCalendar.calendarID}`, updatedCalendar, props.appState.authState)
			if (req.data.success) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated selection for empty alerts',
						timeout: 3000,
						dismissible: true,
					},
				})
				props.updateCurrentCalendar({ ...props.currentCalendar, ...updatedCalendar })
			}
		} catch (err) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to change selection for empty alertrs',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const changeShowLeaveInDaysPreference = async (option: boolean) => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			try {
				const updatedXeroOrg = {
					...props.currentOrg.xeroOrg,
					showLeaveInDays: option,
				}
				const req = await Request.put(`xeroorg`, updatedXeroOrg, props.appState.authState)
				if (req.status) {
					updateMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: 'Successfully updated selection for showing leave in days',
							timeout: 3000,
							dismissible: true,
						},
					})
					props.updateCurrentOrg({
						...props.currentOrg,
						xeroOrg: updatedXeroOrg,
					})
				}
			} catch (err) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to change selection for showing leave in days',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		} else {
			updateMessages(Messages.addMessage('danger', 'You do no have permission to change selection for showing leave in days'))
		}
	}

	// delete calendar input feed
	const deleteCalendarFeed = async (feedID: string) => {
		try {
			const req = await Request.del(`calendar/${props.currentCalendar.calendarID}/inputfeed/${feedID}`, props.appState.authState)

			if (req.data.success && props.currentCalendar && props.currentCalendar.inputFeeds) {
				props.updateCurrentCalendar({
					...props.currentCalendar,
					inputFeeds: props.currentCalendar.inputFeeds.filter((feed) => feed.calendarInputFeedID !== feedID),
				})
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully deleted external calendar',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		} catch (err) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to delete external calendar',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	if (props.show) {
		if (props.pageStatus === 'Finished') {
			return (
				<>
					<FormGroup className="settings-card">
						<Row>
							<Col sm="auto">
								<FormLabel className="top-settings-label">Calendar Name</FormLabel>
							</Col>
							{calendarSettings && (
								<Col>
									<CalendarName
										updateMessages={updateMessages}
										appState={props.appState}
										currentCalendar={props.currentCalendar}
										updateCurrentCalendar={props.updateCurrentCalendar}
										calendarSettings={calendarSettings}
										updateCalendarSettings={updateCalendarSettings}
									/>
								</Col>
							)}
							{!props.currentCalendar.isUndeletable ? (
								<>
									<Col sm="auto" style={{ flex: '1', textAlign: 'right' }}>
										<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-calendar`}>Delete calendar</Tooltip>}>
											<Trash2
												style={{
													cursor: 'pointer',
													paddingTop: '5px',
													width: '30px',
													height: '30px',
													textAlign: 'right',
												}}
												onClick={() => updateShowDialog(true)}
											/>
										</OverlayTrigger>
									</Col>
									<Dialog
										show={showDialog}
										handleClose={() => updateShowDialog(false)}
										handleConfirm={deleteCurrentCalendar}
										titleText={'Delete calendar confirmation'}
										bodyText={'Are you sure you want to delete this calendar?'}
									/>
								</>
							) : (
								<Col sm="auto" style={{ flex: '1', textAlign: 'right' }}>
									<OverlayTrigger
										placement="auto"
										overlay={<Tooltip id={`tooltip-remove-calendar`}>This is an All Employees calendar that cannot be deleted.</Tooltip>}
									>
										<Trash2
											style={{
												paddingTop: '5px',
												width: '30px',
												height: '30px',
												textAlign: 'right',
												color: '#7c7e80',
											}}
										/>
									</OverlayTrigger>
								</Col>
							)}
						</Row>
					</FormGroup>
					<FormGroup className="settings-card">
						<Row>
							<Col sm="7">
								<Row>
									<Col sm="6">
										<FormLabel className="top-settings-label">First Day of the Week</FormLabel>
									</Col>
									{calendarSettings && (
										<Col sm="3">
											<CalendarFirstDayOfWeek
												updateMessages={updateMessages}
												appState={props.appState}
												currentCalendar={props.currentCalendar}
												updateCurrentCalendar={props.updateCurrentCalendar}
												calendarSettings={calendarSettings}
												updateCalendarSettings={updateCalendarSettings}
											/>
										</Col>
									)}
								</Row>
								<Row>
									<Col sm="6">
										<FormLabel className="top-settings-label">Send alerts when no leave</FormLabel>
									</Col>
									<Col sm="3" style={{ paddingLeft: '3px' }}>
										<OverlayTrigger
											placement="auto"
											overlay={
												<Tooltip id={`tooltip-empty-leave-alerts`}>
													{props.currentCalendar.sendEmptyLeaveAlert
														? 'Deselecting this will ensure you do not get an alert when there is no leave'
														: 'Selecting this will ensure you still get an alert even when there is no leave'}
												</Tooltip>
											}
										>
											<FormControlLabel
												control={
													<Checkbox
														color={'default'}
														checked={props.currentCalendar.sendEmptyLeaveAlert}
														onChange={() => {
															changeEmptyLeaveAlertPreference(props.currentCalendar.sendEmptyLeaveAlert ? false : true)
														}}
													/>
												}
												label={''}
											/>
										</OverlayTrigger>
									</Col>
								</Row>
								<Row>
									<Col sm="6">
										<FormLabel className="top-settings-label">Standard number of hours in a day</FormLabel>
									</Col>
									<Col sm="3" style={{ paddingLeft: '3px' }}>
										<OrgHoursInDay
											appState={props.appState}
											updateMessages={updateMessages}
											currentOrg={props.currentOrg}
											updateCurrentOrg={props.updateCurrentOrg}
										/>
									</Col>
								</Row>
								<Row>
									<Col sm="6">
										<FormLabel className="top-settings-label">Show leave in days</FormLabel>
									</Col>
									<Col sm="3" style={{ paddingLeft: '3px' }}>
										<OverlayTrigger
											placement="auto"
											overlay={
												<Tooltip id={`tooltip-show-leave-in-days`}>
													{props.currentOrg.xeroOrg && props.currentOrg.xeroOrg.showLeaveInDays
														? 'Deselecting will update calendar and alerts to show in hours'
														: 'Selecting will update calendars and alerts to show in days'}
												</Tooltip>
											}
										>
											<FormControlLabel
												control={
													<Checkbox
														color={'default'}
														checked={props.currentOrg.xeroOrg!.showLeaveInDays}
														onChange={() => {
															changeShowLeaveInDaysPreference(props.currentOrg.xeroOrg!.showLeaveInDays ? false : true)
														}}
													/>
												}
												label={''}
											/>
										</OverlayTrigger>
									</Col>
								</Row>
							</Col>
							<Col>
								<Row>
									<Col sm="6">
										<FormLabel className="top-settings-label">Calendar Outputs</FormLabel>
									</Col>
									{props.currentCalendar && props.currentCalendar.calendarID !== '' ? (
										<Col sm="4">
											<Row>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.outputToIcs}
															onChange={(e) => handleCalendarChange(e.target.name)}
															name={'outputToIcs'}
														/>
													}
													label={'Calendar Feed'}
												/>
											</Row>
											<Row>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.outputToHtml}
															onChange={(e) => handleCalendarChange(e.target.name)}
															name={'outputToHtml'}
														/>
													}
													label={'Graphical Calendar'}
												/>
											</Row>
											{googleIntegration && (
												<Row>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.outputToGoogle}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'outputToGoogle'}
															/>
														}
														label={'Google'}
													/>
												</Row>
											)}
											{microsoftIntegration && (
												<Row>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.outputToMicrosoft}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'outputToMicrosoft'}
															/>
														}
														label={'Outlook'}
													/>
												</Row>
											)}
										</Col>
									) : null}
								</Row>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="settings-card">
						<Row>
							<Col sm="3">
								<Row style={{ justifyContent: 'space-between' }}>
									<FormLabel className="settings-label">Included Leave Types</FormLabel>
								</Row>
								<Row>
									<Col>
										<FormControl component="fieldset" style={{ width: '100%' }}>
											<FormGroup>
												<div className="settings-box">
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showUnapprovedLeave}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showUnapprovedLeave'}
															/>
														}
														label={'Unapproved Leave'}
													/>
													{leaveTypes && leaveTypes.length > 0 && calendarLeaveTypes ? (
														<>
															<FormControlLabel
																style={{ width: '100%', fontSize: '14px' }}
																control={
																	<Checkbox
																		color={'default'}
																		checked={checkIfAllLeaveSelected()}
																		onChange={(e) => handleLeaveTypeChange(e.target.name)}
																		name={'all'}
																	/>
																}
																label={'Select All'}
															/>
															{leaveTypes.map((leavetype: LeaveType) => (
																<LeaveTypeRow
																	key={leavetype.xeroOrgLeaveTypeID}
																	leaveType={leavetype}
																	appState={props.appState}
																	updateMessages={updateMessages}
																	updateXeroLeaveTypes={updateLeaveTypes}
																	xeroLeaveTypes={leaveTypes}
																	onChecked={handleCustomTypeRemove}
																	onUnChecked={handleCustomTypeAdd}
																	currentOrg={props.currentOrg}
																	currentCalendar={props.currentCalendar}
																	updateCalendar={props.updateCurrentCalendar}
																	isCustom={false}
																/>
															))}
														</>
													) : null}
												</div>
											</FormGroup>
										</FormControl>
									</Col>
								</Row>
							</Col>

							<Col sm="3">
								<Row
									style={{
										justifyContent: 'space-between',
										backgroundColor: '#f9f9fa',
										marginBottom: '-10px',
									}}
								>
									<FormLabel className="settings-label">Custom Types</FormLabel>
									<Col sm="auto" className="position-right" style={{ padding: '20px' }}>
										{I.have('admin', props.appState.permission[props.appState.context]) &&
										props.currentOrg!.xeroOrg!.products!.some((pr) => pr.productID === Product.CustomLeaveTypes.productID) ? (
											<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-xero`}>Add another Custom Type</Tooltip>}>
												<div>
													<Plus className={'settings-plus'} onClick={() => updateShowAddCustomTypeDialog(true)} />
												</div>
											</OverlayTrigger>
										) : (
											<OverlayTrigger
												placement="auto"
												overlay={<Tooltip id={`tooltip-xero`}>Premium Feature - contact support@finlert.com to get started.</Tooltip>}
											>
												<div>
													<Plus className={'settings-plus'} style={{ color: '#82888a' }} />
												</div>
											</OverlayTrigger>
										)}
									</Col>
								</Row>
								{showAddCustomTypeDialog && (
									<LeaveTypePopUp
										show={showAddCustomTypeDialog}
										handleClose={() => updateShowAddCustomTypeDialog(false)}
										titleText={'Add Custom Type'}
										type={'Add'}
										updateMessages={updateMessages}
										updateXeroLeaveTypes={updateNonXeroLeaveTypes}
										updateCalendar={props.updateCurrentCalendar}
										appState={props.appState}
										leaveTypes={nonXeroLeaveTypes}
										xeroOrgID={props.currentOrg.xeroOrg!.xeroOrgID!}
										calendarID={props.currentCalendar.calendarID!}
										calendar={props.currentCalendar}
										currentLeaveType={null}
										isCustom={true}
									/>
								)}
								{I.have('admin', props.appState.permission[props.appState.context]) &&
								props.currentOrg!.xeroOrg!.products!.some((pr) => pr.productID === Product.CustomLeaveTypes.productID) ? (
									<Row>
										<FormGroup as={Col}>
											{props.currentCalendar &&
											props.currentCalendar.calendarID !== '' &&
											nonXeroLeaveTypes &&
											nonXeroLeaveTypes.length !== 0 ? (
												<div className="settings-box">
													{/* <FormControlLabel
                                                    style={{ paddingLeft: '20px' }}
                                                    control={<Checkbox color={'default'} name={'all'} onChange={(e) => handleCustomLeaveTypeChange(e.target.name)} checked={nonXeroLeaveTypes.every(choice => choice.isActive)} />}
                                                    label={'Select All'}
                                                /> */}

													{nonXeroLeaveTypes.map((nxlt: LeaveType) => (
														<LeaveTypeRow
															key={nxlt.xeroOrgLeaveTypeID}
															leaveType={nxlt}
															appState={props.appState}
															updateMessages={updateMessages}
															updateXeroLeaveTypes={updateNonXeroLeaveTypes}
															onUnChecked={handleCustomTypeAdd}
															onChecked={handleCustomTypeRemove}
															xeroLeaveTypes={nonXeroLeaveTypes}
															currentOrg={props.currentOrg}
															currentCalendar={props.currentCalendar}
															updateCalendar={props.updateCurrentCalendar}
															isCustom={true}
														/>
													))}
												</div>
											) : null}
										</FormGroup>
									</Row>
								) : null}
							</Col>

							<Col sm="3">
								<Row
									style={{
										justifyContent: 'space-between',
										backgroundColor: '#f9f9fa',
									}}
								>
									<FormLabel className="settings-label">Celebrate</FormLabel>
								</Row>
								<Row>
									<FormGroup as={Col}>
										{props.currentCalendar && props.currentCalendar.calendarID !== '' ? (
											<div className="settings-box">
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={checkIfAllCelebrationsSelected()}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'all'}
														/>
													}
													label={'Select All'}
												/>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.showAnniversary}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'showAnniversary'}
														/>
													}
													label={'Anniversaries'}
												/>
												<FormControlLabel
													style={{ width: '100%', paddingLeft: '30px' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.showAnniversaryYears}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'showAnniversaryYears'}
														/>
													}
													label={'Display years'}
												/>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.showBirthday}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'showBirthday'}
														/>
													}
													label={'Birthdays'}
												/>
												<Row style={{ flexWrap: 'nowrap' }}>
													<FormControlLabel
														style={{ width: '100%', paddingLeft: '30px', marginLeft: '15px' }}
														control={
															<Checkbox
																color={'default'}
																checked={!props.currentCalendar.showDayOfBirthday}
																onChange={(e) => handleCelebrationChange(e.target.name)}
																name={'showDayOfBirthday'}
															/>
														}
														label={'Suppress day of birthday'}
													/>
													<Col sm="auto" className="position-right" style={{ color: '#7c7e80', paddingTop: '10px' }}>
														<OverlayTrigger
															placement="auto"
															overlay={
																<Tooltip id={`tooltip-show-day-of-bday`} style={{ maxWidth: '100%' }}>
																	<div style={{ textAlign: 'left', width: '100%' }}>
																		For privacy reasons, we offer the ability to suppress the actual date of the birthday in
																		alerts, and all birthdays will be on the 1st of the month in calendar.
																	</div>
																</Tooltip>
															}
														>
															<div>
																<Info />
															</div>
														</OverlayTrigger>
													</Col>
												</Row>

												<FormLabel className="settings-label">New Team Member Milestones</FormLabel>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.newEmployee1Month}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'newEmployee1Month'}
														/>
													}
													label={'1 month'}
												/>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.newEmployee3Months}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'newEmployee3Months'}
														/>
													}
													label={'3 months'}
												/>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.newEmployee6Months}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'newEmployee6Months'}
														/>
													}
													label={'6 months'}
												/>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.newEmployee18Months}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'newEmployee18Months'}
														/>
													}
													label={'18 months'}
												/>
											</div>
										) : null}
									</FormGroup>
								</Row>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="settings-card">
						<Row>
							<Col sm="3">
								<Row style={{ justifyContent: 'space-between' }}>
									<FormLabel className="settings-inner-label">Information to Include</FormLabel>
								</Row>
								<Row>
									<FormGroup as={Col}>
										{props.currentCalendar && props.currentCalendar.calendarID !== '' ? (
											<div className="settings-box">
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={checkIfAllIncludedInfoSelected()}
															onChange={(e) => handleCalendarChange(e.target.name)}
															name={'all'}
														/>
													}
													label={'Select All'}
												/>
												<Col
													sm="auto"
													style={{
														flex: '1',
														textAlign: 'left',
														paddingLeft: '20px',
													}}
												>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showLeaveType}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showLeaveType'}
															/>
														}
														label={'Leave Type'}
													/>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showAmount}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showAmount'}
															/>
														}
														label={'Amount'}
													/>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showPayrollDescription}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showPayrollDescription'}
															/>
														}
														label={'Description from payroll system'}
													/>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showCustomLeaveDescription}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showCustomLeaveDescription'}
															/>
														}
														label={'Description from custom leave'}
													/>
												</Col>
											</div>
										) : null}
										{props.currentOrg.xeroOrg &&
										(props.currentOrg.xeroOrg.version === 'AU' || props.currentOrg.xeroOrg.version === 'AUONRAMP') ? (
											<div className="settings-box">
												<Col
													sm="auto"
													style={{
														flex: '1',
														textAlign: 'left',
														paddingLeft: '20px',
													}}
												>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showTitle}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showTitle'}
															/>
														}
														label={'Title'}
													/>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showGroup}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showGroup'}
															/>
														}
														label={'Employee Group'}
													/>
												</Col>
											</div>
										) : null}
									</FormGroup>
								</Row>
							</Col>
							<Col sm="4">
								<Row
									style={{
										justifyContent: 'space-between',
										marginBottom: '-10px',
									}}
								>
									<FormLabel className="settings-inner-label">Public Holidays / External Calendars</FormLabel>
									<Col sm="auto" className="position-right" style={{ padding: '20px' }}>
										{I.have('admin', props.appState.permission[props.appState.context]) && (
											<OverlayTrigger
												placement="auto"
												overlay={<Tooltip id={`tooltip-xero`}>Add Public Holidays / External Calendars</Tooltip>}
											>
												<div>
													<Plus className={'settings-plus'} onClick={() => updateShowAddCustomFeedDialog(true)} />
												</div>
											</OverlayTrigger>
										)}
									</Col>
								</Row>
								{showAddCustomFeedDialog && (
									<CustomFeedPopUp
										show={showAddCustomFeedDialog}
										handleClose={() => updateShowAddCustomFeedDialog(false)}
										titleText={'Public Holidays / External Calendars'}
										updateMessages={updateMessages}
										appState={props.appState}
										currentCalendar={props.currentCalendar}
										updateCalendar={props.updateCurrentCalendar}
									/>
								)}
								<Row>
									<FormGroup as={Col}>
										{props.currentCalendar && props.currentCalendar.calendarID !== '' && (
											<div className="settings-box">
												{props.currentCalendar.inputFeeds &&
													props.currentCalendar.inputFeeds.map((feed) => (
														<OverlayTrigger
															key={feed.calendarInputFeedID}
															placement="right"
															overlay={<Tooltip id={`tooltip-ical Feed`}>{feed.url}</Tooltip>}
														>
															<FormControlLabel
																key={feed.calendarInputFeedID}
																style={{ width: '100%' }}
																control={
																	<Checkbox
																		color={'default'}
																		checked={true}
																		onChange={() => deleteCalendarFeed(feed.calendarInputFeedID!)}
																		name={feed.url}
																	/>
																}
																label={feed.url}
															/>
														</OverlayTrigger>
													))}
											</div>
										)}
									</FormGroup>
								</Row>
							</Col>
							<Col sm="4">
								<ApproverSettingsPanel
									currentCalendar={props.currentCalendar}
									appState={props.appState}
									handleCalendarChange={handleGenericCalendarChange}
								/>
							</Col>
						</Row>
					</FormGroup>
					<Messages.Messages messages={messages} updateMessage={updateMessages} />
				</>
			)
		} else {
			return (
				<div className="loading-gif">
					<img src={LoadingImage} alt="Loading ..." />
				</div>
			)
		}
	} else {
		return null
	}
}

export default SettingsFrame
