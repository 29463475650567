import React from 'react'
import { Col, FormGroup, FormLabel, Row } from 'react-bootstrap'

import * as Request from '../../../utilities/request'
import { FormSelect, MultiOption, SelectOption } from '../../ui/form/Select'
import LoadingImage from '../../../images/Finlert-loading-GIF_100px.gif'

import { Calendar as CalendarData, User } from '../../../../../back-end/utilities/apiDefinitions'
import * as AppDefinitions from '../../../App.d'

interface ApproverSettingsPanelProps {
	currentCalendar: CalendarData
	appState: AppDefinitions.AppState
	handleCalendarChange: (calendar: Partial<CalendarData>) => Promise<void>
}

const LeaveApproversRuleOptions = [
	{
		label: 'At least one approver must approve',
		value: 'one',
	},
	{
		label: 'All approvers must approve',
		value: 'all',
	},
]

export const ApproverSettingsPanel = (props: ApproverSettingsPanelProps) => {
	const [users, setUsers] = React.useState<User[]>([])
	const [approvers, setApprovers] = React.useState<string[]>(props.currentCalendar.leaveApprovers || [])

	React.useEffect(() => {
		const getUsers = async () => {
			const usersRes = await Request.get('user', props.appState.authState)
			setUsers(usersRes.data.users)
		}

		if (props.appState.authState.isLoggedIn) {
			getUsers()
		}
	}, [props.appState.authState])

	React.useEffect(() => {
		setApprovers(props.currentCalendar.leaveApprovers || []) // in case this updates after the component loads
	}, [props.currentCalendar.leaveApprovers])

	if (users.length === 0) {
		return (
			<div className="loading-gif">
				<img src={LoadingImage} alt="Loading ..." />
			</div>
		)
	}

	return (
		<>
			<Row
				style={{
					justifyContent: 'space-between',
					marginBottom: '-10px',
				}}
			>
				<FormLabel className="settings-inner-label">Leave Approvers</FormLabel>
			</Row>
			<Row>
				<FormGroup as={Col}>
					{props.currentCalendar && props.currentCalendar.calendarID !== '' && (
						<div className="settings-box" style={{ padding: '10px' }}>
							<Row>
								<Col>
									<FormLabel>Users who can approve in this calendar</FormLabel>
									<FormSelect
										name="leaveApprovers"
										options={users.map((user) => ({ label: `${user.firstName} ${user.lastName}`, value: user.userID! }))}
										value={approvers}
										onChange={(options) => {
											if (options === null) {
												setApprovers([])
											}
											const selected = (options as MultiOption<string>).map((opt) => opt.value)
											setApprovers(selected)
										}}
										onBlur={() => {
											props.handleCalendarChange({ leaveApprovers: approvers })
										}}
										isMulti={true}
										isClearable={true}
										isSearchable={true}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormLabel>How many need to approve before request is approved</FormLabel>
									<FormSelect
										name="leaveApproversRule"
										options={LeaveApproversRuleOptions}
										value={props.currentCalendar.leaveApproversRule}
										onChange={(option) => {
											if (!option) {
												return
											}
											const value = (option as SelectOption<unknown>).value as 'one' | 'all'
											props.handleCalendarChange({ leaveApproversRule: value })
										}}
										isMulti={false}
										isClearable={false}
									/>
								</Col>
							</Row>
						</div>
					)}
				</FormGroup>
			</Row>
		</>
	)
}
