import React from 'react'

// components
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import * as Messages from '../../ui/messages/messages'

// utillites
import * as Request from '../../../utilities/request'
import * as I from '../../../utilities/me'
import { CalendarNameProps } from './calendarName'

export const CalendarFirstDayOfWeek = (props: CalendarNameProps) => {
	const DaysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

	const postFirstDayOfWeekChange = async (selected: number) => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			try {
				await Request.put(
					`calendar/${props.calendarSettings!.calendarID}`,
					{ ...props.calendarSettings, firstDayOfWeek: selected },
					props.appState.authState
				)
				props.updateCurrentCalendar({
					...props.calendarSettings!,
					firstDayOfWeek: selected,
				})
				props.updateMessages(Messages.addMessage('success', 'Succesfully updated first day of the week'))
			} catch (err) {
				console.log('Update Error: ', err)
				props.updateMessages(Messages.addMessage('danger', 'Failed to update first day of the week'))
			}
		} else {
			props.updateMessages(Messages.addMessage('danger', `You do no have permission to update a calendar's first day of the week`))
		}
	}

	return (
		<>
			<DropdownButton
				as={ButtonGroup}
				id={'dropdown'}
				variant={'primary'}
				title={DaysOfTheWeek[props.calendarSettings!.firstDayOfWeek!]}
				onSelect={(eventKey) => {
					if (eventKey) {
						const key = Number(eventKey)
						postFirstDayOfWeekChange(key)
					}
				}}
				alignRight
				style={{
					display: 'inline',
					width: '50%',
					paddingLeft: '0px',
					fontSize: '14px',
				}}
				className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : ''}
			>
				{DaysOfTheWeek.map((day, index) => (
					<Dropdown.Item key={day} eventKey={String(index)} active={props.calendarSettings!.firstDayOfWeek! === index}>
						{day}
					</Dropdown.Item>
				))}
			</DropdownButton>
		</>
	)
}
