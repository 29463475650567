const LeaveCalTrial = {
	productID: '5528c381-6a72-4797-8797-0f1daed4df62',
	name: 'LeaveCal Trial',
	xeroID: '',
}

const OversightTrial = {
	productID: 'b5ba756e-719a-4e71-9047-969b1daf702f',
	name: 'Oversight Trial',
	xeroID: '',
}

const LeaveBalance = {
	productID: 'fce5bbd4-df63-4f40-8f05-ba39b780df7c',
	name: 'Leave Balances',
	xeroID: '',
}

const CustomGroups = {
	productID: 'f888f16e-60d6-4a88-b84a-f06e7bb7ec31',
	name: 'Custom Groups',
	xeroID: '',
}

const CustomLeaveTypes = {
	productID: '984a25d6-5778-4315-84ec-2b8b9c9b9f74',
	name: 'Custom Leave Types',
	xeroID: '',
}

const LeaveApproval = {
	productID: '98f89aed-3a26-4e38-884c-49db5625130d',
	name: 'LeaveCal Leave Approvals',
	xeroID: '',
}

const TaygTrial = {
	productID: '18dfd929-2c56-49d0-938f-4f5fe057d0d6',
	name: 'Tayg Trial',
	xeroID: '',
}

const SubSyncTrial = {
	productID: '6affdf3e-fded-11ed-bc87-022b0239de30',
	name: 'SubSync Trial',
	xeroID: '',
}

export { LeaveBalance, LeaveCalTrial, OversightTrial, CustomGroups, CustomLeaveTypes, LeaveApproval, TaygTrial, SubSyncTrial }
