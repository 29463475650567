import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'

// definitions
import { LeavePeriod } from '../../../../../back-end/common/leaveApplication'
import { MessageAction } from '../../ui/messages/message'
import * as AppDefinitions from '../../../App.d'
import { AccountXeroOrg, Calendar as CalendarData, LeaveApplication } from '../../../../../back-end/utilities/apiDefinitions'

// utilities
import moment from 'moment'
import { LeavePeriodStatus } from '../../ui/bigCalendar/utilities'

interface UnapprovedLeaveApplication extends LeaveApplication {
	leaveTypeBalance: string
}

interface UnapprovedLeaveRowProps {
	appState: AppDefinitions.AppState
	unapprovedLeaves: UnapprovedLeaveApplication[]
	updateMessages: (e: MessageAction) => void
	updateCurrentCalendar: (cal: CalendarData) => void
	unapprovedLeave: UnapprovedLeaveApplication
	index: number
	currentCalendar: CalendarData
	currentOrg: AccountXeroOrg
	updateLeaveStatus: (leave: UnapprovedLeaveApplication, action: string) => void
	isSubmitting: boolean
}

const UnapprovedLeaveRow = (props: UnapprovedLeaveRowProps) => {
	const getAmount = (leaveApplication: UnapprovedLeaveApplication) => {
		const totalUnits = leaveApplication.leavePeriod
			? leaveApplication.leavePeriod.reduce((total: number, leavePeriod: LeavePeriod) => Number(total + (Number(leavePeriod.numberOfUnits) || 0)), 0)
			: 0

		return `${totalUnits} ${leaveApplication.leaveType?.unitType}`
	}

	const hasLeaveStatus = (leavePeriod: LeavePeriod[], status: LeavePeriodStatus) => {
		return leavePeriod.some((lp) => lp.status === status)
	}

	const myUserID = props.appState.authState.attributes?.['custom:userId']

	return (
		<tr key={props.unapprovedLeave.leaveApplicationID} className={`stripe-row ${props.index % 2 !== 0 ? 'stripe-row-body' : ''}`}>
			<td>
				{props.unapprovedLeave.employee!.alias
					? props.unapprovedLeave.employee!.alias
					: `${props.unapprovedLeave.employee!.firstName} ${props.unapprovedLeave.employee!.lastName}`}
			</td>
			{/* Leave by default as we don't have cashout coming through */}
			{/* <td>Leave</td> */}
			<td>{props.unapprovedLeave.leaveType!.name}</td>
			<td>{hasLeaveStatus(props.unapprovedLeave.leavePeriod!, LeavePeriodStatus.pending) ? 'Unapproved' : 'Approved'}</td>
			<td>{moment.utc(props.unapprovedLeave.startDate).format('DD-MM-YYYY')}</td>
			<td>{moment.utc(props.unapprovedLeave.endDate).format('DD-MM-YYYY')}</td>
			<td>{getAmount(props.unapprovedLeave)}</td>
			<td>{props.unapprovedLeave.leaveTypeBalance}</td>
			<td>{props.unapprovedLeave.description}</td>
			<td>
				{props.unapprovedLeave.responses?.map((response) => `${response.userFirstName} ${response.userLastName}`).join(', ')}
				<br />
				{`${(props.currentCalendar.leaveApprovers?.length || 0) - (props.unapprovedLeave.responses?.length || 0)} pending`}
			</td>
			{props.currentCalendar.leaveApprovers?.some((approver) => approver === myUserID) &&
			props.unapprovedLeave.responses?.every((response) => response.userID !== myUserID) ? (
				<td>
					<ButtonGroup>
						{hasLeaveStatus(props.unapprovedLeave.leavePeriod!, LeavePeriodStatus.pending) && (
							<Button onClick={() => props.updateLeaveStatus(props.unapprovedLeave, 'approve')} variant={'info'} disabled={props.isSubmitting}>
								Approve
							</Button>
						)}
						{hasLeaveStatus(props.unapprovedLeave.leavePeriod!, LeavePeriodStatus.approved) ||
							hasLeaveStatus(props.unapprovedLeave.leavePeriod!, LeavePeriodStatus.pending)}
						<Button onClick={() => props.updateLeaveStatus(props.unapprovedLeave, 'reject')} variant={'danger'} disabled={props.isSubmitting}>
							Reject
						</Button>
					</ButtonGroup>
				</td>
			) : (
				<td></td>
			)}
		</tr>
	)
}

export { UnapprovedLeaveRow }
export type { UnapprovedLeaveApplication }
