import React from 'react'
import { Row, Form, FormLabel, Col, Button, FormGroup, Modal } from 'react-bootstrap'
import Moment from 'moment'

// definitions
import { AccountXeroOrg, Calendar as CalendarData, Employee, LeaveApplication, LeaveType, Recurrence } from '../../../../../back-end/utilities/apiDefinitions'
import * as AppDefinitions from '../../../App.d'

// components
import { CalendarLeave } from '../../ui/bigCalendar/bigCalendar'

// utillites
import * as Request from '../../../utilities/request'
import * as I from '../../../utilities/me'
import Select from 'react-select'
import * as yup from 'yup'
import { MessageAction } from '../../ui/messages/message'
import { formatLeaveTitle, getAmount } from '../../ui/bigCalendar/utilities'
import { HandleEventPopUp } from './handleEventPopup'

type EndingRecurrences = 'Never' | 'On' | 'After'

interface CalendarEntryPopUpProps {
	handleClose: () => void
	titleText: string
	types: LeaveType[] | null
	employees: Employee[] | undefined
	leaveApplications: CalendarLeave[]
	updateLeaveApplications: React.Dispatch<React.SetStateAction<LeaveApplication[]>>
	updateMessages: (message: MessageAction) => void
	selectedDateForEvent?: string | null
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	xeroOrgCalendars: CalendarData[]
	leaveApplicationID?: string | null
	showCalendar?: boolean
}

const leaveApp: LeaveApplication & { updatedStartOrEnd: boolean } = {
	description: '',
	startDate: undefined,
	endDate: undefined,
	employeeID: '',
	xeroOrgLeaveTypeID: '',
	leavePeriod: [
		{
			startDate: '',
			endDate: '',
			numberOfUnits: undefined,
		},
	],
	leaveType: undefined,
	employee: undefined,
	updatedStartOrEnd: true,
}

const recurrenceEntry: Recurrence = {
	recurrenceID: '',
	weeklyRegularity: undefined,
	startDate: undefined,
	endDate: null,
	isMonday: false,
	isTuesday: false,
	isWednesday: false,
	isThursday: false,
	isFriday: false,
	isSaturday: false,
	isSunday: false,
	typeIsWeekly: true,
	typeIsMonthly: false,
	typeIsAnnually: false,
}

const recurrenceRegularity = [
	{ label: 'week(s)', value: 'week' },
	{ label: 'month(s)', value: 'month' },
	{ label: 'year(s)', value: 'year' },
]

export const CalendarEntryPopUp = (props: CalendarEntryPopUpProps) => {
	const [isEdit, updateIsEdit] = React.useState<boolean>(false)
	const [leaveApplication, updateLeaveApplication] = React.useState<LeaveApplication & { updatedStartOrEnd: boolean }>(leaveApp)
	const [recurring, updateRecurring] = React.useState<string>('No')
	const [recurrenceLeave, updateRecurrenceLeave] = React.useState<Recurrence>(recurrenceEntry)
	const [endingRecurrence, updateEndingRecurrence] = React.useState<EndingRecurrences>('Never')
	const [occurences, updateOccurences] = React.useState<number | null>(null)
	const [showEditRecurrenceDialog, updateShowEditRecurrenceDialog] = React.useState<boolean>(false)
	const [showDialog, updateShowDialog] = React.useState<boolean>(props.show)

	React.useEffect(
		() => {
			const fetchData = async () => {
				const [leaveApplicationReq] = await Promise.all([
					Request.get(`leave?where=LeaveApplicationID=in(${props.leaveApplicationID})`, props.appState.authState),
				])
				const leaveApplication = leaveApplicationReq.data.leaveApplications[0]
				const recurrence = leaveApplicationReq.data.leaveApplications[0].recurrence!
				const startDate = leaveApplication.startDate ? leaveApplication.startDate.slice(0, 10) : null
				const endDate = leaveApplication.endDate ? leaveApplication.endDate.slice(0, 10) : null
				updateLeaveApplication({
					...leaveApplication,
					startDate: startDate,
					endDate: endDate,
					updatedStartOrEnd: false,
					leavePeriod: [
						{
							...leaveApplication.leavePeriod![0],
							numberOfUnits: calculateNumberOfHours(startDate, endDate),
						},
					],
				})
				updateIsEdit(true)

				if (recurrence.recurrenceID !== null) {
					updateRecurring('Yes')
					updateRecurrenceLeave({
						...recurrence,
						startDate: recurrence.startDate.slice(0, 10),
						endDate: recurrence.endDate ? recurrence.endDate.slice(0, 10) : null,
					})
					updateEndingRecurrence(recurrence.endDate ? 'On' : 'Never')
				}
			}

			if (props.appState.authState && props.leaveApplicationID) {
				fetchData()
			}

			if (props.appState.authState && props.selectedDateForEvent) {
				updateLeaveApplication({
					...leaveApplication,
					startDate: props.selectedDateForEvent,
					endDate: props.selectedDateForEvent,
					leavePeriod: [
						{
							...leaveApplication.leavePeriod![0],
							numberOfUnits: calculateNumberOfHours(props.selectedDateForEvent, props.selectedDateForEvent),
						},
					],
				})
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.leaveApplicationID, props.selectedDateForEvent]
	)

	// ---- Validation Schemas ---- //
	const schema = React.useMemo(() => getSchema(endingRecurrence), [endingRecurrence])

	const resetModal = React.useCallback(() => {
		updateLeaveApplication({ ...leaveApp })
		updateRecurring('No')
		updateRecurrenceLeave(recurrenceEntry)
		updateEndingRecurrence('Never')
		updateOccurences(null)
	}, [updateLeaveApplication, updateRecurring, updateRecurrenceLeave, updateEndingRecurrence, updateOccurences])

	const handleUpdateNonRecurringEvent = React.useCallback(
		async (updatedLeaveApp: LeaveApplication) => {
			try {
				const employeeID = updatedLeaveApp.employeeID
				const affectedCals = props.xeroOrgCalendars!.filter((c) => c.employees!.find((emp) => emp.employeeID === employeeID))
				const [req] = await Promise.all([
					Request.put(`leave/${props.leaveApplicationID}`, updatedLeaveApp, props.appState.authState),
					...affectedCals.map(async (c) =>
						Request.post(`organisation/${c.accountXeroOrg!.xeroOrgID}/updatecalendar`, null, props.appState.authState)
					),
				])

				if (req.data.success) {
					props.updateMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: 'Successfully updated custom leave',
							timeout: 3000,
							dismissible: true,
						},
					})
					resetModal()
					props.handleClose()
					const newLeave = req.data.leaveApplications[0]
					props.updateLeaveApplications(props.leaveApplications.concat(newLeave))
					updateShowDialog(false)
				}
			} catch (err) {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `Unable to update event`,
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		},
		[
			props.xeroOrgCalendars,
			props.leaveApplicationID,
			props.appState.authState,
			props.currentCalendar,
			props.currentOrg,
			props.leaveApplications,
			props.updateLeaveApplications,
			props.updateMessages,
			props.handleClose,
		]
	)

	const handleUpdatePress = React.useCallback(async () => {
		if (
			I.have('admin', props.appState.permission[props.appState.context]) ||
			props.appState.authState.attributes!['custom:userId'] === leaveApplication.createUserID
		) {
			const updatedLA = {
				...leaveApplication,
				leavePeriod: [
					{
						...leaveApplication.leavePeriod![0],
						startDate: leaveApplication.startDate,
						endDate: leaveApplication.endDate,
						status: 0,
					},
				],
				recurrence:
					recurring === 'Yes'
						? {
								weeklyRegularity: recurrenceLeave.weeklyRegularity,
								startDate: recurrenceLeave.startDate,
								endDate: recurrenceLeave.endDate,
								isMonday: recurrenceLeave.isMonday,
								isTuesday: recurrenceLeave.isTuesday,
								isWednesday: recurrenceLeave.isWednesday,
								isThursday: recurrenceLeave.isThursday,
								isFriday: recurrenceLeave.isFriday,
								isSaturday: recurrenceLeave.isSaturday,
								isSunday: recurrenceLeave.isSunday,
						  }
						: undefined,
			}

			try {
				await schema.validate(updatedLA, { abortEarly: false })
				if (recurring === 'Yes') {
					updateShowEditRecurrenceDialog(true)
					updateShowDialog(false)
				} else {
					handleUpdateNonRecurringEvent(updatedLA)
				}
			} catch (validationError) {
				if (validationError instanceof yup.ValidationError) {
					const newErrors = validationError.inner.map((err) => err.errors.join('\n'))
					props.updateMessages({
						type: 'add',
						data: {
							severity: 'danger',
							message: `Unable to update Custom calendar entry: \n\n ${newErrors
								.filter((val: string, id: number, array: string | string[]) => array.indexOf(val) === id)
								.join('\n')}`,
							timeout: 3000,
							dismissible: true,
						},
					})
				}
				return
			}
		} else {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `You don't have permissions to update this Custom calendar entry`,
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}, [props.updateMessages, leaveApplication])

	const handleCalendarEntryCreation = React.useCallback(async () => {
		try {
			const newLA = {
				...leaveApplication,
				isNonXero: true,
				updatedDateUTC: Moment.utc(new Date()).format('YYYY-MM-DD'),
				xeroOrgLeaveTypeID: leaveApplication.leaveType ? leaveApplication.leaveType!.xeroOrgLeaveTypeID : '',
				leavePeriod: [
					{
						...leaveApplication.leavePeriod![0],
						startDate: leaveApplication.startDate,
						endDate: leaveApplication.endDate,
						status: 0,
					},
				],
				recurrence:
					recurring === 'Yes'
						? {
								weeklyRegularity: recurrenceLeave.weeklyRegularity,
								startDate: recurrenceLeave.startDate,
								endDate: recurrenceLeave.endDate,
								isMonday: recurrenceLeave.isMonday,
								isTuesday: recurrenceLeave.isTuesday,
								isWednesday: recurrenceLeave.isWednesday,
								isThursday: recurrenceLeave.isThursday,
								isFriday: recurrenceLeave.isFriday,
								isSaturday: recurrenceLeave.isSaturday,
								isSunday: recurrenceLeave.isSunday,
								typeIsWeekly: recurrenceLeave.typeIsWeekly,
								typeIsMonthly: recurrenceLeave.typeIsMonthly,
								typeIsAnnually: recurrenceLeave.typeIsAnnually,
						  }
						: undefined,
			}

			try {
				await schema.validate(newLA, { abortEarly: false })
			} catch (validationError) {
				if (validationError instanceof yup.ValidationError) {
					const newErrors = validationError.inner.map((err) => err.errors.join('\n'))
					props.updateMessages({
						type: 'add',
						data: {
							severity: 'danger',
							message: `Unable to add Custom calendar entry: \n\n ${newErrors
								.filter((val: string, id: number, array: string | string[]) => array.indexOf(val) === id)
								.join('\n')}`,
							timeout: 3000,
							dismissible: true,
						},
					})
				}
				return
			}

			const employeeID = newLA.employeeID
			const affectedCals = props.xeroOrgCalendars!.filter((c) => c.employees!.find((emp) => emp.employeeID === employeeID))
			const [req] = await Promise.all([
				Request.post(`leave`, newLA, props.appState.authState),
				...affectedCals.map(async (c) => Request.post(`organisation/${c.accountXeroOrg!.xeroOrgID}/updatecalendar`, null, props.appState.authState)),
			])
			if (req.data.success) {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully created custom leave',
						timeout: 3000,
						dismissible: true,
					},
				})
				const newLeave = req.data.leaveApplications[0]
				const event = {
					start: newLeave.startDate!,
					end: newLeave.endDate!,
					description: newLeave.description!,
					isNonXero: newLeave.leaveType.isNonXero,
					leaveApplicationID: newLeave.leaveApplicationID,
					title: `${
						(props.currentCalendar.showLeaveType && newLeave.leaveType) ||
						(newLeave.leaveType && newLeave.leaveType.isNonXero && newLeave.leaveType.showTypeInCalendar)
							? newLeave.leaveType.name
							: 'Leave'
					}: ${newLeave.employee!.alias ? newLeave.employee!.alias : `${newLeave.employee!.firstName} ${newLeave.employee!.lastName}`}${
						props.currentCalendar.showTitle ? formatLeaveTitle(newLeave.employee!.jobTitle!, '-') : ''
					}${props.currentCalendar.showGroup ? formatLeaveTitle(newLeave.employee!.groupName!, '-') : ''}${
						props.currentCalendar.showAmount && props.currentOrg.xeroOrg
							? getAmount(newLeave, props.currentOrg.xeroOrg.showLeaveInDays!, props.currentOrg.xeroOrg.hoursInDay!)
							: ''
					}`,
				}
				props.updateLeaveApplications(props.leaveApplications.concat(event))
				resetModal()
				props.handleClose()
			}
		} catch (err) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to create custom leave',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}, [
		props.xeroOrgCalendars,
		props.appState.authState,
		props.currentCalendar,
		props.currentOrg,
		props.updateLeaveApplications,
		props.leaveApplications,
		props.handleClose,
		props.updateMessages,
	])

	const calculateNumberOfHours = React.useCallback(
		(startDate: string | undefined | null, endDate: string | undefined | null) => {
			if (startDate && endDate && props.currentOrg.xeroOrg) {
				const days = Math.round(Math.abs((new Date(startDate).getTime() - new Date(endDate).getTime()) / (24 * 60 * 60 * 1000))) + 1 // to include end date
				return Number(days * props.currentOrg.xeroOrg.hoursInDay!)
			}

			return 0
		},
		[props.currentOrg]
	)

	return (
		<>
			<Modal
				show={showDialog}
				aria-labelledby="modal"
				centered
				onHide={() => {
					resetModal()
					props.handleClose()
				}}
			>
				{props.titleText ? (
					<Modal.Header>
						<Modal.Title>{props.titleText}</Modal.Title>
					</Modal.Header>
				) : null}
				<Modal.Body>
					<FormGroup>
						<Row>
							<Col sm="6">
								<Form.Control
									type="date"
									name="startDate"
									value={leaveApplication.startDate ? leaveApplication.startDate.split('T')[0] : leaveApplication.startDate}
									max={Moment.utc().add('1', 'year').format('YYYY-MM-DD')}
									onChange={(e) => {
										const value = e.target.value.slice(0, 10)
										if (recurring === 'Yes') {
											updateRecurrenceLeave({
												...recurrenceLeave,
												startDate: value,
												endDate: value,
											})
										}
										const todayInOneYear = Moment.utc().add('1', 'year')
										const threeMonthsBefore = Moment.utc().subtract('90', 'days')
										if (Moment.utc(value).isSameOrAfter(threeMonthsBefore) && Moment.utc(value).isSameOrBefore(todayInOneYear)) {
											updateLeaveApplication({
												...leaveApplication,
												startDate: value,
												endDate: value,
												updatedStartOrEnd: true,
												leavePeriod: [
													{
														...leaveApplication.leavePeriod![0],
														numberOfUnits: calculateNumberOfHours(value, value),
													},
												],
											})
										}
									}}
								/>
							</Col>
							<Col sm="6">
								<Form.Control
									type="date"
									name="endDate"
									value={leaveApplication.endDate ? leaveApplication.endDate.split('T')[0] : leaveApplication.endDate}
									onChange={(e) => {
										const value = e.target.value.slice(0, 10) // we just want the date
										updateLeaveApplication({
											...leaveApplication,
											endDate: value,
											updatedStartOrEnd: true,
											leavePeriod: [
												{
													...leaveApplication.leavePeriod![0],
													numberOfUnits: calculateNumberOfHours(leaveApplication.startDate, value),
												},
											],
										})
									}}
								/>
							</Col>
						</Row>
						<Row style={{ padding: '15px' }}>
							<Form.Control
								type="number"
								placeholder={'Number of hours'}
								value={leaveApplication.leavePeriod![0].numberOfUnits}
								onChange={(e) =>
									updateLeaveApplication({
										...leaveApplication,
										leavePeriod: [
											{
												...leaveApplication.leavePeriod![0],
												numberOfUnits: Number(e.target.value),
											},
										],
									})
								}
							/>
						</Row>
						<Row>
							<Col style={{ paddingBottom: '15px' }}>
								<Select
									placeholder={'Select Type'}
									options={
										I.have('admin', props.appState.permission[props.appState.context])
											? props.types!.map((t) => ({
													label: t.name!,
													value: t.xeroOrgLeaveTypeID,
											  }))
											: props
													.types!.filter((t: LeaveType) => t.isEnteredByAdmin === false)
													.map((t) => ({
														label: t.name!,
														value: t.xeroOrgLeaveTypeID,
													}))
									}
									value={
										leaveApplication.leaveType !== undefined
											? {
													label: leaveApplication.leaveType!.name!,
													value: leaveApplication.leaveType!.xeroOrgLeaveTypeID,
											  }
											: undefined
									}
									onChange={(e) => {
										const selected = props.types!.find((t) => t.xeroOrgLeaveTypeID === e!.value)
										updateLeaveApplication({
											...leaveApplication,
											xeroOrgLeaveTypeID: selected!.xeroOrgLeaveTypeID,
											leaveType: selected,
										})
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col style={{ paddingBottom: '15px' }}>
								<Select
									placeholder={'Select Team Member'}
									options={props.employees!.map((e: Employee) => ({
										label: `${e!.alias ? e.alias : `${e!.firstName} ${e!.lastName}`}`,
										value: e.employeeID,
									}))}
									value={
										leaveApplication.employee !== undefined
											? {
													label: `${
														leaveApplication.employee!.alias
															? leaveApplication.employee!.alias
															: `${leaveApplication.employee!.firstName} ${leaveApplication.employee!.lastName}`
													}`,
													value: leaveApplication.employeeID,
											  }
											: undefined
									}
									onChange={(e) => {
										const selected = props.employees!.find((t) => t.employeeID === e!.value)
										updateLeaveApplication({
											...leaveApplication,
											employeeID: selected!.employeeID,
											employee: selected,
										})
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm="4">
								<FormLabel>Description (for inclusion in Calendar entries):</FormLabel>
							</Col>
							<Col sm="8">
								<textarea
									name="description"
									value={leaveApplication.description}
									className={'form-control'}
									rows={2}
									style={{ marginBottom: '15px' }}
									onChange={(e) =>
										updateLeaveApplication({
											...leaveApplication,
											description: e.target.value,
										})
									}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm="4">
								<FormLabel>Recurring Event:</FormLabel>
							</Col>
							<Col sm="6">
								<label>
									<input
										type="radio"
										value="Yes"
										name="recurrence"
										checked={recurring === 'Yes'}
										onClick={() => {
											updateRecurring('Yes')
											updateRecurrenceLeave({
												...convertDayIntoRecurrence(recurrenceLeave, new Date(leaveApplication.startDate!).getDay()),
												startDate: leaveApplication.startDate,
											})
											convertDayIntoRecurrence(recurrenceLeave, new Date(leaveApplication.startDate!).getDay())
										}}
									/>{' '}
									Yes{' '}
								</label>
								<label>
									<input
										type="radio"
										value="No"
										name="recurrence"
										checked={recurring === 'No'}
										onClick={() => {
											updateRecurring('No')
											updateRecurrenceLeave(recurrenceEntry)
										}}
										style={{ marginLeft: '15px' }}
									/>{' '}
									No{' '}
								</label>
							</Col>
						</Row>
						{recurring === 'Yes' ? (
							<>
								<Row style={{ marginTop: '15px' }}>
									<Col sm="3">
										<FormLabel>Repeat every </FormLabel>
									</Col>
									<Col sm="3">
										<input
											type="number"
											min="1"
											style={{ marginTop: '-8px' }}
											className={'form-control'}
											value={recurrenceLeave.weeklyRegularity}
											onChange={(event) =>
												updateRecurrenceLeave({
													...recurrenceLeave,
													weeklyRegularity: parseInt(event.target.value),
												})
											}
										/>
									</Col>
									<Col sm="4" style={{ marginTop: '-8px' }}>
										<Select
											placeholder={'Select Recurrence Regularity'}
											options={recurrenceRegularity}
											value={recurrenceRegularity.find(
												(rec) =>
													rec.value ===
													(recurrenceLeave.typeIsWeekly
														? 'week'
														: recurrenceLeave.typeIsAnnually
														? 'year'
														: recurrenceLeave.typeIsMonthly
														? 'month'
														: '')
											)}
											onChange={(e) => {
												switch (e!.value) {
													case 'week':
														updateRecurrenceLeave({
															...recurrenceLeave,
															typeIsWeekly: true,
															typeIsAnnually: false,
															typeIsMonthly: false,
														})
														break
													case 'month':
														updateRecurrenceLeave({
															...recurrenceLeave,
															typeIsWeekly: false,
															typeIsAnnually: false,
															typeIsMonthly: true,
														})
														break
													case 'year':
														updateRecurrenceLeave({
															...recurrenceLeave,
															typeIsWeekly: false,
															typeIsAnnually: true,
															typeIsMonthly: false,
														})
														break
													default:
														return
												}
											}}
										/>
									</Col>
								</Row>
								<Row style={{ marginTop: '15px' }}>
									<Col sm="3">
										<FormLabel>Repeat on </FormLabel>
									</Col>
								</Row>
								<Row style={{ marginLeft: '0px', paddingBottom: '10px' }}>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isMonday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Monday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isMonday: !recurrenceLeave.isMonday,
											})
										}
									>
										<span>M</span>
									</button>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isTuesday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Tuesday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isTuesday: !recurrenceLeave.isTuesday,
											})
										}
									>
										<span>T</span>
									</button>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isWednesday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Wednesday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isWednesday: !recurrenceLeave.isWednesday,
											})
										}
									>
										<span>W</span>
									</button>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isThursday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Thursday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isThursday: !recurrenceLeave.isThursday,
											})
										}
									>
										<span>T</span>
									</button>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isFriday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Friday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isFriday: !recurrenceLeave.isFriday,
											})
										}
									>
										<span>F</span>
									</button>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isSaturday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Saturday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isSaturday: !recurrenceLeave.isSaturday,
											})
										}
									>
										<span>S</span>
									</button>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isSunday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Sunday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isSunday: !recurrenceLeave.isSunday,
											})
										}
									>
										<span>S</span>
									</button>
								</Row>
								<Row>
									<Col sm="3">
										<FormLabel>Ends</FormLabel>
									</Col>
								</Row>
								<Row>
									<Col sm="3">
										<label>
											<input
												type="radio"
												value="Never"
												name="endingRecurrence"
												checked={endingRecurrence === 'Never'}
												onClick={() => {
													updateEndingRecurrence('Never')
													updateRecurrenceLeave({
														...recurrenceLeave,
														endDate: null,
													})
												}}
											/>{' '}
											Never{' '}
										</label>
									</Col>
								</Row>
								<Row style={{ marginTop: '15px' }}>
									<Col sm="2">
										<label>
											<input
												type="radio"
												value="On"
												name="endingRecurrence"
												checked={endingRecurrence === 'On'}
												onClick={() => updateEndingRecurrence('On')}
											/>{' '}
											On{' '}
										</label>
									</Col>
									<Col sm="6" style={{ marginTop: '-6px' }}>
										<Form.Control
											type="date"
											name="endDate"
											readOnly={endingRecurrence !== 'On'}
											value={endingRecurrence === 'On' ? recurrenceLeave.endDate! : undefined}
											onChange={(e) =>
												updateRecurrenceLeave({
													...recurrenceLeave,
													endDate: e.target.value,
												})
											}
										/>
									</Col>
								</Row>
								<Row style={{ marginTop: '15px', marginBottom: '20px' }}>
									<Col sm="2">
										<label>
											<input
												type="radio"
												value="After"
												name="endingRecurrence"
												checked={endingRecurrence === 'After'}
												onClick={() => updateEndingRecurrence('After')}
											/>{' '}
											After{' '}
										</label>
									</Col>
									<Col sm="3">
										<input
											type="number"
											min="0"
											readOnly={endingRecurrence !== 'After'}
											value={occurences!}
											style={{ marginTop: '-8px' }}
											className={'form-control'}
											onChange={(e) => {
												updateOccurences(parseInt(e.target.value))
												updateRecurrenceLeave({
													...convertDayIntoRecurrence(recurrenceLeave, new Date(leaveApplication.startDate!).getDay()),
													endDate: calculateEndDate(recurrenceLeave, recurring, endingRecurrence, parseInt(e.target.value)),
												})
											}}
										/>
									</Col>
									<Col sm="3">
										<FormLabel>occurence(s)</FormLabel>
									</Col>
								</Row>
							</>
						) : null}

						{!isEdit ? (
							<Button
								variant={'success'}
								style={{ marginRight: '15px' }}
								onClick={() => {
									handleCalendarEntryCreation()
								}}
							>
								Save
							</Button>
						) : (
							<Button
								variant={'success'}
								style={{ marginRight: '15px' }}
								onClick={() => {
									updateLeaveApplication({
										...leaveApplication,
										recurrence: recurrenceLeave,
									})
									handleUpdatePress()
								}}
							>
								Update
							</Button>
						)}

						<Button
							variant={'secondary'}
							onClick={() => {
								resetModal()
								props.handleClose()
							}}
						>
							Cancel
						</Button>
					</FormGroup>
				</Modal.Body>
			</Modal>
			{showEditRecurrenceDialog && (
				<HandleEventPopUp
					leaveApplication={leaveApplication}
					leaveApplicationID={leaveApplication.leaveApplicationID!}
					popUpEventType={'Edit'}
					handleClose={() => {
						updateShowEditRecurrenceDialog(false)
						updateShowDialog(false)
						resetModal()
						props.handleClose()
					}}
					show={showEditRecurrenceDialog}
					leaveApplications={props.leaveApplications}
					updateMessages={props.updateMessages}
					appState={props.appState}
					updateLeaveApplications={props.updateLeaveApplications}
					calendar={props.currentCalendar}
					xeroOrgCalendars={props.xeroOrgCalendars}
				/>
			)}
		</>
	)
}

const checkHoursWithinPeriod = (start: string | number | Date, end: string | number | Date) => {
	return (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 3600) + 24 //+24 to include the end date hours
}

const calculateEndDate = (recurrenceLeave: Recurrence, recurring: string, endingRecurrence: EndingRecurrences, occurences: number) => {
	const days = [
		recurrenceLeave.isSunday,
		recurrenceLeave.isMonday,
		recurrenceLeave.isTuesday,
		recurrenceLeave.isWednesday,
		recurrenceLeave.isThursday,
		recurrenceLeave.isFriday,
		recurrenceLeave.isSaturday,
	] //index corresponds to day in Date object
	if (recurring === 'Yes' && endingRecurrence === 'After' && recurrenceLeave.weeklyRegularity && days.includes(true)) {
		if (recurrenceLeave.typeIsMonthly || recurrenceLeave.typeIsAnnually) {
			const lastStart = new Date(recurrenceLeave.startDate!)
			return Moment.utc(lastStart)
				.add(occurences * recurrenceLeave.weeklyRegularity, recurrenceLeave.typeIsMonthly ? 'month' : 'year')
				.format('YYYY-MM-DD')
		} else {
			const totalOFDaysAWeek = days.filter(Boolean).length
			const endingPointInDays = Math.ceil((occurences * (recurrenceLeave.weeklyRegularity * 7)) / totalOFDaysAWeek)
			const lastStart = new Date(recurrenceLeave.startDate!)
			lastStart.setDate(lastStart.getDate() + endingPointInDays)
			//console.log("Calculated end date", lastStart)
			if (lastStart.getDay() === days.lastIndexOf(true)) {
				return Moment.utc(lastStart).format('YYYY-MM-DD')
			} else if (lastStart.getDay() > days.lastIndexOf(true)) {
				lastStart.setDate(lastStart.getDate() - (lastStart.getDay() - days.lastIndexOf(true)))
				return Moment.utc(lastStart).format('YYYY-MM-DD')
			} else if (lastStart.getDay() < days.lastIndexOf(true)) {
				lastStart.setDate(lastStart.getDate() + (days.lastIndexOf(true) - lastStart.getDay()))
				return Moment.utc(lastStart).format('YYYY-MM-DD')
			}
		}
	}

	return null
}

const convertDayIntoRecurrence = (recurrenceLeave: Recurrence, day: number) => {
	switch (day) {
		case 0:
			return { ...recurrenceLeave, isSunday: true }
		case 1:
			return { ...recurrenceLeave, isMonday: true }
		case 2:
			return { ...recurrenceLeave, isTuesday: true }
		case 3:
			return { ...recurrenceLeave, isWednesday: true }
		case 4:
			return { ...recurrenceLeave, isThursday: true }
		case 5:
			return { ...recurrenceLeave, isFriday: true }
		case 6:
			return { ...recurrenceLeave, isSaturday: true }
		default:
			return { ...recurrenceLeave, isMonday: true }
	}
}

const getSchema = (endingRecurrence: EndingRecurrences) =>
	yup.object().shape({
		description: yup.string().optional(),
		startDate: yup.date().required('Start date is required.'),
		endDate: yup.date().min(yup.ref('startDate'), 'End date cannot be before start date.').required('End date is required.'),
		employeeID: yup.string().required('Employee is required.'),
		xeroOrgLeaveTypeID: yup.string().required('Leave type is required.'),
		leavePeriod: yup.array().of(
			yup.object().shape({
				numberOfUnits: yup
					.number()
					.min(0, 'Number of hours cannot be below 0.')
					.test({
						name: 'max',
						exclusive: false,
						params: {},
						message: 'Number of hours cannot be greater than\n the total hours in a period.',
						test: (value, context) => value! <= checkHoursWithinPeriod(context.parent.startDate, context.parent.endDate),
					})
					.required('Number of hours is required.'),
			})
		),
		recurrence: yup
			.object()
			.shape({
				weeklyRegularity: yup.number().min(1).required('Regularity frequency is required'),
				startDate: yup.date().required('Start date is required'),
				endDate:
					endingRecurrence === 'On'
						? yup.date().required('Ends on date is required').nullable()
						: endingRecurrence === 'After'
						? yup.date().required('Ends after occurences is required').nullable()
						: yup.date().optional().nullable(),
				isMonday: yup.boolean(),
				isTuesday: yup.boolean(),
				isWednesday: yup.boolean(),
				isThursday: yup.boolean(),
				isFriday: yup.boolean(),
				isSaturday: yup.boolean(),
				isSunday: yup.boolean(),
				daySelection: yup.boolean().when(['isMonday', 'isTuesday', 'isWednesday', 'isThursday', 'isFriday', 'isSaturday', 'isSunday'], {
					is: (
						isMonday: boolean,
						isTuesday: boolean,
						isWednesday: boolean,
						isThursday: boolean,
						isFriday: boolean,
						isSaturday: boolean,
						isSunday: boolean
					) => !isMonday && !isTuesday && !isWednesday && !isThursday && !isFriday && !isSaturday && !isSunday,
					then: yup.boolean().required('At least one repeating day required.'),
				}),
				typeIsWeekly: yup.boolean(),
				typeIsMonthly: yup.boolean(),
				typeIsAnnually: yup.boolean(),
			})
			.notRequired()
			.default(undefined),
	})
