import React from 'react'

// definitions
import * as AppDefinitions from '../../../App.d'
import * as RecordStatus from '../../../types/recordStatus'

// components
import { Col, Row, FormGroup, Button, Form, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import { Calendar as CalendarData, LeaveType } from '../../../../../back-end/utilities/apiDefinitions'

// utillites
import * as Request from '../../../utilities/request'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { HelpCircle, Trash2 } from 'react-feather'
import { MessageAction } from '../../ui/messages/message'
import { ColorPicker } from 'material-ui-color'

interface LeaveTypePopUpProps {
	show: boolean
	appState: AppDefinitions.AppState
	updateMessages: (message: MessageAction) => void
	handleClose: () => void
	titleText: string
	type: 'Add' | 'Edit'
	leaveTypes: LeaveType[] | null
	updateXeroLeaveTypes: (lv: LeaveType[]) => void
	updateCalendar: (calendar: CalendarData) => void
	xeroOrgID: string
	calendarID: string
	calendar: CalendarData
	currentLeaveType: LeaveType | null
	isCustom: boolean
}

const newLeaveType = {
	name: '',
	xeroID: '',
	xeroOrgID: '',
	isActive: true,
	isNonXero: true,
	hexColour: '#86A581',
	isEnteredByAdmin: true,
	unitType: 'Hours',
}

const allColours = { Blue: '#8aa6ff', Green: '#86A581', Lavendar: '#E4B1F7', Peach: '#F89995', Yellow: '#F8C950' }

export const LeaveTypePopUp = (props: LeaveTypePopUpProps) => {
	const [leaveType, updateleaveType] = React.useState<LeaveType>(props.currentLeaveType ? props.currentLeaveType : newLeaveType)

	const handleCustomTypeCreation = async () => {
		try {
			const req = await Request.post(`leavetype`, { ...leaveType, xeroOrgID: props.xeroOrgID }, props.appState.authState)

			if (req.data.success && req.data.leaveTypes.length > 0) {
				const xeroOrgLeaveTypeID = req.data.leaveTypes[0].xeroOrgLeaveTypeID
				await Request.post(
					`calendar/${props.calendarID}/leavetype`,
					{
						calendarID: props.calendarID,
						xeroOrgLeaveTypeID,
						recordStatusID: RecordStatus.Active,
					},
					props.appState.authState
				)
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully created custom leave type',
						timeout: 3000,
						dismissible: true,
					},
				})
				const updatedCalendar = JSON.parse(JSON.stringify(props.calendar))
				updatedCalendar.leaveTypes!.push({ xeroOrgLeaveTypeID })
				props.updateCalendar(updatedCalendar)
				props.updateXeroLeaveTypes(props.leaveTypes!.concat(req.data.leaveTypes).sort((a, b) => a.name!.localeCompare(b.name!)))
				updateleaveType(newLeaveType)
			}
		} catch (err) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to create custom leave type',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const handleLeaveTypeEdit = async () => {
		try {
			const req = await Request.put(`leavetype/${leaveType.xeroOrgLeaveTypeID}`, { ...leaveType, xeroOrgID: props.xeroOrgID }, props.appState.authState)

			if (req.data.success) {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated custom leave type',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
			const updatedTypes = props.leaveTypes!.map((lv: LeaveType) => {
				if (lv.xeroOrgLeaveTypeID === req.data.leaveTypes[0].xeroOrgLeaveTypeID) {
					return req.data.leaveTypes[0]
				} else {
					return lv
				}
			})
			props.updateXeroLeaveTypes(updatedTypes)
		} catch (err) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to update custom leave type',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const handleCustomTypeDelete = async () => {
		try {
			const req = await Request.del(`leavetype/${leaveType.xeroOrgLeaveTypeID}`, props.appState.authState)
			if (req.data.success) {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully deleted custom leave type',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
			const updatedTypes = props.leaveTypes!.filter((lv: LeaveType) => lv.xeroOrgLeaveTypeID !== leaveType.xeroOrgLeaveTypeID)
			props.updateXeroLeaveTypes(updatedTypes)
		} catch (err) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to delete custom leave type',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	return (
		<Modal
			show={props.show}
			aria-labelledby="modal"
			centered
			onHide={() => {
				updateleaveType({ ...props.currentLeaveType })
				props.handleClose()
			}}
		>
			{props.titleText ? (
				<Modal.Header>
					<Modal.Title>{props.titleText}</Modal.Title>
					{props.isCustom ? (
						<OverlayTrigger
							placement="auto"
							overlay={
								<Tooltip id={`tooltip-xero`}>
									Custom types can be created to describe all types of activities in an office. They can be used to define working location,
									fixed day&apos;s off or any other item that is not captured in Xero.
								</Tooltip>
							}
						>
							<div>
								<HelpCircle className={'settings-plus'} />
							</div>
						</OverlayTrigger>
					) : null}
				</Modal.Header>
			) : null}
			<Modal.Body>
				<FormGroup>
					<Row style={{ paddingLeft: '16px', alignItems: 'center' }}>
						<div className="settings-colourpicker">
							<ColorPicker
								onChange={(value) => updateleaveType({ ...leaveType, hexColour: '#' + value.hex })}
								value={leaveType.hexColour}
								palette={allColours}
								hideTextfield
								disableAlpha
								disableTextfield
								inputFormats={[]}
							/>
						</div>
						<Form.Control
							type="text"
							style={{ width: '75%' }}
							readOnly={!props.isCustom}
							value={leaveType.name}
							onChange={(e) => {
								const value = e.target.value
								updateleaveType({ ...leaveType, name: value })
							}}
						/>
						{props.isCustom ? (
							<Col sm="2" style={{ flex: '1', textAlign: 'right' }}>
								<OverlayTrigger placement="right" overlay={<Tooltip id={`tooltip-remove-custom-type`}>Delete Custom Type</Tooltip>}>
									<Trash2
										style={{
											cursor: 'pointer',
											width: '30px',
											height: '30px',
											textAlign: 'right',
										}}
										onClick={() => {
											props.handleClose()
											handleCustomTypeDelete()
										}}
									/>
								</OverlayTrigger>
							</Col>
						) : (
							<i style={{ opacity: '0.5', padding: '8px' }}>{`  ${leaveType.unitType}`}</i>
						)}
					</Row>
					{props.isCustom ? (
						<>
							<Row>
								<FormControlLabel
									style={{ padding: '15px 0px 0px 5px' }}
									control={
										<Checkbox
											color={'default'}
											checked={leaveType.isEnteredByAdmin}
											name={'byAdmin'}
											onClick={() =>
												updateleaveType({
													...leaveType,
													isEnteredByAdmin: !leaveType.isEnteredByAdmin,
												})
											}
										/>
									}
									label={'Entered by Admin'}
								/>
								<OverlayTrigger
									placement="auto"
									overlay={
										<Tooltip id={`tooltip-xero-entered-by-admin`}>
											If selected, only administrators can create calendar entries for this type
										</Tooltip>
									}
								>
									<div style={{ padding: '23px 0px 0px 10px' }}>
										<HelpCircle className={'settings-plus'} />
									</div>
								</OverlayTrigger>
							</Row>
							<Row>
								<FormControlLabel
									style={{ padding: '15px 0px 0px 5px' }}
									control={
										<Checkbox
											color={'default'}
											checked={leaveType.showTypeInCalendar}
											name={'alwaysShowTypeInCalendar'}
											onClick={() =>
												updateleaveType({
													...leaveType,
													showTypeInCalendar: !leaveType.showTypeInCalendar,
												})
											}
										/>
									}
									label={'Always show Custom Type in calendar'}
								/>
								<OverlayTrigger
									placement="auto"
									overlay={
										<Tooltip id={`tooltip-xero-entered-by-admin`}>
											If selected, this Custom Type will always be displayed in calendar feeds and alerts even if &apos;Leave Type&apos;
											is not included
										</Tooltip>
									}
								>
									<div style={{ padding: '23px 0px 0px 10px' }}>
										<HelpCircle className={'settings-plus'} />
									</div>
								</OverlayTrigger>
							</Row>

							{props.type === 'Edit' ? (
								<Row>
									<FormControlLabel
										style={{ padding: '15px 0px 5px 5px' }}
										control={
											<Checkbox
												color={'default'}
												name={'active'}
												checked={leaveType.isActive}
												onClick={() =>
													updateleaveType({
														...leaveType,
														isActive: !leaveType.isActive,
													})
												}
											/>
										}
										label={'Active'}
									/>
								</Row>
							) : null}
						</>
					) : (
						<div style={{ paddingTop: '15px' }} />
					)}

					<Button
						variant={'success'}
						style={{ marginRight: '15px' }}
						onClick={() => {
							props.handleClose()
							if (props.type === 'Add') {
								handleCustomTypeCreation()
							} else {
								handleLeaveTypeEdit()
							}
						}}
					>
						Save
					</Button>
					<Button
						variant={'secondary'}
						onClick={() => {
							updateleaveType({ ...props.currentLeaveType })
							props.handleClose()
						}}
					>
						Cancel
					</Button>
				</FormGroup>
			</Modal.Body>
		</Modal>
	)
}
