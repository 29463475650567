import React from 'react'
import { Row, Col, DropdownButton, Dropdown, ButtonGroup, Form } from 'react-bootstrap'

// utilities
import moment from 'moment'

// components
import { DateRangePicker } from 'react-dates'

// definitions
import { DateRange } from './pointInTime'

type TimePeriodPickerProps = {
	dates: DateRange
	id: string
	noPeriod?: boolean
	handleDatesChange: (dateRange: DateRange) => void
}

type StandardPeriod = {
	name: string
	dates: DateRange
}

type FocusedInput = 'startDate' | 'endDate' | null

const TimePeriodPicker = (props: TimePeriodPickerProps) => {
	const [focusedInput, setFocusedInput] = React.useState<FocusedInput>(null)
	const [selectedDateRange, setSelectedDateRange] = React.useState<string>('Select a time period')

	// Disable start date from being after today
	const isStartDateOutsideRange = (day: moment.Moment) => {
		return day.isAfter(moment(), 'day') // Disable dates after today for the start date
	}

	// Disable end date from being before start date
	const isEndDateBlocked = (day: moment.Moment) => {
		const { startDate } = props.dates
		if (!startDate) {
			return false
		} // If no start date is selected, don't block the end date.
		return day.isBefore(moment(startDate), 'day') // Disable any end date before the start date
	}

	return (
		<>
			<Col xs={12} sm={5} md={6} lg={'auto'}>
				<DateRangePicker
					startDate={props.dates.startDate}
					startDateId={`${props.id}-start`}
					endDate={props.dates.endDate}
					endDateId={`${props.id}-end`}
					onDatesChange={props.handleDatesChange}
					focusedInput={focusedInput}
					onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
					renderMonthElement={customMonthAndYear}
					displayFormat={'DD/MM/Y'}
					isOutsideRange={focusedInput === 'startDate' ? isStartDateOutsideRange : () => false} // Disable future dates for start date only
					isDayBlocked={focusedInput === 'endDate' ? isEndDateBlocked : () => false} // Disable end date before start date
					small
					showClearDates={props.noPeriod ? false : true}
				/>
			</Col>
			{props.noPeriod ? null : (
				<Col xs={12} sm={4} md={4} lg={'auto'}>
					<DropdownButton as={ButtonGroup} id={'dropdown'} variant={'primary'} title={selectedDateRange} style={{ width: '100%' }}>
						{Periods.map((periods, index) => (
							<React.Fragment key={index}>
								{periods.group.map((p, i) => (
									<Dropdown.Item
										key={i}
										eventKey={p.name}
										active={p.name === selectedDateRange}
										onSelect={() => {
											props.handleDatesChange({
												startDate: p.dates.startDate,
												endDate: p.dates.endDate,
											})
											setSelectedDateRange(p.name)
										}}
									>
										<Row>
											<Col sm={'auto'} style={{ textAlign: 'left' }}>
												{p.name}
											</Col>
											<Col sm={'auto'} style={{ textAlign: 'right', flex: '1 1 auto' }}>
												{moment(p.dates.startDate).format('D MMM Y')} - {moment(p.dates.endDate).format('D MMM Y')}
											</Col>
										</Row>
									</Dropdown.Item>
								))}
								{index !== Periods.length - 1 && <Dropdown.Divider />}
							</React.Fragment>
						))}
					</DropdownButton>
				</Col>
			)}
		</>
	)
}

type RenderMonthProps = {
	month: moment.Moment
	onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void
	onYearSelect: (currentMonth: moment.Moment, newMonthVal: string) => void
	isVisible: boolean
}

const customMonthAndYear = (props: RenderMonthProps) => (
	<div className={'custom-month-year'}>
		<span>
			<Form.Control
				as="select"
				size="sm"
				custom
				value={props.month.year()}
				onChange={(e) => {
					props.onYearSelect(props.month, e.target.value)
				}}
			>
				{availableYears.map((y) => (
					<option key={y} value={y}>
						{y}
					</option>
				))}
			</Form.Control>
		</span>
		<span style={{ paddingLeft: '.5em' }}>
			<Form.Control
				as="select"
				size="sm"
				custom
				value={props.month.month()}
				onChange={(e) => {
					props.onMonthSelect(props.month, e.target.value)
				}}
			>
				{moment.months().map((label, value) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</Form.Control>
		</span>
	</div>
)

const availableYears = [moment().year(), moment().year() - 1, moment().year() - 2, moment().year() - 3]

const Periods: { group: StandardPeriod[] }[] = [
	{
		group: [
			{
				name: 'This Month',
				dates: {
					startDate: moment().startOf('month'),
					endDate: moment().endOf('month'),
				},
			},
			{
				name: 'This Quarter',
				dates: {
					startDate: moment().startOf('quarter'),
					endDate: moment().endOf('quarter'),
				},
			},
			{
				name: 'This Financial Year',
				dates: {
					startDate: moment(`${moment().month() >= 6 ? moment().year() : moment().year() - 1}-07-01`), // remember month is 0 indexed
					endDate: moment(),
				},
			},
		],
	},
	{
		group: [
			{
				name: 'Last Month',
				dates: {
					startDate: moment().subtract(1, 'month').startOf('month'),
					endDate: moment().subtract(1, 'month').endOf('month'),
				},
			},
			{
				name: 'Last Quarter',
				dates: {
					startDate: moment().startOf('quarter').subtract(3, 'month'),
					endDate: moment().startOf('quarter').subtract(1, 'second'),
				},
			},
			{
				name: 'Last Financial Year',
				dates: {
					startDate: moment(`${moment().month() >= 6 ? moment().year() - 1 : moment().year() - 2}-07-01`),
					endDate: moment(`${moment().month() >= 6 ? moment().year() : moment().year() - 1}-06-30`),
				},
			},
		],
	},
	{
		group: [
			{
				name: 'Month to Date',
				dates: {
					startDate: moment().startOf('month'),
					endDate: moment(),
				},
			},
			{
				name: 'Quarter to Date',
				dates: {
					startDate: moment().startOf('quarter'),
					endDate: moment(),
				},
			},
			{
				name: 'Financial Year to Date',
				dates: {
					startDate: moment(`${moment().month() >= 6 ? moment().year() : moment().year() - 1}-07-01`),
					endDate: moment(),
				},
			},
		],
	},
]

export { TimePeriodPicker }
