import React from 'react'

// definitions
import * as AppDefinitions from '../../../App.d'

// components
import { Button, Form } from 'react-bootstrap'
import { AccountXeroOrg } from '../../../../../back-end/utilities/apiDefinitions'
import * as Messages from '../../ui/messages/messages'

// utillites
import * as Request from '../../../utilities/request'
import * as I from '../../../utilities/me'
import { MessageAction } from '../../ui/messages/message'

interface OrgHoursInDaysProps {
	appState: AppDefinitions.AppState
	updateMessages: (message: MessageAction) => void
	currentOrg: AccountXeroOrg
	updateCurrentOrg: (xero: AccountXeroOrg) => void
}

export const OrgHoursInDay = (props: OrgHoursInDaysProps) => {
	const [isHoursInDayDisabled, updateHoursInDayDisabled] = React.useState<boolean>(true)
	const [hoursInDay, updateHoursInDay] = React.useState<number>(props.currentOrg.xeroOrg!.hoursInDay! || 0.0)

	const postHoursInDayChange = async () => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			try {
				if (hoursInDay > 0) {
					await Request.put(`xeroorg`, { ...props.currentOrg.xeroOrg!, hoursInDay: hoursInDay }, props.appState.authState)
					props.updateCurrentOrg({
						...props.currentOrg,
						xeroOrg: { ...props.currentOrg.xeroOrg!, hoursInDay: hoursInDay },
					})
					props.updateMessages(Messages.addMessage('success', 'Succesfully updated standard number of hours in a day'))
				} else {
					updateHoursInDayDisabled(false)
					props.updateMessages(Messages.addMessage('danger', 'Standard number of hours in a day should be more than 0'))
					return
				}
			} catch (err) {
				console.log('Update Error: ', err)
				props.updateMessages(Messages.addMessage('danger', 'Failed to update standard number of hours in a day'))
			}
		} else {
			props.updateMessages(Messages.addMessage('danger', 'You do no have permission to update the standard number of hours in a day'))
		}
	}
	const resetHoursinDay = async () => {
		updateHoursInDay(props.currentOrg.xeroOrg!.hoursInDay!)
	}

	return (
		<>
			<Form.Control
				type="number"
				step="0.1"
				min="0"
				max="24"
				id={props.currentOrg.xeroOrgID!}
				name="hours in day"
				placeholder=""
				readOnly={isHoursInDayDisabled}
				plaintext={isHoursInDayDisabled}
				onClick={() => updateHoursInDayDisabled(false)}
				onChange={(event) => {
					const value = event.target.value
					updateHoursInDay(Number(value))
				}}
				value={hoursInDay}
				style={{
					display: 'inline',
					width: '50%',
					paddingLeft: '12px',
					fontSize: '16px',
				}}
				className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : ''}
			/>
			{!isHoursInDayDisabled ? (
				<Button
					type="button"
					style={{ margin: '0px 10px 10px 10px', fontSize: '16px' }}
					variant="success"
					onClick={() => {
						updateHoursInDayDisabled(true)
						postHoursInDayChange()
					}}
				>
					Save
				</Button>
			) : null}
			{!isHoursInDayDisabled ? (
				<Button
					type="button"
					style={{ margin: '0px 5px 10px 5px', fontSize: '16px' }}
					onClick={() => {
						updateHoursInDayDisabled(true)
						resetHoursinDay()
					}}
				>
					Cancel
				</Button>
			) : null}
		</>
	)
}
