import React from 'react'
import { Row, FormLabel, Col, Button, FormGroup, Alert } from 'react-bootstrap'
import { AccountXeroOrg, Calendar, LeaveRule, LeaveType } from '../../../../../back-end/utilities/apiDefinitions'

// definitions
import * as AppDefinitions from '../../../App.d'
import { LeaveBalance as LeaveBalanceProduct } from '../../../constants/products'

// components
import LeaveRuleCard from './leaveRuleCard'
import * as Messages from '../../ui/messages/messages'
import LoadingImage from '../../../images/Finlert-loading-GIF_100px.gif'

// utillites
import * as Request from '../../../utilities/request'
import { AlertCircle } from 'react-feather'
import { checkScopes } from '../../../utilities/scope'
import * as XeroProducts from '../../../constants/products'

interface LeaveRuleFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: Calendar
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

const emptyRule = {
	name: '',
	action: 'approve' as const,
	leaveType: null,
	leaveBalance: null,
	duration: null,
	dateRange: null,
	relativeDate: null,
	createUserID: '',
	modifiedUserID: '',
	createTs: '',
	modifiedTs: '',
	recordStatusID: '',
}

const LeaveRules = (props: LeaveRuleFrameProps) => {
	const [leaveRules, updateLeaveRules] = React.useState<LeaveRule[] | null>(null)
	const [leaveTypes, updateLeaveTypes] = React.useState<LeaveType[] | null>(null)
	const [xeroConsentUrl, updateXeroConsentUrl] = React.useState<string | null>(null)
	const [messages, updateMessages] = Messages.useMessageReducer([])

	React.useEffect(() => {
		const fetchData = async () => {
			const [leaveRulesRes, leaveTypesRes, xeroConsentUrlRes] = await Promise.all([
				Request.get(`leaverule?where=CalendarID==${props.currentCalendar.calendarID}`, props.appState.authState),
				Request.get(`leavetype?where=XeroOrgID==${props.currentOrg.xeroOrg!.xeroOrgID}&where=XeroOrgLeaveTypeIsNonXero==0`, props.appState.authState),
				Request.get('xero/url/leavecal', props.appState.authState),
			])

			updateLeaveRules(leaveRulesRes.data.leaveRules)
			updateLeaveTypes(leaveTypesRes.data.leaveTypes)
			updateXeroConsentUrl(xeroConsentUrlRes.data.url)
			if (props.show) {
				props.updatePageStatus('Finished')
			}
		}

		if (props.currentCalendar.calendarID !== '') {
			fetchData()
		}
	}, [props.show, props.appState.authState, props.currentCalendar.calendarID, props.currentOrg, props.updatePageStatus]) // eslint-disable-line

	const updateRule = React.useCallback(async (rule: LeaveRule) => {
		const updateRuleRes = await Request.put(`leaverule/${rule.leaveRuleID}`, rule, props.appState.authState)
		const updateRule = updateRuleRes.data.leaveRules[0]
		if (updateRule) {
			updateLeaveRules((leaveRules) => leaveRules?.map((lr) => (lr.leaveRuleID === rule.leaveRuleID ? updateRule : lr)) || [])
			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Successfully updated leave rule',
					timeout: 3000,
					dismissible: true,
				},
			})
		} else {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to update leave rule',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}, [])

	const createRule = React.useCallback(async (rule: LeaveRule) => {
		const newRuleRes = await Request.post('leaverule', { ...rule, leaveRuleID: undefined }, props.appState.authState)
		const newRule = newRuleRes.data.leaveRules[0]
		if (newRule) {
			updateLeaveRules((leaveRules ?? []).concat(newRule))
			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Successfully created new leave rule',
					timeout: 3000,
					dismissible: true,
				},
			})
		} else {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to create new leave rule',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}, [])

	const cancelNewRule = React.useCallback(async (rule: LeaveRule) => {
		updateLeaveRules((leaveRules) => leaveRules?.filter((lr) => lr.leaveRuleID !== rule.leaveRuleID) || [])
	}, [])

	const removeRule = React.useCallback(
		async (rule: LeaveRule) => {
			if (rule.leaveRuleID!.includes('new')) {
				updateLeaveRules((leaveRules) => leaveRules!.filter((lr) => lr.leaveRuleID !== rule.leaveRuleID))
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully removed leave rule',
						timeout: 3000,
						dismissible: true,
					},
				})
			} else {
				const req = await Request.del(`leaverule/${rule.leaveRuleID}`, props.appState.authState)
				if (req.data.success) {
					updateLeaveRules((leaveRules) => leaveRules!.filter((lr) => lr.leaveRuleID !== rule.leaveRuleID))
					updateMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: 'Successfully removed leave rule',
							timeout: 3000,
							dismissible: true,
						},
					})
				} else {
					updateMessages({
						type: 'add',
						data: {
							severity: 'danger',
							message: 'Unable to remove leave rule',
							timeout: 3000,
							dismissible: true,
						},
					})
				}
			}
		},
		[updateLeaveRules, updateMessages, props.appState.authState]
	)
	const hasLeaveApprovalProduct = React.useMemo(() => {
		return props.currentOrg.xeroOrg?.products?.some((product) => product.productID === XeroProducts.LeaveApproval.productID) || false
	}, [props.currentOrg])

	if (!props.show) {
		return null
	}
	if (props.pageStatus !== 'Finished' || leaveRules === null || leaveTypes === null) {
		return (
			<div className="loading-gif">
				<img src={LoadingImage} alt="Loading ..." />
			</div>
		)
	}
	if (!hasLeaveApprovalProduct) {
		return <Alert variant="info">You need the Leave Approval product to access this page</Alert>
	}

	return (
		<>
			{props.currentOrg.userAccountXeroOrgAuth && !checkScopes(props.currentOrg.userAccountXeroOrgAuth.scopes) ? (
				<div className="fade alert alert-danger show" style={{ display: 'flex', marginLeft: '20px', marginTop: '20px', alignItems: 'center' }}>
					<AlertCircle style={{ marginRight: '10px' }} />
					<p style={{ marginTop: '15px' }}>
						You will need to reauthenticate with Xero to access this feature.{' '}
						<a href={xeroConsentUrl || ''} style={{ color: 'inherit', fontStyle: 'italic' }}>
							Re-auth here.
						</a>
					</p>
				</div>
			) : null}
			<FormGroup style={{ padding: '30px' }}>
				<Row className="email-alerts-card">
					<Row style={{ justifyContent: 'space-between' }}>
						<FormLabel className="emails-label">Team Leave Application Rules</FormLabel>
					</Row>
					<Row style={{ paddingLeft: '20px' }}>
						<FormLabel style={{ marginTop: '-5px', paddingLeft: '20px' }}>
							Set up leave application rules to save you time approving and rejecting leave
						</FormLabel>
					</Row>
					{leaveRules
						? leaveRules.map((rule: LeaveRule) => (
								<LeaveRuleCard
									key={rule.leaveRuleID}
									rule={rule}
									appState={props.appState}
									removeRule={removeRule}
									hasLeaveBalanceProduct={
										(props.currentOrg.xeroOrg &&
											props.currentOrg.xeroOrg.products &&
											props.currentOrg.xeroOrg.products.findIndex((product) => product.productID === LeaveBalanceProduct.productID) >=
												0) ||
										false
									}
									leaveTypes={leaveTypes}
									handleUpdate={updateRule}
									handleSubmit={createRule}
									handleCancel={cancelNewRule}
									updateMessages={updateMessages}
								/>
						  ))
						: null}
					<Row style={{ justifyContent: 'flex-end', paddingTop: '10px' }}>
						<Col sm="auto">
							<Button
								type="submit"
								onClick={() => {
									leaveRules
										? updateLeaveRules(
												leaveRules.concat({
													leaveRuleID: `new${leaveRules.length}`,
													calendarID: props.currentCalendar.calendarID!,
													...emptyRule,
												})
										  )
										: updateLeaveRules([{ leaveRuleID: 'new0', calendarID: props.currentCalendar.calendarID!, ...emptyRule }])
								}}
							>
								{leaveRules && leaveRules.length !== 0 ? 'Add another rule' : 'Add a rule'}
							</Button>
						</Col>
					</Row>
				</Row>
			</FormGroup>
			<Messages.Messages messages={messages} updateMessage={updateMessages} />
		</>
	)
}

export default LeaveRules
