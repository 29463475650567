import React from 'react'

// definitions
import * as AppDefinitions from '../../../App.d'

// components
import { FormGroup, Button, Form, OverlayTrigger, Tooltip, Modal, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import { Calendar as CalendarData } from '../../../../../back-end/utilities/apiDefinitions'

// utillites
import * as Request from '../../../utilities/request'
import { HelpCircle } from 'react-feather'
import { MessageAction } from '../../ui/messages/message'

interface CustomFeedPopUpProps {
	show: boolean
	appState: AppDefinitions.AppState
	updateMessages: (message: MessageAction) => void
	currentCalendar: CalendarData
	updateCalendar: (cal: CalendarData) => void
	handleClose: () => void
	titleText: string
}

interface IcsFeed {
	name: string
	url: string
}

export const presetIcsFeeds: IcsFeed[] = [
	{ name: 'Australia', url: 'https://www.officeholidays.com/ics/australia' },
	{ name: '\u00A0\u00A0NSW', url: 'https://www.officeholidays.com/ics/australia/new-south-wales' },
	{ name: '\u00A0\u00A0VIC', url: 'https://www.officeholidays.com/ics/australia/victoria' },
	{ name: '\u00A0\u00A0QLD', url: 'https://www.officeholidays.com/ics/australia/queensland' },
	{ name: '\u00A0\u00A0ACT', url: 'https://www.officeholidays.com/ics/australia/act' },
	{ name: '\u00A0\u00A0WA', url: 'https://www.officeholidays.com/ics/australia/western-australia' },
	{ name: '\u00A0\u00A0SA', url: 'https://www.officeholidays.com/ics/australia/south-australia' },
	{ name: '\u00A0\u00A0TAS', url: 'https://www.officeholidays.com/ics/australia/tasmania' },
	{ name: '\u00A0\u00A0NT', url: 'https://www.officeholidays.com/ics/australia/northern-territory' },
	{ name: 'United Kingdom', url: 'https://www.officeholidays.com/ics/united-kingdom' },
	{ name: 'New Zealand', url: 'https://www.officeholidays.com/ics/new-zealand' },
	{ name: 'Philippines', url: 'https://www.officeholidays.com/ics/philippines' },
]

export const CustomFeedPopUp = (props: CustomFeedPopUpProps) => {
	const [inputFeed, updateInputFeed] = React.useState<string>('')

	const handleCustomFeedCreation = async () => {
		try {
			const feed = await Request.post(`inputfeed/check`, { url: inputFeed }, props.appState.authState)
			if (feed.data && feed.data.isValid) {
				const req = await Request.post(`calendar/${props.currentCalendar.calendarID}/inputfeed`, { url: inputFeed }, props.appState.authState)

				if (req.data.success) {
					props.updateMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: 'Successfully added external calendar',
							timeout: 3000,
							dismissible: true,
						},
					})
				}
				props.updateCalendar({
					...props.currentCalendar,
					inputFeeds: req.data.calendarInputFeeds,
				})
			} else {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'That does not look like an ics feed',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		} catch (err) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to add external calendar',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	return (
		<Modal show={props.show} aria-labelledby="modal" centered onHide={props.handleClose}>
			{props.titleText ? (
				<Modal.Header>
					<Modal.Title>{props.titleText}</Modal.Title>
					<OverlayTrigger
						placement="auto"
						overlay={
							<Tooltip id={`tooltip-xero`}>
								An external calendar (.ics) feed can combine an external calendar with our graphical and ics feeds.
							</Tooltip>
						}
					>
						<div>
							<HelpCircle className={'settings-plus'} />
						</div>
					</OverlayTrigger>
				</Modal.Header>
			) : null}
			<Modal.Body>
				<FormGroup>
					<DropdownButton
						as={ButtonGroup}
						id={'dropdown'}
						variant={'primary'}
						title={inputFeed ? presetIcsFeeds.find((e) => e.url === inputFeed)?.name ?? 'Custom URL' : 'Select one'}
						onSelect={(e) => {
							if (e) {
								updateInputFeed(e)
							}
						}}
						alignRight
						style={{
							paddingLeft: '0px',
							marginBottom: '15px',
							fontSize: '14px',
						}}
					>
						<Dropdown.Header>Public Holidays</Dropdown.Header>
						{presetIcsFeeds.map((e) => (
							<Dropdown.Item key={e.name} eventKey={e.url} active={inputFeed === e.url}>
								{e.name}
							</Dropdown.Item>
						))}
					</DropdownButton>
					<Form.Control
						type="text"
						value={inputFeed}
						style={{ marginBottom: '15px' }}
						placeholder="https://example.com/calendar-name.ics"
						onChange={(e) => {
							const value = e.target.value
							updateInputFeed(value)
						}}
					/>
					<Button
						variant={'success'}
						style={{ marginRight: '15px' }}
						onClick={() => {
							props.handleClose()
							handleCustomFeedCreation()
						}}
					>
						Save
					</Button>
					<Button variant={'secondary'} onClick={props.handleClose}>
						Cancel
					</Button>
				</FormGroup>
			</Modal.Body>
		</Modal>
	)
}
