import React from 'react'
import { Row, Button, FormGroup, Modal } from 'react-bootstrap'

// definitions
import { Calendar as CalendarData, LeaveApplication } from '../../../../../back-end/utilities/apiDefinitions'
import * as AppDefinitions from '../../../App.d'

// components
import { CalendarLeave } from '../../ui/bigCalendar/bigCalendar'
import LoadingImage from '../../../images/Finlert-loading-GIF_100px.gif'

// utillites
import * as Request from '../../../utilities/request'
import { MessageAction } from '../../ui/messages/message'
import * as I from '../../../utilities/me'

interface HandleEventPopUpProps {
	popUpEventType: 'Edit' | 'Delete'
	handleClose: () => void
	show: boolean
	updateMessages: (e: MessageAction) => void
	leaveApplications: CalendarLeave[] | []
	appState: AppDefinitions.AppState
	updateLeaveApplications: React.Dispatch<React.SetStateAction<LeaveApplication[]>>
	leaveApplicationID: string | null
	leaveApplication?: LeaveApplication & { updatedStartOrEnd: boolean }
	calendar: CalendarData
	xeroOrgCalendars: CalendarData[]
}

export const HandleEventPopUp = (props: HandleEventPopUpProps) => {
	const [choice, updateChoice] = React.useState<string>('')
	const [isSubmitting, updateIsSubmitting] = React.useState<boolean>(false)
	const [isRecurring, updateIsRecurring] = React.useState<boolean | null>(null)

	React.useEffect(() => {
		const fetchData = async () => {
			const [leaveApplicationReq] = await Promise.all([
				Request.get(`leave?where=LeaveApplicationID=in(${props.leaveApplicationID})`, props.appState.authState),
			])
			if (leaveApplicationReq.data.leaveApplications.length > 0) {
				const recurrence = await leaveApplicationReq.data.leaveApplications[0].recurrence!
				const recurring = recurrence.recurrenceID !== null
				updateIsRecurring(recurring)

				if (!recurring) {
					handlePopUpEvent()
				}
			}
		}

		if (props.appState.authState && props.leaveApplicationID) {
			fetchData()
		}
	}, [props.appState.authState, props.leaveApplicationID])

	const handlePopUpEvent: () => Promise<void> = async () => {
		const createUserID = props.leaveApplications.find((l) => l.leaveApplicationID === props.leaveApplicationID)!.createUserID
		if (
			I.have('admin', props.appState.permission[props.appState.context]) ||
			(createUserID && props.appState.authState.attributes!['custom:userId'] === createUserID!)
		) {
			try {
				if (props.popUpEventType === 'Edit') {
					const employeeID = props.leaveApplication?.employeeID
					const affectedCals = props.xeroOrgCalendars!.filter((c) => c.employees!.find((emp) => emp.employeeID === employeeID))
					await Promise.all([
						Request.put(
							`leave/${props.leaveApplicationID}`,
							{ ...props.leaveApplication, choice: choice, updatedStartOrEnd: true },
							props.appState.authState
						),
						...affectedCals.map(async (c) =>
							Request.post(`organisation/${c.accountXeroOrg!.xeroOrgID}/updatecalendar`, null, props.appState.authState)
						),
					])
				} else if (props.popUpEventType === 'Delete') {
					const employeeID = props.leaveApplications.find((l) => l.leaveApplicationID === props.leaveApplicationID)!.employeeID
					const affectedCals = props.xeroOrgCalendars!.filter((c) => c.employees!.find((emp) => emp.employeeID === employeeID))
					await Promise.all([
						choice === 'this' || !isRecurring
							? Request.del(`leave/${props.leaveApplicationID}`, props.appState.authState)
							: Request.del(`leave/${props.leaveApplicationID}/${choice}`, props.appState.authState),
						...affectedCals.map(async (c) =>
							Request.post(`organisation/${c.accountXeroOrg!.xeroOrgID}/updatecalendar`, null, props.appState.authState)
						),
					])
				}

				props.updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: `Successful ${props.popUpEventType.toLowerCase()} of ${isRecurring ? 'recurring ' : ''}event`,
						timeout: 3000,
						dismissible: true,
					},
				})

				if (props.popUpEventType === 'Edit') {
					props.updateLeaveApplications(
						props.leaveApplications.filter((leave: LeaveApplication) => leave.leaveApplicationID === props.leaveApplicationID)
					)
				} else {
					props.updateLeaveApplications(
						props.leaveApplications.filter((leave: LeaveApplication) => leave.leaveApplicationID !== props.leaveApplicationID)
					)
				}
				props.handleClose()
				updateIsSubmitting(false)
			} catch (err) {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `Unable to ${props.popUpEventType.toLowerCase()} ${isRecurring ? 'recurring ' : ''} event`,
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		} else {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `You don't have permissions to ${props.popUpEventType === 'Edit' ? 'edit' : 'delete'} this Custom calendar entry`,
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	if (isRecurring) {
		return (
			<Modal show={props.show} aria-labelledby="modal" centered onHide={props.handleClose}>
				<Modal.Header>
					<Modal.Title>{`${props.popUpEventType} recurring event`}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormGroup>
						<Row style={{ margin: '15px 0px 0px 0px' }}>
							<label>
								<input
									type="radio"
									style={{ marginRight: '5px', marginTop: '3px' }}
									value="this"
									checked={choice === 'this'}
									readOnly
									onClick={() => updateChoice('this')}
								/>{' '}
								This event{' '}
							</label>
						</Row>
						<Row style={{ margin: '15px 0px 0px 0px' }}>
							<label>
								<input
									type="radio"
									style={{ marginRight: '5px', marginTop: '3px' }}
									value="following"
									checked={choice === 'following'}
									readOnly
									onClick={() => updateChoice('following')}
								/>{' '}
								This and following events{' '}
							</label>
						</Row>
						<Row style={{ margin: '15px 0px 20px 0px' }}>
							<label>
								<input
									type="radio"
									style={{ marginRight: '5px', marginTop: '3px' }}
									value="all"
									checked={choice === 'all'}
									readOnly
									onClick={() => updateChoice('all')}
								/>{' '}
								All events{' '}
							</label>
						</Row>
						{!isSubmitting ? (
							<>
								<Button
									variant={'success'}
									disabled={isSubmitting}
									style={{ marginRight: '15px' }}
									onClick={() => {
										handlePopUpEvent()
										updateIsSubmitting(true)
									}}
								>
									Ok
								</Button>
								<Button variant={'secondary'} onClick={props.handleClose}>
									Cancel
								</Button>
							</>
						) : (
							<img src={LoadingImage} alt="Loading ..." style={{ width: '10%' }} />
						)}
					</FormGroup>
				</Modal.Body>
			</Modal>
		)
	} else {
		return <></>
	}
}
