const checkScopes = (scopes: string) => {
	const neededScope = 'payroll.employees'
	const pattern = new RegExp(`\\b${neededScope}\\b(?!\\.)`)
	if (scopes) {
		return pattern.test(scopes)
	}
	return false
}

export { checkScopes }
