import React from 'react'
import { ButtonGroup, Col, Dropdown, DropdownButton, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import { ArrowLeftCircle, ArrowRightCircle, Calendar } from 'react-feather'

import * as AppDefinitions from '../../App.d'
import * as Product from '../../constants/products'
import { AccountXeroOrg, Calendar as CalendarData, Employee, Google, LeaveType } from '../../../../back-end/utilities/apiDefinitions'
import { ModalListChildProps } from '../ui/modalList/modalList'
import * as RecordStatus from '../../types/recordStatus'

import * as Request from '../../utilities/request'
import { presetIcsFeeds } from './settings/customFeedPopup'

interface OnBoardProps extends ModalListChildProps {
	accountXeroOrgID: string
	organisationID: string
	organisationName: string
	organisationVersion: string
	appState: AppDefinitions.AppState
	currentOrg?: AccountXeroOrg
	firstCalendar: boolean
}

interface ModalProps {
	appState: AppDefinitions.AppState
	organisationID: string
	organisationName: string
	organisationVersion: string
	currentOrg?: AccountXeroOrg
	firstCalendar: boolean
	groups: string[] | null
	selectedGroup: number
	updateSelectedGroup: React.Dispatch<React.SetStateAction<number>>
	employees: Employee[] | null
	selectedEmployees: string[]
	updateSelectedEmployees: React.Dispatch<React.SetStateAction<string[]>>
	leaveTypes: LeaveType[] | null
	selectedLeaveTypes: number[]
	updateSelectedLeaveTypes: React.Dispatch<React.SetStateAction<number[]>>
	selectedDetails: string[]
	updateSelectedDetails: React.Dispatch<React.SetStateAction<string[]>>
	selectedCelebrate: string[]
	updateSelectedCelebrate: React.Dispatch<React.SetStateAction<string[]>>
	selectedPublicHolidayFeed: string
	updateSelectedPublicHolidayFeed: React.Dispatch<React.SetStateAction<string>>
	calendarName: string
	updateCalendarName: React.Dispatch<React.SetStateAction<string>>
	customGroupName: string
	updateCustomGroupName: React.Dispatch<React.SetStateAction<string>>
	selectedGroupEmployees: Employee[]
	updateSelectedGroupEmployees: React.Dispatch<React.SetStateAction<Employee[]>>
	googleUrl: string
	updateGoogleUrl: React.Dispatch<React.SetStateAction<string>>
	googleConnection: Google | null
	updateGoogleConnection: React.Dispatch<React.SetStateAction<Google | null>>
	isCheckingGoogleConnection: boolean
	updateIsCheckingGoogleConnection: React.Dispatch<React.SetStateAction<boolean>>
	canGoBack: boolean
	updateCanGoBack: React.Dispatch<React.SetStateAction<boolean>>
	canGoFoward: boolean
	updateCanGoFoward: React.Dispatch<React.SetStateAction<boolean>>
	title: string
	updateTitle: React.Dispatch<React.SetStateAction<string>>
	hasCustomGroupsProduct: boolean
	step: number
	updateStep: React.Dispatch<React.SetStateAction<number>>
	calendar: CalendarData | null
	// eslint-disable-next-line
	finishOnBoarding: (...args: any[]) => void
}

enum ShowDetails {
	title = 'showTitle',
	amount = 'showAmount',
	group = 'showGroup',
	leaveType = 'showLeaveType',
	birthday = 'showBirthday',
	anniversary = 'showAnniversary',
	anniversary1 = 'newEmployee1Month',
	anniversary3 = 'newEmployee3Month',
	anniversary6 = 'newEmployee6Month',
	anniversary18 = 'newEmployee18Month',
}

const checkEmploymentBasisIsSelected = (selectedGroup: string, employmentBasis: string) => {
	switch (employmentBasis) {
		case 'PARTTIME':
			return selectedGroup.includes('Part Time')
		case 'FULLTIME':
			return selectedGroup.includes('Full Time')
		case 'CASUAL':
			return selectedGroup.includes('Casual')
		default:
			return false
	}
}

const getCalendarEmploymentBasis = (selectedGroup: string) => {
	return selectedGroup.includes('Casual')
		? 'CASUAL'
		: selectedGroup.includes('Part Time') && selectedGroup.includes('Full Time')
		? 'FULLTIMEPARTTIME'
		: selectedGroup.includes('Part Time')
		? 'PARTTIME'
		: selectedGroup.includes('Full Time')
		? 'FULLTIME'
		: null
}

const OnboardModal = (props: OnBoardProps) => {
	const [modalStep, updateModalStep] = React.useState<number>(0)
	const [title, updateTitle] = React.useState<string>(props.organisationName)
	const [employees, updateEmployees] = React.useState<Employee[] | null>(null)
	const [groups, updateGroups] = React.useState<string[] | null>(null)
	const [selectedGroup, updateSelectedGroup] = React.useState<number>(-1)
	const [leaveTypes, updateLeaveTypes] = React.useState<LeaveType[] | null>(null)
	const [selectedLeaveTypes, updateSelectedLeaveTypes] = React.useState<number[]>([])
	const [selectedDetails, updateSelectedDetails] = React.useState<string[]>([])
	const [selectedCelebrate, updateSelectedCelebrate] = React.useState<string[]>([])
	const [canGoFoward, updateCanGoFoward] = React.useState<boolean>(true)
	const [canGoBack, updateCanGoBack] = React.useState<boolean>(true)
	const [calendar, updateCalendar] = React.useState<CalendarData | null>(null)

	const [googleUrl, updateGoogleUrl] = React.useState<string>('')
	const [googleConnection, updateGoogleConnection] = React.useState<Google | null>(null)
	const [isCheckingGoogleConnection, updateIsCheckingGoogleConnection] = React.useState<boolean>(false)

	const [calendarName, updateCalendarName] = React.useState<string>('')
	const [customGroupName, updateCustomGroupName] = React.useState<string>('')
	const [selectedGroupEmployees, updateSelectedGroupEmployees] = React.useState<Employee[]>([])
	const [selectedEmployees, updateSelectedEmployees] = React.useState<string[]>([])
	const [selectedPublicHolidayFeed, updateSelectedPublicHolidayFeed] = React.useState<string>('')
	const [hasCustomGroupsProduct, updateHasCustomGroupsProduct] = React.useState<boolean>(
		(props.appState.selectedOrg &&
			props.appState.selectedOrg.xeroOrg &&
			props.appState.selectedOrg.xeroOrg.products &&
			props.appState.selectedOrg.xeroOrg.products.findIndex((product) => product.productID === Product.CustomGroups.productID) >= 0) ||
			false
	) // eslint-disable-line

	const modalProps: ModalProps = {
		appState: props.appState,
		organisationID: props.organisationID,
		organisationName: props.organisationName,
		organisationVersion: props.organisationVersion,
		currentOrg: props.currentOrg,
		firstCalendar: props.firstCalendar,
		groups,
		selectedGroup,
		updateSelectedGroup,
		customGroupName,
		updateCustomGroupName,
		calendarName,
		updateCalendarName,
		employees,
		selectedEmployees,
		updateSelectedEmployees,
		selectedGroupEmployees,
		updateSelectedGroupEmployees,
		leaveTypes,
		selectedLeaveTypes,
		updateSelectedLeaveTypes,
		selectedDetails,
		updateSelectedDetails,
		selectedCelebrate,
		updateSelectedCelebrate,
		selectedPublicHolidayFeed,
		updateSelectedPublicHolidayFeed,
		// selectedShare,
		// updateSelectedShare,
		// receipientNameForEmailAlerts,
		// updateReceipientNameForEmailAlerts,
		// emailForAlerts,
		// updateEmailForAlerts,
		// selectedEmailDetails,
		// updateSelectedEmailDetails,
		// slackUrl,
		// slackConnection,
		// slackChannelForAlerts,
		// updateSlackChannelForAlerts,
		// selectedSlackDetails,
		// updateSelectedSlackDetails,
		googleUrl,
		updateGoogleUrl,
		googleConnection,
		updateGoogleConnection,
		isCheckingGoogleConnection,
		updateIsCheckingGoogleConnection,
		canGoBack,
		updateCanGoBack,
		canGoFoward,
		updateCanGoFoward,
		title,
		updateTitle,
		hasCustomGroupsProduct,
		step: modalStep,
		updateStep: updateModalStep,
		calendar,
		finishOnBoarding: props.handleClose,
	}

	const modals = [waitingScreen]
		.concat(
			!props.firstCalendar
				? props.organisationVersion !== 'AU' && props.organisationVersion !== 'AUONRAMP' && hasCustomGroupsProduct
					? [calendarNameScreen, employeeSelectionScreen]
					: [groupSelectionScreen]
				: []
		)
		.concat(customGroupName !== '' && hasCustomGroupsProduct ? [employeeSelectionScreen] : [])
		.concat([leaveDetailsScreen, publicHolidayScreen, celebrateScreen])
		.concat([loadingScreen])

	// get employee groups and leave types
	React.useEffect(() => {
		const getData = async () => {
			const [employeesReq, leaveTypesReq] = await Promise.all([
				//, googleUrl, google
				Request.get(`employee?where=XeroOrgID==${props.organisationID}`, props.appState.authState),
				Request.get(`leaveType?where=XeroOrgID==${props.organisationID}`, props.appState.authState),
				// Request.get('google/url', props.appState.authState),
				// Request.get('google', props.appState.authState)
			])

			const employees = employeesReq.data.employees.map((e: Employee) => ({
				...e,
				isActive: false,
			}))
			const employeeGroups: string[] = employeesReq.data.employees.reduce((groups: string[], employee: Employee) => {
				const index = employee.groupName ? groups.indexOf(employee.groupName) : -1
				if (index < 0 && employee.groupName) {
					return [...groups, employee.groupName!]
				} else {
					return groups
				}
			}, [])
			//hardcoded Smart groups
			const xeroEmployementBasis = [
				'Smart: Casual Employees',
				'Smart: Full Time & Part Time Employees',
				'Smart: Full Time Employees',
				'Smart: Part Time Employees',
			]

			const leaveTypes = leaveTypesReq.data.leaveTypes

			updateEmployees(employees)
			updateGroups(
				props.organisationVersion === 'AU' || props.organisationVersion === 'AUONRAMP' ? employeeGroups.concat(xeroEmployementBasis) : employeeGroups
			)
			updateLeaveTypes(leaveTypes)
			updateSelectedLeaveTypes(leaveTypes.map((_lt: LeaveType, i: number) => i))
			// updateGoogleUrl(googleUrl.data.url)
			// updateGoogleConnection(google.data.google)

			if (props.currentOrg) {
				updateHasCustomGroupsProduct(props.currentOrg!.xeroOrg!.products!.some((pr) => pr.productID === Product.CustomGroups.productID))
			}
		}

		if (props.appState.authState.isLoggedIn) {
			getData()
		}
	}, [props.appState.authState, props.organisationID, props.currentOrg, props.organisationVersion])

	React.useEffect(() => {
		const getGoogleConnectionData = async () => {
			const google = await Request.get('google', props.appState.authState)
			updateGoogleConnection(google.data.google)
			updateIsCheckingGoogleConnection(false)
		}

		if (isCheckingGoogleConnection) {
			getGoogleConnectionData()
		}
	}, [isCheckingGoogleConnection, props.appState.authState])

	const getCustomEmployeesArray = (): Employee[] => {
		const finalEmpl = employees!.map((e) => {
			if (selectedEmployees.includes(e.employeeID!)) {
				return { ...e, isActive: true }
			}
			return { ...e, isActive: false }
		})
		return finalEmpl
	}

	const createCalendar = async () => {
		if (groups && leaveTypes && employees) {
			let emps
			if (customGroupName !== '' || calendarName !== '') {
				emps = getCustomEmployeesArray()
			} else if (props.firstCalendar) {
				emps = employees.map((e) => ({ ...e, isActive: true }))
			} else {
				//here we filter employees based on the group/emp basis/ custom
				if (selectedGroup !== -1 && groups[selectedGroup].includes('Smart')) {
					//we have a smart group so we filter based on employment basis
					emps = employees
						.filter((e) => checkEmploymentBasisIsSelected(groups[selectedGroup], e.employmentBasis!))
						.map((e) => ({ ...e, isActive: true }))
				} else {
					//calendar based on xero group
					emps = employees.filter((e) => selectedGroup === -1 || e.groupName === groups[selectedGroup]).map((e) => ({ ...e, isActive: true }))
				}
			}

			const body: CalendarData = {
				fileName:
					props.organisationVersion !== 'AU' && props.organisationVersion !== 'AU'
						? calendarName !== ''
							? calendarName
							: 'All Employees'
						: customGroupName !== ''
						? customGroupName
						: selectedGroup === -1
						? 'All Employees'
						: groups[selectedGroup],
				isCustomGroup: customGroupName !== '' || calendarName !== '' ? true : false,
				accountXeroOrgID: props.accountXeroOrgID,
				showTitle: selectedDetails.includes(ShowDetails.title),
				showGroup: selectedDetails.includes(ShowDetails.group),
				showLeaveType: selectedDetails.includes(ShowDetails.leaveType),
				showAmount: selectedDetails.includes(ShowDetails.amount),
				showUnapprovedLeave: false,
				showBirthday: selectedCelebrate.includes(ShowDetails.birthday),
				showAnniversary: selectedCelebrate.includes(ShowDetails.anniversary),
				showAnniversaryYears: false,
				showDayOfBirthday: true,
				newEmployee1Month: selectedCelebrate.includes(ShowDetails.anniversary1),
				newEmployee3Months: selectedCelebrate.includes(ShowDetails.anniversary3),
				newEmployee6Months: selectedCelebrate.includes(ShowDetails.anniversary6),
				newEmployee18Months: selectedCelebrate.includes(ShowDetails.anniversary18),
				outputToGoogle: false,
				outputToHtml: true,
				outputToIcs: true,
				outputToMicrosoft: false,
				employeeGroupName: customGroupName !== '' ? customGroupName : selectedGroup === -1 ? null : groups[selectedGroup],
				employees: emps,
				leaveTypes: leaveTypes.map((lt, i) => ({
					...lt,
					recordStatusID: selectedLeaveTypes.includes(i) ? RecordStatus.Active : RecordStatus.Deleted,
				})),
				firstDayOfWeek: 0,
				smartEmploymentBasis: selectedGroup === -1 ? null : getCalendarEmploymentBasis(groups[selectedGroup]),
				inputFeeds: selectedPublicHolidayFeed !== '' ? [{ url: selectedPublicHolidayFeed }] : [],
			}

			const req = await Request.post('calendar', body, props.appState.authState)
			const calendar = req.data.calendars && req.data.calendars.length > 0 ? req.data.calendars[0] : null
			localStorage.setItem('newCalendarID', req.data.calendars[0].calendarID!)
			updateCalendar(calendar)
			props.handleClose('refreshData', props.currentOrg, req.data.calendars[0])
		}
	}

	const Modal = modals[modalStep]

	return (
		<div>
			<Row>
				<Col>
					<h3>
						<Calendar />
						{title}
					</h3>
				</Col>
			</Row>
			<Row>
				<Col>
					<Modal {...modalProps} />
				</Col>
			</Row>
			<Row style={{ paddingTop: '10px' }}>
				<Col xs="auto">
					{modalStep + 1 !== modals.length ? (
						<ArrowLeftCircle
							color={canGoBack ? 'black' : 'grey'}
							onClick={() => {
								if (canGoBack && modalStep - 1 >= 0) {
									updateModalStep((step) => step - 1)
									updateCanGoBack(true)
									updateCanGoFoward(true)
								}
							}}
						/>
					) : null}
				</Col>
				<Col></Col>
				<Col xs="auto">
					{modalStep + 1 !== modals.length ? (
						<ArrowRightCircle
							color={modalProps.canGoFoward ? 'black' : 'grey'}
							onClick={() => {
								if (modalStep === modals.indexOf(calendarNameScreen)) {
									updateTitle(`${props.organisationName} > ${calendarName}`)
								}
								if (modalStep === modals.indexOf(waitingScreen)) {
									updateCanGoBack(false)
									updateCanGoFoward(false)
								}
								if (modalStep === modals.indexOf(loadingScreen)) {
									updateCanGoBack(true)
									updateCanGoFoward(true)
									createCalendar()
								}
								if (modalStep === modals.indexOf(celebrateScreen)) {
									createCalendar()
								}
								if (modalStep === modals.indexOf(groupSelectionScreen)) {
									if (customGroupName !== '' && hasCustomGroupsProduct) {
										updateTitle(`${props.organisationName} > ${customGroupName}`)
									} else {
										updateTitle(`${props.organisationName} > ${selectedGroup >= 0 ? groups![selectedGroup] : 'All Employees'}`)
									}
								}
								if (canGoFoward && modalStep + 1 < modals.length) {
									updateCanGoBack(true)
									updateCanGoFoward(true)
									updateModalStep((step) => step + 1)
								}
							}}
						/>
					) : null}
				</Col>
			</Row>
		</div>
	)
}

const waitingScreen = (props: ModalProps) => {
	if (props.groups && props.leaveTypes) {
		if (props.groups.length > 0 || !props.firstCalendar) {
			//props.updateStep(ScreenStep.groupSelectionScreen)
			props.updateTitle(`${props.organisationName}`)
		} else {
			props.updateTitle(`${props.organisationName} > All Employees`)
			//props.updateStep(ScreenStep.leaveTypeScreen)
		}
		props.updateStep(1)
		return <div></div>
	} else {
		return (
			<div>
				<Spinner animation={'border'} size={'sm'} style={{ marginLeft: '5px' }} />
			</div>
		)
	}
}

// interface CardWithImageAndSwitchProps {
//     title: string
//     image: string
//     switchValue: boolean
//     onSwitchUpdate: (value: boolean) => void
// }

// const CardWithImageAndSwitch = (props: CardWithImageAndSwitchProps) => {
//     return (
//         <div className={'card-with-image-and-switch' + (props.switchValue ? ' success' : '')}>
//             {props.image === "Calendar"
//                 ? <Calendar />
//                 : props.image === "Code" ?
//                     <Code />
//                     : props.image === "Coffee" ?
//                         <img src={GoogleImage} alt='Google' />
//                         : null}
//             <p>{props.title}</p>
//             <div>
//                 <Switch value={props.switchValue} defaultChecked={true} onChange={(_event, checked) => props.onSwitchUpdate(checked)} color="primary" />
//             </div>
//         </div>
//     )
// }

// eslint-disable-next-line
const updateCheckbox = (updateFunction: React.Dispatch<React.SetStateAction<any>>, array: any[]) => (e: { target: { checked: boolean; id: string } }) => {
	const checked: boolean = e.target.checked
	const key: string = e.target.id
	if (checked && !array.includes(key)) {
		updateFunction([...array, key])
	} else if (!checked && array.includes(key)) {
		updateFunction(array.filter((a) => a !== key))
	}
}

const leaveDetailsScreen = (props: ModalProps) => {
	return (
		<Row>
			<Col>
				<p>What details do you want to share?</p>
				<Form>
					<div className="mb-3">
						<Form.Check
							type={'checkbox'}
							id={'-1'}
							label={'Select All'}
							checked={props.selectedDetails.length === (props.organisationVersion === 'AU' || props.organisationVersion === 'AU' ? 4 : 2)}
							onChange={(e) => {
								const checked = e.target.checked
								if (checked) {
									props.updateSelectedDetails(
										[ShowDetails.leaveType, ShowDetails.amount].concat(
											props.organisationVersion === 'AU' || props.organisationVersion === 'AUONRAMP'
												? [ShowDetails.title, ShowDetails.group]
												: []
										)
									)
								} else {
									props.updateSelectedDetails([])
								}
							}}
						/>
						<Form.Check type="checkbox" id="employeeName" label="Employee Name" checked={true} readOnly={true} />
						<Form.Check
							type={'checkbox'}
							id={ShowDetails.leaveType}
							label={'Leave Type'}
							checked={props.selectedDetails.includes(ShowDetails.leaveType)}
							onChange={updateCheckbox(props.updateSelectedDetails, props.selectedDetails)}
						/>
						<Form.Check
							type={'checkbox'}
							id={ShowDetails.amount}
							label={'Amount Taken'}
							checked={props.selectedDetails.includes(ShowDetails.amount)}
							onChange={updateCheckbox(props.updateSelectedDetails, props.selectedDetails)}
						/>
						{props.organisationVersion === 'AU' || props.organisationVersion === 'AUONRAMP' ? (
							<>
								<Form.Check
									type={'checkbox'}
									id={ShowDetails.title}
									label={'Employee Title'}
									checked={props.selectedDetails.includes(ShowDetails.title)}
									onChange={updateCheckbox(props.updateSelectedDetails, props.selectedDetails)}
								/>
								<Form.Check
									type={'checkbox'}
									id={ShowDetails.group}
									label={'Employee Group Name'}
									checked={props.selectedDetails.includes(ShowDetails.group)}
									onChange={updateCheckbox(props.updateSelectedDetails, props.selectedDetails)}
								/>
							</>
						) : null}
					</div>
				</Form>
			</Col>
		</Row>
	)
}

const updateTextField = (updateFunction: React.Dispatch<React.SetStateAction<string>>) => (e: { target: { innerText: string; value: string } }) => {
	const value: string = e.target.innerText || e.target.value
	updateFunction(value)
}

const celebrateScreen = (props: ModalProps) => {
	return (
		<Row>
			<Col>
				<p>Do you want to Celebrate any of these for your team members?</p>
				<Form>
					<div className="mb-3">
						<Form.Check
							type={'checkbox'}
							id={'-1'}
							label={'Select All'}
							checked={props.selectedCelebrate.length === 2}
							onChange={(e) => {
								const checked = e.target.checked
								if (checked) {
									props.updateSelectedCelebrate([ShowDetails.birthday, ShowDetails.anniversary])
								} else {
									props.updateSelectedCelebrate([])
								}
							}}
						/>
						<Form.Check
							type={'checkbox'}
							id={ShowDetails.anniversary}
							label={'Anniversaries'}
							checked={props.selectedCelebrate.includes(ShowDetails.anniversary)}
							onChange={updateCheckbox(props.updateSelectedCelebrate, props.selectedCelebrate)}
						/>
						<Form.Check
							type={'checkbox'}
							id={ShowDetails.birthday}
							label={'Birthdays'}
							checked={props.selectedCelebrate.includes(ShowDetails.birthday)}
							onChange={updateCheckbox(props.updateSelectedCelebrate, props.selectedCelebrate)}
						/>
					</div>
				</Form>
			</Col>
		</Row>
	)
}

const groupSelectionScreen = (props: ModalProps) => {
	return (
		<>
			<Row>
				<Col>
					<p>Is this calendar for a:</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p>Employee Group</p>
					<DropdownButton
						as={ButtonGroup}
						id={'dropdown'}
						variant={'primary'}
						title={props.selectedGroup === -1 ? 'All Employees' : props.groups![props.selectedGroup]}
						onSelect={(eventKey) => {
							if (props.groups && eventKey) {
								const key = Number(eventKey)
								props.updateSelectedGroup(key)
								props.updateCustomGroupName('')
								// props.updateTitle(`${props.organisationName} > ${key >= 0 ? props.groups[key] : 'All Employees'}`)
							}
						}}
						alignLeft
					>
						<Dropdown.Item eventKey={'-1'} active={props.selectedGroup === -1}>
							All Employees
						</Dropdown.Item>
						<Dropdown.Divider />
						{props.groups!.map((group, index) => {
							if (group === 'Smart: Casual Employees') {
								return (
									<>
										<Dropdown.Divider />
										<Dropdown.Item key={group} eventKey={String(index)} active={props.selectedGroup === index}>
											{group}
										</Dropdown.Item>
									</>
								)
							} else {
								return (
									<Dropdown.Item key={group} eventKey={String(index)} active={props.selectedGroup === index}>
										{group}
									</Dropdown.Item>
								)
							}
						})}
					</DropdownButton>
				</Col>
				<Col>
					<p>Or</p>
				</Col>
				{props.hasCustomGroupsProduct ? (
					<Col>
						<p>Custom</p>
						<Form.Group>
							<Form.Control
								type={'text'}
								value={props.customGroupName}
								onChange={updateTextField(props.updateCustomGroupName)}
								name={'customGroup'}
							/>
						</Form.Group>
					</Col>
				) : (
					<OverlayTrigger
						placement="auto"
						overlay={<Tooltip id={`tooltip-xero`}>Premium Feature - contact support@finlert.com to get started.</Tooltip>}
					>
						<Col style={{ color: '#82888a' }}>
							<p>Custom</p>
							<Form.Group>
								<Form.Control
									type={'text'}
									value={props.customGroupName}
									onChange={updateTextField(props.updateCustomGroupName)}
									name={'customGroup'}
									disabled
								/>
							</Form.Group>
						</Col>
					</OverlayTrigger>
				)}
			</Row>
		</>
	)
}

const calendarNameScreen = (props: ModalProps) => {
	return (
		<>
			<Row>
				<Col>
					<p>Please enter the calendar name:</p>
					<Form>
						<div className="mb-3">
							<Form.Group>
								<Form.Control type={'text'} value={props.calendarName} onChange={updateTextField(props.updateCalendarName)} />
							</Form.Group>
						</div>
					</Form>
				</Col>
			</Row>
		</>
	)
}

const publicHolidayScreen = (props: ModalProps) => {
	return (
		<>
			<Row>
				<Col>
					<p>Choose the public holidays you&apos;d like to add to the calendar (optional):</p>
					<Form>
						<div className="mb-3">
							<Form.Group>
								<DropdownButton
									as={ButtonGroup}
									id={'dropdown'}
									variant={'primary'}
									title={
										props.selectedPublicHolidayFeed
											? presetIcsFeeds.find((e) => e.url === props.selectedPublicHolidayFeed)?.name ?? 'Custom URL'
											: 'Select one'
									}
									onSelect={(e) => {
										if (e) {
											props.updateSelectedPublicHolidayFeed(e)
										}
									}}
									alignRight
									style={{
										paddingLeft: '0px',
										marginBottom: '15px',
										fontSize: '14px',
									}}
								>
									<Dropdown.Header>Public Holidays</Dropdown.Header>
									{presetIcsFeeds.map((e) => (
										<Dropdown.Item key={e.name} eventKey={e.url} active={props.selectedPublicHolidayFeed === e.url}>
											{e.name}
										</Dropdown.Item>
									))}
								</DropdownButton>
							</Form.Group>
						</div>
					</Form>
				</Col>
			</Row>
		</>
	)
}

const employeeSelectionScreen = (props: ModalProps) => {
	return (
		<Row>
			<Col>
				<p>Team member</p>
				<Form>
					<div className="mb-3">
						<Form.Check
							type={'checkbox'}
							id={'-1'}
							label={'Select All'}
							checked={
								props.selectedGroup === -1
									? props.selectedEmployees.length === props.employees!.length
									: props.selectedEmployees.length ===
									  props.employees!.filter((empl) => empl.groupName === props.groups![props.selectedGroup]).length
							}
							onChange={(e) => {
								const checked = e.target.checked
								if (checked) {
									props.updateSelectedEmployees(
										props.selectedGroup === -1
											? props.employees!.map((e) => e.employeeID!)
											: props.selectedGroupEmployees!.map((e) => e.employeeID!)
									)
								} else {
									props.updateSelectedEmployees([])
								}
							}}
						/>
						{props.employees!.map((employee: Employee) => (
							<Form.Check
								key={employee.employeeID}
								type={'checkbox'}
								id={employee.employeeID}
								label={`${employee.firstName} ${employee.lastName}`}
								checked={props.selectedEmployees!.includes(employee.employeeID!)}
								onChange={updateCheckbox(props.updateSelectedEmployees, props.selectedEmployees)}
							/>
						))}
					</div>
				</Form>
			</Col>
		</Row>
	)
}

const loadingScreen = () => {
	return (
		<div>
			<Spinner animation={'border'} size={'sm'} style={{ marginLeft: '5px' }} />
		</div>
	)
}

export default OnboardModal
