import React from 'react'
import { Button, Row, FormGroup } from 'react-bootstrap'
import { AccountXeroOrg, Calendar as CalendarData } from '../../../../back-end/utilities/apiDefinitions'

// definitions
import * as AppDefinitions from '../../App.d'

// components
import CalendarComponent from './calendarTab/calendar'
import EmailAlerts from './emails'
import EmployeesList from './employees'
import SettingsFrame from './settings/settings'
import SlackAlerts from '../common/slackAlerts'
import EmployeesLeaveBalances from './leaveBalances'
import OverviewComponent from './overviewComponents/overview'
import UnapprovedLeavesList from './unapprovedTab/unapprovedLeaves'
import LeaveRules from './leaveRuleTab/leaveRule'
import LoadingImage from '../../images/Finlert-loading-GIF_100px.gif'
import { AppStateAction } from '../../App.d'

type FrameType = 'calendar' | 'emails' | 'slack' | 'settings' | 'employees' | 'leaveBalances' | 'overview' | 'unapprovedLeaves' | 'leaveRules'
interface FrameProps {
	pageStatus: string
	appState: AppDefinitions.AppState
	currentFrame: FrameType
	currentOrg: AccountXeroOrg | null
	currentCalendar: CalendarData | null
	xeroOrgCalendars: CalendarData[] | null
	updateAppState: React.Dispatch<AppStateAction>
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
	updateCurrentCalendar: (cal: CalendarData) => void
	updateCurrentOrg: (xero: AccountXeroOrg) => void
	updateModal: () => void
}

const BodyFrame = (props: FrameProps) => {
	if (props.currentOrg && props.currentCalendar) {
		return (
			<>
				<OverviewComponent
					show={props.currentFrame === 'overview'}
					appState={props.appState}
					currentOrg={props.currentOrg}
					currentCalendar={props.currentCalendar}
					xeroOrgCalendars={props.xeroOrgCalendars}
					pageStatus={props.pageStatus}
					updatePageStatus={props.updatePageStatus}
				/>
				<CalendarComponent
					show={props.currentFrame === 'calendar'}
					appState={props.appState}
					currentOrg={props.currentOrg}
					currentCalendar={props.currentCalendar}
					xeroOrgCalendars={props.xeroOrgCalendars ? props.xeroOrgCalendars : []}
					pageStatus={props.pageStatus}
					updatePageStatus={props.updatePageStatus}
				/>
				{props.currentOrg.xeroOrg?.version === 'AU' && (
					<>
						<UnapprovedLeavesList
							show={props.currentFrame === 'unapprovedLeaves'}
							appState={props.appState}
							currentOrg={props.currentOrg}
							currentCalendar={props.currentCalendar}
							updateCurrentCalendar={props.updateCurrentCalendar}
							pageStatus={props.pageStatus}
							updatePageStatus={props.updatePageStatus}
						/>
						<LeaveRules
							show={props.currentFrame === 'leaveRules'}
							appState={props.appState}
							currentOrg={props.currentOrg}
							currentCalendar={props.currentCalendar}
							pageStatus={props.pageStatus}
							updatePageStatus={props.updatePageStatus}
						/>
					</>
				)}
				<EmailAlerts
					show={props.currentFrame === 'emails'}
					appState={props.appState}
					currentOrg={props.currentOrg}
					currentCalendar={props.currentCalendar}
					pageStatus={props.pageStatus}
					updatePageStatus={props.updatePageStatus}
				/>
				<SlackAlerts
					show={props.currentFrame === 'slack'}
					appState={props.appState}
					currentOrg={props.currentOrg}
					currentCalendar={props.currentCalendar}
					pageStatus={props.pageStatus}
					updatePageStatus={props.updatePageStatus}
					updateAppState={props.updateAppState}
					loadingImage={LoadingImage}
				/>
				<EmployeesList
					show={props.currentFrame === 'employees'}
					appState={props.appState}
					currentOrg={props.currentOrg}
					currentCalendar={props.currentCalendar}
					updateCurrentCalendar={props.updateCurrentCalendar}
					pageStatus={props.pageStatus}
					updatePageStatus={props.updatePageStatus}
				/>
				<EmployeesLeaveBalances
					show={props.currentFrame === 'leaveBalances'}
					appState={props.appState}
					currentOrg={props.currentOrg}
					currentCalendar={props.currentCalendar}
					pageStatus={props.pageStatus}
					updatePageStatus={props.updatePageStatus}
				/>
				<SettingsFrame
					show={props.currentFrame === 'settings'}
					appState={props.appState}
					currentOrg={props.currentOrg}
					currentCalendar={props.currentCalendar}
					updateCurrentCalendar={props.updateCurrentCalendar}
					updateCurrentOrg={props.updateCurrentOrg}
					pageStatus={props.pageStatus}
					updatePageStatus={props.updatePageStatus}
				/>
			</>
		)
	} else {
		if (props.pageStatus === 'Finished') {
			return (
				<>
					<FormGroup style={{ padding: '10px' }}>
						<Row className="calendar-alerts-card">
							<p>You don&apos;t have any calendars set up for this organisation.</p>
							<Button type="button" variant="primary" onClick={props.updateModal}>
								Create Calendar
							</Button>
						</Row>
					</FormGroup>
				</>
			)
		} else {
			return (
				<div className="loading-gif">
					<img src={LoadingImage} alt="Loading ..." />
				</div>
			)
		}
	}
}

export type { FrameType }
export default BodyFrame
