import Moment from 'moment'
import { AccountXeroOrg, Calendar as CalendarData, Employee, LeaveApplication } from '../../../../../back-end/utilities/apiDefinitions'
import { CalendarLeave } from './bigCalendarTypes'
import { LeavePeriod } from '../../../../../back-end/utilities/apiDefinitions'

import * as Product from '../../../constants/products'

export const formatEventDate = (date: string, dontAddYear?: boolean) => {
	const givenDate = Moment.utc(date)
	const today = Moment.utc()
	const sixWeeksAgo = today.clone().subtract(6, 'weeks')

	const eventDate = Moment.utc(today.clone().format(`YYYY-${givenDate.format('MM')}-${givenDate.format('DD')}`))

	if (eventDate.isBefore(sixWeeksAgo) && !dontAddYear) {
		return eventDate.clone().add(1, 'year').format('YYYY-MM-DD')
	} else {
		return eventDate.format('YYYY-MM-DD')
	}
}

export const isNewStart = (startDate: string) => {
	// An anniversary is a newstart if the employee's start date is greater than the calendar start date
	const calStart = new Date()
	calStart.setDate(calStart.getDate() - 45)
	const givenDate = new Date(startDate)
	if (givenDate > calStart) {
		return true
	} else {
		return false
	}
}

export const formatLeaveTitle = (title: string, character: string) => {
	if (title) {
		if (character === ' ') {
			return title + ' '
		} else if (character === '-') {
			return ' - ' + title
		}
	}
	return ''
}

export const convertNumberToHoursAndMinutes = (hoursAsADecimal: number) => {
	return {
		hours: Math.floor(hoursAsADecimal),
		minutes: Number(((hoursAsADecimal - Math.floor(hoursAsADecimal)) * 60).toFixed(0)),
	}
}

export const getAnniversaryYears = (eventDate: string, startDate: string) => {
	const amount = new Date(eventDate).getFullYear() - new Date(startDate).getFullYear()
	return amount === 1 ? '1 year' : `${amount} years`
}

export const getAmount = (leaveApplication: LeaveApplication, showLeaveInDays: boolean, hoursInDay: number) => {
	const totalUnits = leaveApplication.leavePeriod
		? leaveApplication.leavePeriod.reduce((total: number, leavePeriod: LeavePeriod) => Number(total + (Number(leavePeriod.numberOfUnits) || 0)), 0)
		: 0

	if (leaveApplication.leaveType && leaveApplication.leaveType.unitType) {
		if (leaveApplication.leaveType.unitType.toLowerCase() === 'hours') {
			if (showLeaveInDays && totalUnits > 0) {
				const days = Math.floor(totalUnits / hoursInDay)
				const { hours, minutes } = convertNumberToHoursAndMinutes(totalUnits % hoursInDay)
				return ` - ${days > 0 ? days + ` day${days > 1 ? 's' : ''}` : ''}${days > 0 && hours > 0 ? ' and ' : ''}${hours > 0 ? hours + ' hours' : ''}${
					(days > 0 || hours > 0) && minutes > 0 ? ' and ' : ''
				}${minutes > 0 ? minutes + ' minutes' : ''}`
			} else {
				const { hours, minutes } = convertNumberToHoursAndMinutes(totalUnits)
				if (totalUnits > 0) {
					return ` - ${hours > 0 ? hours + ' hours' : ''}${hours > 0 && minutes > 0 ? ' and ' : ''}${minutes > 0 ? minutes + ' minutes' : ''}`
				}
			}
		} else {
			return ` - ${totalUnits} ${leaveApplication.leaveType.unitType.toLowerCase()}`
		}
	}
	return ''
}

export const getEmployeeAnniversaryEvents = (currCalendar: CalendarData): CalendarLeave[] => {
	return currCalendar.showAnniversary ||
		currCalendar.newEmployee1Month ||
		currCalendar.newEmployee3Months ||
		currCalendar.newEmployee6Months ||
		currCalendar.newEmployee18Months
		? currCalendar
				.employees!.filter((e) => e.isActive && e.startDate)
				.map((employee: Employee) => {
					const event = ([] as CalendarLeave[])
						.concat(
							currCalendar.showAnniversary
								? [
										{
											start: formatEventDate(Moment.utc(employee.startDate).toJSON()),
											end: formatEventDate(Moment.utc(employee.startDate).toJSON()),
											type: 'anniversary',
											allDay: true,
											employeeID: employee!.employeeID!,
											leaveApplicationID: `anniversary-${employee!.employeeID!}`,
											showTitle: true,
											hexColour: '#F8C950',
											title: `${isNewStart(employee.startDate!) ? 'New Start' : 'Anniversary'}: ${
												employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`
											}${currCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : ''}${
												currCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''
											}${
												currCalendar.showAnniversaryYears && !isNewStart(employee.startDate!)
													? ` (${getAnniversaryYears(formatEventDate(Moment.utc(employee.startDate).toJSON()), employee.startDate!)})`
													: ''
											}`,
										},
								  ]
								: []
						)
						.concat(
							currCalendar.newEmployee1Month
								? [
										{
											start: Moment.utc(employee.startDate).add(1, 'month').toJSON(),
											end: Moment.utc(employee.startDate).add(1, 'month').toJSON(),
											type: 'anniversary',
											leaveApplicationID: `anniversary-1-month-${employee!.employeeID!}`,
											allDay: true,
											hexColour: '#F8C950',
											title: `1 Month Anniversary: ${employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`}${
												currCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : ''
											}${currCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''}`,
										},
								  ]
								: []
						)
						.concat(
							currCalendar.newEmployee3Months
								? [
										{
											start: Moment.utc(employee.startDate).add(3, 'month').toJSON(),
											end: Moment.utc(employee.startDate).add(3, 'month').toJSON(),
											type: 'anniversary',
											allDay: true,
											hexColour: '#F8C950',
											leaveApplicationID: `anniversary-3-months-${employee!.employeeID!}`,
											title: `3 Month Anniversary: ${employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`}${
												currCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : ''
											}${currCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''}`,
										},
								  ]
								: []
						)
						.concat(
							currCalendar.newEmployee6Months
								? [
										{
											start: Moment.utc(employee.startDate).add(6, 'month').toJSON(),
											end: Moment.utc(employee.startDate).add(6, 'month').toJSON(),
											type: 'anniversary',
											allDay: true,
											hexColour: '#F8C950',
											leaveApplicationID: `anniversary-6-months-${employee!.employeeID!}`,
											title: `6 Month Anniversary: ${employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`}${
												currCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : ''
											}${currCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''}`,
										},
								  ]
								: []
						)
						.concat(
							currCalendar.newEmployee18Months
								? [
										{
											start: Moment.utc(employee.startDate).add(18, 'month').toJSON(),
											end: Moment.utc(employee.startDate).add(18, 'month').toJSON(),
											type: 'anniversary',
											leaveApplicationID: `anniversary-18-months-${employee!.employeeID!}`,
											allDay: true,
											hexColour: '#F8C950',
											title: `18 Month Anniversary: ${employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`}${
												currCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : ''
											}${currCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''}`,
										},
								  ]
								: []
						)
					return event
				})
				.flat()
		: []
}

export const getEmployeeBirthdayEvents = (currCalendar: CalendarData): CalendarLeave[] =>
	currCalendar.showBirthday
		? currCalendar
				.employees!.filter((e) => e.isActive === true && e.dateOfBirth !== null)
				.map((employee: Employee) => {
					const event = {
						start: currCalendar.showDayOfBirthday
							? formatEventDate(employee.dateOfBirth!)
							: formatEventDate(Moment.utc(employee.dateOfBirth!).format('YYYY-MM-01')),
						end: currCalendar.showDayOfBirthday
							? formatEventDate(employee.dateOfBirth!)
							: formatEventDate(Moment.utc(employee.dateOfBirth!).format('YYYY-MM-01')),
						type: 'birthday',
						allDay: true,
						employeeID: employee!.employeeID!,
						leaveApplicationID: `birthday-${employee!.employeeID!}`,
						showTitle: true,
						hexColour: '#F89995',
						title: `Birthday: ${employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`}${
							currCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : ''
						}${currCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''}`,
					}
					return event
				})
		: []

export const getCalendarLeaveEvents = (currCalendar: CalendarData, currOrg: AccountXeroOrg, leaveApplications: LeaveApplication[]): CalendarLeave[] => {
	const activeEmployees = currCalendar.employees!.filter((e) => e.isActive).map((e) => e.employeeID!)
	return leaveApplications.length > 0 || leaveApplications.length > 0
		? leaveApplications
				.filter(
					(lv: LeaveApplication) =>
						activeEmployees.includes(lv.employeeID!) &&
						(currCalendar.showUnapprovedLeave || (lv.leavePeriod || []).every((lp) => lp.status !== LeavePeriodStatus.pending)) &&
						(lv.leavePeriod || []).every((lp) => lp.status !== LeavePeriodStatus.rejected)
				)
				.map((leave: LeaveApplication) => {
					const event = {
						start: leave.startDate!,
						end: leave.endDate!,
						allDay: true,
						description:
							(leave.isNonXero && currCalendar.showCustomLeaveDescription) || (!leave.isNonXero && currCalendar.showPayrollDescription)
								? leave.description!
								: '',
						isNonXero: leave.isNonXero,
						type: leave.isNonXero ? 'custom' : 'leave',
						hexColour: leave.hexColour || leave.leaveType!.hexColour,
						leaveApplicationID: leave.leaveApplicationID!,
						title:
							leave.title ||
							`${
								(currCalendar.showLeaveType && leave.leaveType) ||
								(leave.leaveType && leave.leaveType.isNonXero && leave.leaveType.showTypeInCalendar)
									? `${leave.leavePeriod!.some((lp) => lp.status === LeavePeriodStatus.pending) ? 'UNAPPROVED ' : ''}${leave.leaveType.name}`
									: `${leave.leavePeriod!.some((lp) => lp.status === LeavePeriodStatus.pending) ? 'UNAPPROVED ' : ''}Leave`
							}: ${leave.employee!.alias ? leave.employee!.alias : `${leave.employee!.firstName} ${leave.employee!.lastName}`}${
								currCalendar.showTitle ? formatLeaveTitle(leave.employee!.jobTitle!, '-') : ''
							}${currCalendar.showGroup ? formatLeaveTitle(leave.employee!.groupName!, '-') : ''}${
								currCalendar.showAmount ? getAmount(leave, currOrg.xeroOrg!.showLeaveInDays!, currOrg.xeroOrg!.hoursInDay!) : ''
							}`,
						createUserID: leave.createUserID,
						employeeID: leave.employeeID!,
						showTitle: true,
					}
					return event
				})
		: []
}

export const getCalendarTrialEvent = (currOrg: AccountXeroOrg): CalendarLeave[] => {
	const trial = currOrg.xeroOrg!.products!.find((p) => p.productID === Product.LeaveCalTrial.productID)
	if (trial && trial.startDate) {
		const startDate = new Date(trial.startDate)
		startDate.setDate(startDate.getDate() + 15)
		const endDate = new Date(startDate)
		endDate.setDate(endDate.getDate() + 7)

		return [
			{
				start: startDate.toJSON(),
				end: endDate.toJSON(),
				type: 'reminder',
				allDay: true,
				leaveApplicationID: 'trial',
				hexColour: '#FF0000',
				title: `Your LeaveCal trial is expiring - make sure you subscribe to keep receiving leave updates`,
				showTitle: true,
			},
		]
	}
	return [] // not on trial
}

export enum LeavePeriodStatus {
	approved,
	completed,
	pending,
	rejected,
	cancelled,
}
