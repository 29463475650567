import { FormikErrors, FormikTouched, getIn } from 'formik'

// getIn is pretty undocumented, but this is basically how <ErrorMessage /> from Formik works under the hood
const errorMsgFromFormik = <T>(touched: FormikTouched<T>, errors: FormikErrors<T>, name: string) => {
	const touch = getIn(touched, name)
	const error = getIn(errors, name)
	return touch && error && typeof error === 'string' ? error : ''
}

export { errorMsgFromFormik }
