import React from 'react'
import moment from 'moment'
import * as AppDefinitions from '../../../App.d'
import { Row, Col, FormLabel } from 'react-bootstrap'
import { PieChart } from 'react-minimal-pie-chart'
import Plot from 'react-plotly.js'
import {
	AccountXeroOrg,
	Calendar as CalendarData,
	Employee,
	EmployeeLeaveBalance,
	LeaveApplication,
	LeaveType,
} from '../../../../../back-end/utilities/apiDefinitions'
import { getCalendarsWithBalances, getDataForBarChart, getDataForPieChart } from './overview'

const getAge = (dob: string) => {
	const age = moment().diff(dob, 'years')
	return age
}

interface TeamDemographicsComponentProps {
	allLeaveApplications: LeaveApplication[] | []
	originalBalanceData: EmployeeLeaveBalance[]
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
}

const TeamDemographicsComponent = (props: TeamDemographicsComponentProps) => {
	//** Calculative functions */
	const returnEmployeePropertyValueCount = (employees: Employee[], compare: (employee: Employee) => boolean) =>
		employees.filter((e) => e.isActive && compare(e)).length
	const getGenders = () => {
		const genders = {
			Male: returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => e.gender === 'M'),
			Female: returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => e.gender === 'F'),
			'Not Stated': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => e.gender === 'N'),
			'Non-Binary': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => e.gender === 'I'),
		}

		return genders
	}

	const getAges = () => {
		const ageGroups = {
			'<18': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => getAge(e.dateOfBirth!) < 18),
			'18-30': returnEmployeePropertyValueCount(
				props.currentCalendar.employees!,
				(e: Employee) => getAge(e.dateOfBirth!) >= 18 && getAge(e.dateOfBirth!) < 31
			),
			'31-45': returnEmployeePropertyValueCount(
				props.currentCalendar.employees!,
				(e: Employee) => getAge(e.dateOfBirth!) >= 31 && getAge(e.dateOfBirth!) < 46
			),
			'46-55': returnEmployeePropertyValueCount(
				props.currentCalendar.employees!,
				(e: Employee) => getAge(e.dateOfBirth!) >= 46 && getAge(e.dateOfBirth!) < 56
			),
			'>55': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => getAge(e.dateOfBirth!) >= 56),
		}

		return ageGroups
	}

	const getTenures = () => {
		const tenureYears = {
			'<1': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => getAge(e.startDate!) < 1),
			'1-5': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => getAge(e.startDate!) >= 1 && getAge(e.startDate!) < 6),
			'6-10': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => getAge(e.startDate!) >= 6 && getAge(e.startDate!) < 11),
			'11-20': returnEmployeePropertyValueCount(
				props.currentCalendar.employees!,
				(e: Employee) => getAge(e.startDate!) >= 11 && getAge(e.startDate!) < 21
			),
			'>20': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => getAge(e.startDate!) >= 21),
		}

		return tenureYears
	}

	// const getEmployeesByBasis = () => {
	// 	// out for now
	// }

	const getEmployeesByGroup = () => {
		return props.currentCalendar
			.employees!.filter((e) => e.isActive)
			.reduce((result, currentValue: Employee) => {
				result[currentValue.groupName! || 'None'] = result[currentValue.groupName! || 'None'] + 1 || 1
				return result
			}, {} as { [groupName: string]: number })
	}

	// this function should be refactored
	const groupDaysByLeaveType = () => {
		const leaveTypeObjects = props.currentCalendar.leaveTypes!.reduce((result, currentValue: LeaveType) => {
			result[currentValue.xeroOrgLeaveTypeID!] = {
				name: currentValue.name!,
				days: 0,
			}
			return result
		}, {} as { [key: string]: { name: string; days: number } | number }) // xeroOrgLeaveTypeID is key

		const balances = getCalendarsWithBalances(
			props.currentCalendar,
			props.allLeaveApplications,
			props.originalBalanceData,
			props.currentOrg!.xeroOrg!.timezone!
		)

		balances.employees
			.filter((e) => e.isActive)
			.map((emp) => emp.leaveBalance)
			.flat()
			.forEach((lb) => {
				if (leaveTypeObjects[lb.xeroOrgLeaveTypeID!]) {
					leaveTypeObjects[lb.xeroOrgLeaveTypeID!] = {
						...(leaveTypeObjects[lb.xeroOrgLeaveTypeID!] as { name: string; days: number }),
						days: (leaveTypeObjects[lb.xeroOrgLeaveTypeID!] as { name: string; days: number }).days! + lb.value!,
					}
				}
			})
		Object.keys(leaveTypeObjects).forEach((key: string) => {
			if ((leaveTypeObjects[key] as { name: string; days: number }).days) {
				leaveTypeObjects['total'] =
					((leaveTypeObjects['total'] as number) || 0) + Number((leaveTypeObjects[key] as { name: string; days: number }).days)
			}
		})
		return leaveTypeObjects
	}

	return (
		<Row className="calendar-alerts-card ">
			<Col>
				<Row style={{ paddingLeft: '20px' }}>
					<FormLabel className="emails-label" style={{ paddingTop: '20px' }}>
						Team Demographics
					</FormLabel>
				</Row>

				<Row
					style={{
						marginTop: '-5px',
						paddingLeft: '20px',
						backgroundColor: 'white',
						paddingTop: '20px',
					}}
				>
					<Col style={{ textAlign: 'center' }}>
						<PieChart
							data={getDataForPieChart(getGenders(), 0)}
							style={{ height: '230px' }}
							label={({ dataEntry }) => `${dataEntry.title!} (${dataEntry.value!})`}
							labelStyle={{
								fontSize: '5px',
							}}
							labelPosition={60}
							animate
						/>
						<FormLabel style={{ paddingTop: '20px', fontWeight: 'bold' }}>Gender</FormLabel>
					</Col>
					<Col style={{ textAlign: 'center' }}>
						<PieChart
							data={getDataForPieChart(getAges(), 1)}
							style={{ height: '230px' }}
							label={({ dataEntry }) => `${dataEntry.title!} (${dataEntry.value!})`}
							labelStyle={{
								fontSize: '5px',
							}}
							labelPosition={60}
							animate
						/>
						<FormLabel style={{ paddingTop: '20px', fontWeight: 'bold' }}>Age</FormLabel>
					</Col>
					<Col style={{ textAlign: 'center' }}>
						<PieChart
							data={getDataForPieChart(getTenures(), 2)}
							style={{ height: '230px' }}
							label={({ dataEntry }) => `${dataEntry.title!} (${dataEntry.value!})`}
							labelStyle={{
								fontSize: '5px',
							}}
							labelPosition={60}
							animate
						/>
						<FormLabel style={{ paddingTop: '20px', fontWeight: 'bold' }}>Tenure</FormLabel>
					</Col>
					<Col style={{ textAlign: 'center' }}>
						<PieChart
							data={getDataForPieChart(getEmployeesByGroup(), 3)}
							style={{ height: '230px' }}
							label={({ dataEntry }) => `${dataEntry.title!} (${dataEntry.value!})`}
							labelStyle={{
								fontSize: '5px',
							}}
							labelPosition={60}
							animate
						/>
						<FormLabel style={{ paddingTop: '20px', fontWeight: 'bold' }}>Employee Group</FormLabel>
					</Col>
				</Row>
				<Row
					style={{
						marginTop: '-5px',
						paddingLeft: '20px',
						backgroundColor: 'white',
						paddingTop: '20px',
					}}
				>
					<Col style={{ textAlign: 'center' }}>
						<Plot
							data={[
								getDataForBarChart(
									groupDaysByLeaveType(),
									4,
									props.currentOrg.xeroOrg!.showLeaveInDays!,
									props.currentOrg.xeroOrg!.hoursInDay!
								),
							]}
							layout={{
								title: '',
								yaxis: {
									automargin: true,
									type: 'category',
									ticksuffix: '  ',
								},
								xaxis: { automargin: true },
								hovermode: 'closest',
							}}
							config={{
								displayModeBar: false,
							}}
						/>
						<Col sm="5"></Col>
						<FormLabel style={{ paddingTop: '10px', fontWeight: 'bold' }}>
							{props.currentOrg.xeroOrg && props.currentOrg.xeroOrg.showLeaveInDays
								? 'Days currently owed to employees'
								: 'Amount currently owed to employees'}
						</FormLabel>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default TeamDemographicsComponent
