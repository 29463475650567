import React from 'react'

// definitions
import * as AppDefinitions from '../../../App.d'

// components
import { Button, Form } from 'react-bootstrap'
import { Calendar as CalendarData } from '../../../../../back-end/utilities/apiDefinitions'
import * as Messages from '../../ui/messages/messages'

// utillites
import * as Request from '../../../utilities/request'
import * as I from '../../../utilities/me'
import { MessageAction } from '../../ui/messages/message'

export interface CalendarNameProps {
	appState: AppDefinitions.AppState
	updateMessages: (message: MessageAction) => void
	currentCalendar: CalendarData
	updateCurrentCalendar: (cal: CalendarData) => void
	calendarSettings: CalendarData | null
	updateCalendarSettings: (cal: CalendarData | null) => void
}

export const CalendarName = (props: CalendarNameProps) => {
	const [isNameDisabled, updateNameDisabled] = React.useState<boolean>(true)

	const postNameChange = async () => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			try {
				if (props.currentCalendar.fileName!.length > 0) {
					await Request.put(`calendar/${props.calendarSettings!.calendarID}`, props.calendarSettings, props.appState.authState)
					props.updateCurrentCalendar(props.calendarSettings!)
					props.updateMessages(Messages.addMessage('success', 'Succesfully updated calendar name'))
				} else {
					updateNameDisabled(false)
					props.updateMessages(Messages.addMessage('danger', 'Calendar name cannot be empty.'))
					return
				}
			} catch (err) {
				console.log('Update Error: ', err)
				props.updateMessages(Messages.addMessage('danger', 'Failed to update calendar name'))
			}
		} else {
			props.updateMessages(Messages.addMessage('danger', 'You do no have permission to update a calendar name'))
		}
	}
	const resetName = async () => {
		props.updateCurrentCalendar(props.currentCalendar)
	}

	return (
		<>
			<Form.Control
				type="text"
				id={props.calendarSettings!.calendarID!}
				name="name"
				placeholder=""
				readOnly={isNameDisabled}
				plaintext={isNameDisabled}
				onClick={() => updateNameDisabled(false)}
				onChange={(event) => {
					const value = event.target.value
					props.updateCalendarSettings({
						...props.calendarSettings,
						fileName: value,
					})
				}}
				value={props.calendarSettings!.fileName}
				style={{
					display: 'inline',
					width: '50%',
					paddingLeft: '12px',
					fontSize: '16px',
				}}
				className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : ''}
			/>
			{!isNameDisabled ? (
				<Button
					type="button"
					style={{ margin: '0px 10px 10px 10px', fontSize: '16px' }}
					variant="success"
					onClick={() => {
						updateNameDisabled(true)
						postNameChange()
					}}
				>
					Save
				</Button>
			) : null}
			{!isNameDisabled ? (
				<Button
					type="button"
					style={{ margin: '0px 5px 10px 5px', fontSize: '16px' }}
					onClick={() => {
						updateNameDisabled(true)
						resetName()
					}}
				>
					Cancel
				</Button>
			) : null}
		</>
	)
}
