import React from 'react'
import * as AppDefinitions from '../../../App.d'
import {
	AccountXeroOrg,
	Calendar,
	Calendar as CalendarData,
	EmployeeLeaveBalance,
	LeaveApplication,
	LeaveType,
} from '../../../../../back-end/utilities/apiDefinitions'
import { FormGroup } from 'react-bootstrap'
import * as RecordStatus from '../../../types/recordStatus'

// components
import * as Messages from '../../ui/messages/messages'
import LoadingImage from '../../../images/Finlert-loading-GIF_100px.gif'
import TeamDemographicsComponent from './teamDemographics'
import LeaveOverviewComponent from './leaveOverview'
import EmployeePerformanceOverview from './employeeOverview'

// utillites
import * as Request from '../../../utilities/request'
import moment from 'moment'

const pieColors = ['#86A581', '#E4B1F7', '#F89995', '#F8C950', '#8AA6FF']

const calculateUpcomingLeave = (totalFutureLeave: number, leaveApp: LeaveApplication) =>
	(totalFutureLeave += Number((moment(leaveApp.endDate!).valueOf() + 1000 * 3600 * 24 - moment(leaveApp.startDate!).valueOf()) / (1000 * 3600 * 24)) || 1) //we add a day in the difference so the end date is included

// eslint-disable-next-line
export const getDataForPieChart = (object: { [x: string]: any }, colorIndex: number) => {
	const data: { title: string; value: number; color: string }[] = []
	Object.keys(object).forEach((key: string, index: number) => {
		if (object[key] !== 0) {
			data.push({
				title: key,
				value: object[key],
				color: pieColors[colorIndex] + 15 * (index + 1),
			})
		}
	})
	return data
}

interface ObjectDetails {
	amount?: number
	name: string
	days?: number
}
interface DataObject {
	total?: number
	[key: string]: number | ObjectDetails | undefined
}
interface ChartData {
	type: 'bar'
	marker: { color: string }
	x: (number | string)[]
	y: (number | string)[]
	orientation: 'h'
	options: {
		scales: {
			y: { beginAtZero: boolean }
			x: { beginAtZero: boolean }
		}
	}
}

// eslint-disable-next-line
export const getDataForBarChart = (object: DataObject, colorIndex: number, showLeaveInDays: boolean, hoursInDay: number): ChartData => {
	const data: ChartData = {
		type: 'bar',
		marker: { color: pieColors[colorIndex] },
		x: [],
		y: [],
		orientation: 'h',
		options: {
			scales: {
				y: {
					beginAtZero: true,
				},
				x: {
					beginAtZero: true,
				},
			},
		},
	}
	Object.keys(object).forEach((key: string) => {
		const value = object[key]
		if (key === 'total') {
			data.y.push('Total')
			data.x.push(showLeaveInDays ? parseFloat(Math.fround((value as number) / hoursInDay).toFixed(2)) : (value as number))
		} else {
			if (typeof value === 'object' && value !== null && 'name' in value) {
				data.y.push(value.name)
				if (value.amount) {
					data.x.push(value.amount)
				} else {
					data.x.push(showLeaveInDays ? parseFloat(Math.fround(value.days! / hoursInDay).toFixed(2)) : value.days!)
				}
			} else {
				data.y.push(key)
				data.x.push(value!)
			}
		}
	})
	return { ...data, x: data.x.reverse(), y: data.y.reverse() }
}

export const getCalendarsWithBalances = (
	calendar: Calendar,
	leave: LeaveApplication[],
	leaveBalance: EmployeeLeaveBalance[],
	version: string,
	start?: Date,
	end?: Date
) => {
	const todaysDate = moment().tz(version) //.format('YYYY-MM-DD'))

	return {
		...calendar,
		employees: calendar.employees
			? calendar.employees
					.map((emp) => {
						const employeeLeave = leave.filter((leaveApp) => emp.employeeID === leaveApp.employeeID)
						const fromTodayOnwardsLeaves =
							start && end
								? employeeLeave.filter((leaveApp) => moment(leaveApp.startDate!).isBetween(start, end))
								: employeeLeave.filter((leaveApp) => moment(leaveApp.startDate!).isAfter(todaysDate))
						const associatedBalances = leaveBalance.filter((leaveBalance) => emp.employeeID === leaveBalance.employeeID)

						return {
							...emp,
							daysSinceLastLeave: 0, //not important here
							leaveBalance: associatedBalances
								.map((bal) => {
									const totalFutureLeave = fromTodayOnwardsLeaves.filter((leaveApp) => leaveApp.xeroOrgLeaveTypeID === bal.xeroOrgLeaveTypeID)
									return {
										...bal,
										corespondingLeaves: totalFutureLeave,
										upcoming: totalFutureLeave.reduce(calculateUpcomingLeave, 0),
									}
								})
								.sort((a, b) => a.leaveTypeName!.localeCompare(b.leaveTypeName!)),
						}
					})
					.filter((emp) => emp.leaveBalance.length > 0)
					.sort((a, b) => (a.firstName === b.firstName ? a.lastName!.localeCompare(b.lastName!) : a.firstName!.localeCompare(b.firstName!)))
			: [],
	}
}

interface OverviewComponentFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	xeroOrgCalendars: CalendarData[] | null
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

const OverviewComponent = (props: OverviewComponentFrameProps) => {
	const [messages, updateMessages] = Messages.useMessageReducer([])
	const [allLeaveApplications, updateAllLeaveApplications] = React.useState<LeaveApplication[] | []>([])
	const [allXeroOrgLeaveApplications, updateAllXeroOrgLeaveApplications] = React.useState<LeaveApplication[] | []>([])
	const [originalBalanceData, updateOriginalBalanceData] = React.useState<EmployeeLeaveBalance[]>([])
	const [originalXeroBalanceData, updateOriginalXeroBalanceData] = React.useState<EmployeeLeaveBalance[]>([])

	React.useEffect(
		() => {
			const fetchData = async () => {
				const uniqueEmployees = props.currentCalendar.employees!.map((e) => e.employeeID!)
				const leaveTypeIDs = props.currentCalendar.leaveTypes!.map((clt) => clt.xeroOrgLeaveTypeID)
				const [nonXeroLeaveTypes, calendarLeaveTypesData] = await Promise.all([
					Request.get(
						`leavetype?where=XeroOrgID==${props.currentOrg.xeroOrg!.xeroOrgID}&where=XeroOrgLeaveTypeIsNonXero==1`,
						props.appState.authState
					),
					Request.get(`calendar/${props.currentCalendar.calendarID}/leavetype`, props.appState.authState),
				])

				const nonXeroTypeIDs = nonXeroLeaveTypes.data.leaveTypes
					.filter(
						(lt: LeaveType) =>
							lt.isActive &&
							lt.xeroOrgID === props.currentOrg.xeroOrg!.xeroOrgID &&
							calendarLeaveTypesData.data.calendarLeaveTypes.some(
								(clt: { xeroOrgLeaveTypeID: string }) => clt.xeroOrgLeaveTypeID === lt.xeroOrgLeaveTypeID
							)
					)
					.map((clt: { xeroOrgLeaveTypeID: string }) => clt.xeroOrgLeaveTypeID)
				const allNonXeroTypeIDs = nonXeroLeaveTypes.data.leaveTypes
					.filter((lt: LeaveType) => lt.isActive && lt.xeroOrgID === props.currentOrg.xeroOrg!.xeroOrgID)
					.map((clt: { xeroOrgLeaveTypeID: string }) => clt.xeroOrgLeaveTypeID)
				const allXeroEmployeeIDs = props.xeroOrgCalendars!.reduce(
					(employeeIDs: string[], currentCal) => employeeIDs.concat(currentCal.employees!.map((e) => e.employeeID!)),
					[]
				)
				const allXeroLeaveTypeIDs = props.xeroOrgCalendars!.reduce(
					(leaveTypeIDs: string[], currentCal) => leaveTypeIDs.concat(currentCal.leaveTypes!.map((clt) => clt.xeroOrgLeaveTypeID!)),
					[]
				)
				const [leaveApp, xeroOrgLeaveApp, leaveBalanceData, leaveBalanceXeroData] = await Promise.all([
					Request.get(
						`leave?where=EmployeeID=in(${uniqueEmployees.join(',')})&where=XeroOrgLeaveTypeID=in(${leaveTypeIDs
							.concat(nonXeroTypeIDs)
							.join(',')}&where=RecordStatusID=in(${[RecordStatus.Active, RecordStatus.Archived].join(',')})&minify`,
						props.appState.authState
					),
					Request.get(
						`leave?where=EmployeeID=in(${allXeroEmployeeIDs.join(',')})&where=XeroOrgLeaveTypeID=in(${allXeroLeaveTypeIDs
							.concat(allNonXeroTypeIDs)
							.join(',')}&where=RecordStatusID=in(${[RecordStatus.Active, RecordStatus.Archived].join(',')})&minify`,
						props.appState.authState
					),
					Request.get(`leavebalance?where=EmployeeID=in(${uniqueEmployees.join(',')})`, props.appState.authState),
					Request.get(`leavebalance?where=EmployeeID=in(${allXeroEmployeeIDs.join(',')})`, props.appState.authState),
				])

				const activeEmployees = props.currentCalendar.employees!.filter((e) => e.isActive).map((e) => e.employeeID!)
				const allXeroActiveEmployees = props.xeroOrgCalendars!.reduce(
					(employeeIDs: string[], currentCal) => employeeIDs.concat(currentCal.employees!.filter((e) => e.isActive).map((e) => e.employeeID!)),
					[]
				)
				const leaves =
					leaveApp.data.leaveApplications.length > 0
						? leaveApp.data.leaveApplications
								.filter((lv: LeaveApplication) => activeEmployees.includes(lv.employeeID!))
								.map((leave: LeaveApplication) => leave)
						: []
				const allXeroOrgLeaves =
					xeroOrgLeaveApp.data.leaveApplications.length > 0
						? xeroOrgLeaveApp.data.leaveApplications
								.filter((lv: LeaveApplication) => allXeroActiveEmployees.includes(lv.employeeID!))
								.map((leave: LeaveApplication) => leave)
						: []
				updateAllLeaveApplications(leaves)
				updateAllXeroOrgLeaveApplications(allXeroOrgLeaves)
				updateOriginalBalanceData(leaveBalanceData.data.employeeLeaveBalances)
				updateOriginalXeroBalanceData(leaveBalanceXeroData.data.employeeLeaveBalances)
				if (props.show) {
					props.updatePageStatus('Finished')
				}
			}

			if (props.currentCalendar.calendarID !== '') {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.currentCalendar.calendarID, props.currentOrg]
	)

	if (!props.show) {
		return null
	}
	if (props.pageStatus !== 'Finished') {
		return (
			<div className="loading-gif">
				<img src={LoadingImage} alt="Loading ..." />
			</div>
		)
	}
	return (
		<>
			<FormGroup style={{ padding: '30px' }}>
				<TeamDemographicsComponent
					allLeaveApplications={allLeaveApplications}
					originalBalanceData={originalBalanceData}
					appState={props.appState}
					currentOrg={props.currentOrg}
					currentCalendar={props.currentCalendar}
				/>
				{/* <LeaveOverviewComponent
					allLeaveApplications={allLeaveApplications}
					originalBalanceData={originalBalanceData}
					appState={props.appState}
					currentOrg={props.currentOrg}
					currentCalendar={props.currentCalendar}
				/>
				<EmployeePerformanceOverview
					allLeaveApplications={allLeaveApplications}
					allXeroOrgLeaveApplications={allXeroOrgLeaveApplications}
					originalBalanceData={originalBalanceData}
					originalXeroOrgBalanceData={originalXeroBalanceData}
					appState={props.appState}
					currentOrg={props.currentOrg}
					xeroOrgCalendars={props.xeroOrgCalendars}
					currentCalendar={props.currentCalendar}
				/> */}
			</FormGroup>
			<Messages.Messages messages={messages} updateMessage={updateMessages} />
		</>
	)
}

export default OverviewComponent
