import React from 'react'

// components
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import Select, { SingleValue, MultiValue, ActionMeta, createFilter, components } from 'react-select'

import './Select.css'
import { Search } from 'react-feather'

interface Option<T> {
	label: T
	value: string
}
type OptionValue = SingleValue<Option<string>> | MultiValue<Option<string>>

interface FormSelectProps {
	name: string
	options: Option<string>[]
	label?: string
	required?: boolean
	feedback?: string
	isMulti?: boolean
	isDisabled?: boolean
	isClearable?: boolean
	loading?: boolean
	isSearchable?: boolean
	placeholder?: string | null | number | boolean | JSX.Element
	value: string | string[] | null | undefined
	onChange: (newValue: OptionValue, actionMeta?: ActionMeta<OptionValue>) => void
	onBlur?: React.FocusEventHandler<HTMLInputElement>
	labelCol?: number
	labelJustify?: 'start' | 'end' | 'center' | 'between' | 'around'
	disableSearchToNarrow?: boolean
}

// This has to be any to abuse object destructuring to remove the mouse over events that cause lag on large lists,
// otherwise components.Option complains that it's missing props (because technically it is)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomOption = (props: any) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { innerProps, isFocused, ...otherProps } = props
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps
	const newProps = { innerProps: { ...otherInnerProps }, ...otherProps }
	return (
		<components.Option {...newProps} className="custom-select-option">
			{props.children}
		</components.Option>
	)
}

// This has to be any because react select is going on Santa's naughty list
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MenuListOverride = (props: any) => {
	const children = React.useMemo(() => React.Children.toArray(props.children), [props.children])
	return (
		<components.MenuList {...props}>
			{children.length > 200 ? (
				<div className="center-flex p-1">
					<Search size={14} className="mr-2" />
					{'Begin typing to narrow results...'}
				</div>
			) : (
				props.children
			)}
		</components.MenuList>
	)
}

const FormSelect = (props: FormSelectProps) => {
	const { labelCol, labelJustify, disableSearchToNarrow, ...htmlProps } = props

	const selectedValue = props.value
		? props.options.filter((option) => (props.isMulti ? props.value?.includes(option.value) : option.value === props.value))
		: []

	const control = (
		<>
			{props.loading ? (
				<div className="loading-gif">
					<Spinner animation="border" />
				</div>
			) : (
				<Select
					{...htmlProps}
					value={selectedValue}
					filterOption={createFilter({ ignoreAccents: false })}
					components={{ Option: CustomOption, MenuList: disableSearchToNarrow ? undefined : MenuListOverride }}
					// Make select empty when searching if it already has a value (makes more apparent to user)
					styles={{
						singleValue: (base, state) => ({
							// TS def for the base CSS properties is too broad, any cast to stop singleValue complaining
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							...(base as any),
							display: state.selectProps.menuIsOpen ? 'none' : 'block',
						}),
					}}
				/>
			)}
			{!!props.feedback && <div className="invalid-feedback-custom">{props.feedback}</div>}
		</>
	)

	return (
		<Form.Group controlId={props.name} as={labelCol ? Row : undefined}>
			{props.label ? (
				<Form.Label
					column={labelCol ? true : undefined}
					sm={labelCol || undefined}
					className={labelJustify ? `d-flex justify-content-${labelJustify}` : undefined}
				>
					{props.label}
					{props.required ? ' *' : ''}
				</Form.Label>
			) : null}
			{labelCol ? <Col sm={12 - labelCol}>{control}</Col> : control}
		</Form.Group>
	)
}

export { FormSelect, CustomOption, MenuListOverride }
export type SelectOption<T> = Option<T>
export type MultiOption<T> = MultiValue<Option<T>>
