import React from 'react'
import { Button, ButtonGroup, Col, DropdownButton, Form, FormLabel, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { Trash2, X } from 'react-feather'
import { Formik } from 'formik'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { FormSelect, SelectOption, MultiOption } from '../../ui/form/Select'
import { FormText } from '../../ui/form/Text'

import { MessageAction } from '../../ui/messages/message'
import { AppState } from '../../../App.d'
import { LeaveRule, LeaveType } from '../../../../../back-end/utilities/apiDefinitions'
import { errorMsgFromFormik } from '../../../utilities/form'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import { FormDate } from '../../ui/form/Date'

interface LeaveRuleCardProps {
	rule: LeaveRule
	appState: AppState
	removeRule: (rule: LeaveRule) => void
	hasLeaveBalanceProduct: boolean
	leaveTypes: LeaveType[]
	handleSubmit: (leaveRule: LeaveRule) => void
	handleCancel: (leaveRule: LeaveRule) => void
	handleUpdate: (leaveRule: LeaveRule) => void
	updateMessages: (action: MessageAction) => void
}

const signs = ['<', '<=', '>', '>=', '=']
const dateTest = (dateString: string | undefined) => (dateString ? DateTime.fromISO(dateString).isValid : false)
const leaveRuleSchema = yup
	.object()
	.shape({
		calendarID: yup.string().uuid().required(),
		name: yup.string().required().max(200),
		action: yup.string().oneOf(['approve', 'reject']).required(),
		leaveType: yup
			.object()
			.shape({
				types: yup.array().of(yup.string()).required().min(1),
			})
			.nullable(),
		leaveBalance: yup
			.object()
			.shape({
				sign: yup.string().oneOf(signs).required(),
				value: yup.number().required(),
			})
			.nullable(),
		duration: yup
			.object()
			.shape({
				sign: yup.string().oneOf(signs).required(),
				value: yup.number().required(),
			})
			.nullable(),
		dateRange: yup
			.object()
			.shape({
				fromDate: yup.string().test('isDate', 'from date must be a valid date', dateTest).required(),
				toDate: yup
					.string()
					.test('isDate', 'to date must be a valid date', dateTest)
					.required()
					.test('isLater', 'to date must be after from date', (value: string | undefined, testContext) =>
						value ? DateTime.fromISO(value) < DateTime.fromISO(testContext.parent.fromDate) : false
					),
			})
			.nullable(),
		relativeDate: yup
			.object()
			.shape({
				sign: yup.string().oneOf(signs).required(),
				value: yup.number().required(),
			})
			.nullable(),
	})
	.test('one-required', 'one condition must be set', ({ leaveType, leaveBalance, duration, dateRange, relativeDate }, ctx) => {
		const isValid = !(leaveType === null && leaveBalance === null && duration === null && dateRange === null && relativeDate === null)
		if (isValid) {
			return true
		}
		return ctx.createError({
			path: 'leaveRuleID',
			message: 'One condition must be set',
		})
	})

const signOptions = [
	{
		label: '<',
		value: '<',
	},
	{
		label: '≤',
		value: '<=',
	},
	{
		label: '>',
		value: '>',
	},
	{
		label: '≥',
		value: '>=',
	},
	{
		label: '=',
		value: '=',
	},
]
const relativeDateOptions = [
	{
		label: 'Yesterday',
		value: '-1',
	},
	{
		label: 'Today',
		value: '0',
	},
	{
		label: 'Tomorrow',
		value: '1',
	},
	{
		label: 'Next Week',
		value: '7',
	},
	{
		label: 'Next Month',
		value: '30',
	},
	{
		label: 'Next Quarter',
		value: '90',
	},
]

const actionToText = (action: 'approve' | 'reject') => (action === 'approve' ? 'Auto-approve Leave Request' : 'Auto-reject leave request')
const leaveTypeToText = (leaveType: LeaveRule['leaveType'], leaveTypes: LeaveType[]) =>
	leaveType
		? `leave type is ${
				leaveType.types.length > 1
					? `one of ${leaveType.types
							.slice(0, -1)
							.map((lt) => leaveTypes.find((dblt) => dblt.xeroOrgLeaveTypeID === lt)?.name)
							.join(', ')} or ${leaveTypes.find((dblt) => dblt.xeroOrgLeaveTypeID === leaveType.types.at(-1))?.name}`
					: leaveTypes.find((dblt) => dblt.xeroOrgLeaveTypeID === leaveType.types.at(0))?.name
		  }`
		: ''
const leaveBalanceToText = (leaveBalance: LeaveRule['leaveBalance']) =>
	leaveBalance ? `leave balance remaining after approval is ${signToText(leaveBalance.sign)} ${leaveBalance.value}` : ''
const durationToText = (duration: LeaveRule['duration']) => (duration ? `duration of leave is ${signToText(duration.sign)} ${duration.value}` : '')
const dateRangeToText = (dateRange: LeaveRule['dateRange']) =>
	dateRange
		? `leave is during dates ${DateTime.fromISO(dateRange.fromDate).toFormat('yyyy-MM-dd')} and ${DateTime.fromISO(dateRange.toDate).toFormat(
				'yyyy-MM-dd'
		  )}`
		: ''
const relativeDateToText = (relativeDate: LeaveRule['relativeDate']) =>
	relativeDate ? `leave start date is ${signToText(relativeDate.sign)} ${relativeDate.value} days from request date` : ''
const signToText = (sign: string) => {
	switch (sign) {
		case '<':
			return 'less than'
		case '<=':
			return 'less than or equal to'
		case '>':
			return 'greater than'
		case '>=':
			return 'greater than or equal to'
		case '=':
			return 'equal to'
	}
}

const LeaveRuleCard = (props: LeaveRuleCardProps) => {
	const [isEditing, updateIsEditing] = React.useState<boolean>(props.rule.leaveRuleID.includes('new'))

	if (!isEditing) {
		return (
			<div onClick={() => updateIsEditing(true)} className="leave-rule-read-only">
				<h3>{props.rule.name}</h3>
				<p>
					{actionToText(props.rule.action)} when {leaveTypeToText(props.rule.leaveType, props.leaveTypes)}
					{(props.rule.leaveBalance || props.rule.duration || props.rule.dateRange || props.rule.relativeDate) &&
					props.rule.leaveBalance &&
					leaveTypeToText(props.rule.leaveType, props.leaveTypes) !== ''
						? ' and '
						: ''}
					{leaveBalanceToText(props.rule.leaveBalance)}
					{(props.rule.duration || props.rule.dateRange || props.rule.relativeDate) && props.rule.duration ? ' and ' : ''}
					{durationToText(props.rule.duration)}
					{(props.rule.dateRange || props.rule.relativeDate) && props.rule.dateRange ? ' and ' : ''}
					{dateRangeToText(props.rule.dateRange)}
					{props.rule.relativeDate && props.rule.relativeDate ? ' and ' : ''}
					{relativeDateToText(props.rule.relativeDate)}
				</p>
			</div>
		)
	}

	return (
		<Formik
			initialValues={props.rule}
			validationSchema={leaveRuleSchema}
			onSubmit={props.rule.leaveRuleID.includes('new') ? props.handleSubmit : props.handleUpdate}
		>
			{({ values, errors, touched, handleChange, isSubmitting, resetForm, setFieldValue, submitForm }) => (
				<div className="leave-rule-editing">
					<Row className="leavecal-wrap-style">
						<Col sm="1">
							<FormLabel>Name</FormLabel>
						</Col>
						<Col sm="8">
							<FormText
								name="name"
								value={values.name}
								onChange={handleChange}
								feedback={errorMsgFromFormik(touched, errors, `name`)}
								isInvalid={!!errorMsgFromFormik(touched, errors, `name`)}
							/>
						</Col>
						<Col></Col>
						<Col sm="auto">
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-alert`}>Remove rule.</Tooltip>}>
								<Trash2
									style={{ cursor: 'pointer', paddingTop: '5px', width: '30px', height: '30px', textAlign: 'right' }}
									onClick={() => props.removeRule(props.rule)}
								/>
							</OverlayTrigger>
						</Col>
					</Row>
					<Row>
						<Col sm={{ offset: 1, span: 8 }}>
							<FormSelect
								name="action"
								options={[
									{ label: 'Auto Approve Leave Request', value: 'approve' },
									{ label: 'Auto Reject Leave Request', value: 'reject' },
								]}
								value={values.action}
								onChange={(e) => {
									const value = e ? (e as SelectOption<string>).value : ''
									setFieldValue('action', value)
								}}
								feedback={errorMsgFromFormik(touched, errors, 'action')}
								isDisabled={!isEditing}
							/>
						</Col>
						<Col sm="auto">
							<p>when</p>
						</Col>
						<Col></Col>
					</Row>
					{values.leaveType && (
						<Row>
							<Col sm="1">
								<p>leave type is one of</p>
							</Col>
							<Col sm="8">
								<FormSelect
									name="leaveType.types"
									options={props.leaveTypes.map((leaveType) => ({
										...leaveType,
										label: leaveType.name!,
										value: leaveType.xeroOrgLeaveTypeID!,
									}))}
									value={values.leaveType.types}
									onChange={(e) => {
										const values = e ? (e as MultiOption<string>).map((val) => val.value) : []
										setFieldValue('leaveType.types', values)
									}}
									feedback={errorMsgFromFormik(touched, errors, 'leaveType.types')}
									isMulti={true}
								/>
							</Col>
							{(values.leaveBalance || values.duration || values.dateRange || values.relativeDate) && (
								<Col sm="auto">
									<p>and</p>
								</Col>
							)}
							<Col></Col>
							<Col sm="auto">
								<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-alert`}>Remove condition.</Tooltip>}>
									<X
										style={{ cursor: 'pointer', paddingTop: '5px', width: '30px', height: '30px', textAlign: 'right' }}
										onClick={() => setFieldValue('leaveType', null)}
									/>
								</OverlayTrigger>
							</Col>
						</Row>
					)}
					{values.leaveBalance && (
						<Row>
							<Col sm="1">
								<p>leave balance remaining after approval is</p>
							</Col>
							<Col sm="2">
								<InputGroup>
									<FormSelect
										name="leaveBalance.sign"
										options={signOptions}
										value={values.leaveBalance.sign}
										onChange={(e) => {
											const value = e ? (e as SelectOption<string>).value : []
											setFieldValue('leaveBalance.sign', value)
										}}
										feedback={errorMsgFromFormik(touched, errors, 'leaveBalance.sign')}
									/>
									<FormText
										name="leaveBalance.value"
										labelCol={0}
										value={values.leaveBalance.value}
										onChange={handleChange}
										feedback={errorMsgFromFormik(touched, errors, `leaveBalance.value`)}
										isInvalid={!!errorMsgFromFormik(touched, errors, `leaveBalance.value`)}
									/>
								</InputGroup>
							</Col>
							{(values.duration || values.dateRange || values.relativeDate) && (
								<Col sm="auto">
									<p>and</p>
								</Col>
							)}
							<Col></Col>
							<Col sm="auto">
								<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-alert`}>Remove condition.</Tooltip>}>
									<X
										style={{ cursor: 'pointer', paddingTop: '5px', width: '30px', height: '30px', textAlign: 'right' }}
										onClick={() => setFieldValue('leaveBalance', null)}
									/>
								</OverlayTrigger>
							</Col>
						</Row>
					)}
					{values.duration && (
						<Row>
							<Col sm="1">
								<p>duration of leave is</p>
							</Col>
							<Col sm="2">
								<InputGroup>
									<FormSelect
										name="duration.sign"
										options={signOptions}
										value={values.duration.sign}
										onChange={(e) => {
											const value = e ? (e as SelectOption<string>).value : ''
											setFieldValue('duration.sign', value)
										}}
										feedback={errorMsgFromFormik(touched, errors, 'duration.sign')}
									/>
									<FormText
										name="duration.value"
										labelCol={0}
										value={values.duration.value}
										onChange={handleChange}
										feedback={errorMsgFromFormik(touched, errors, `duration.value`)}
										isInvalid={!!errorMsgFromFormik(touched, errors, `duration.value`)}
									/>
								</InputGroup>
							</Col>
							<Col sm="auto">
								<p>long {(values.dateRange || values.relativeDate) && 'and'}</p>
							</Col>
							<Col></Col>
							<Col sm="auto">
								<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-alert`}>Remove condition.</Tooltip>}>
									<X
										style={{ cursor: 'pointer', paddingTop: '5px', width: '30px', height: '30px', textAlign: 'right' }}
										onClick={() => setFieldValue('duration', null)}
									/>
								</OverlayTrigger>
							</Col>
						</Row>
					)}
					{values.dateRange && (
						<Row>
							<Col sm="1">
								<p>leave is during dates</p>
							</Col>
							<Col sm="3">
								<InputGroup>
									<FormDate
										name="dateRange.fromDate"
										value={values.dateRange.fromDate}
										onChange={handleChange}
										min={DateTime.now().plus({ days: 1 }).toISODate()}
									/>
									<p>and</p>
									<FormDate
										name="dateRange.toDate"
										value={values.dateRange.toDate}
										onChange={handleChange}
										min={DateTime.fromISO(values.dateRange.fromDate).toISODate() || DateTime.now().plus({ days: 1 }).toISODate()}
									/>
								</InputGroup>
							</Col>
							{values.relativeDate && (
								<Col sm="auto">
									<p>and</p>
								</Col>
							)}
							<Col></Col>
							<Col sm="auto">
								<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-alert`}>Remove condition.</Tooltip>}>
									<X
										style={{ cursor: 'pointer', paddingTop: '5px', width: '30px', height: '30px', textAlign: 'right' }}
										onClick={() => setFieldValue('dateRange', null)}
									/>
								</OverlayTrigger>
							</Col>
						</Row>
					)}
					{values.relativeDate && (
						<Row>
							<Col sm="1">
								<p>leave start date is </p>
							</Col>
							<Col sm="2">
								<InputGroup>
									<FormSelect
										name="relativeDate.sign"
										options={signOptions}
										value={values.relativeDate.sign}
										onChange={(e) => {
											const value = e ? (e as SelectOption<string>).value : ''
											setFieldValue('relativeDate.sign', value)
										}}
										feedback={errorMsgFromFormik(touched, errors, 'relativeDate.sign')}
									/>
									<FormSelect
										name="relativeDate.value"
										options={relativeDateOptions}
										value={String(values.relativeDate.value)}
										onChange={(e) => {
											const value = e ? (e as SelectOption<string>).value : ''
											setFieldValue('relativeDate.value', Number(value))
										}}
										feedback={errorMsgFromFormik(touched, errors, 'relativeDate.value')}
									/>
								</InputGroup>
							</Col>
							<Col sm="auto">
								<p>days from request date</p>
							</Col>
							<Col></Col>
							<Col sm="auto">
								<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-alert`}>Remove condition.</Tooltip>}>
									<X
										style={{ cursor: 'pointer', paddingTop: '5px', width: '30px', height: '30px', textAlign: 'right' }}
										onClick={() => setFieldValue('relativeDate', null)}
									/>
								</OverlayTrigger>
							</Col>
						</Row>
					)}
					<Row>
						<Col sm={{ offset: 1, span: 1 }}>
							<DropdownButton as={ButtonGroup} title="Add New Condition" id="bg-nested-dropdown">
								<DropdownItem as="button" onClick={() => setFieldValue('leaveType', { types: [] })} disabled={values.leaveType !== null}>
									Leave Types
								</DropdownItem>
								<DropdownItem
									as="button"
									onClick={() => setFieldValue('leaveBalance', { sign: '>=', value: 0 })}
									disabled={values.leaveBalance !== null}
								>
									Leave Balance
								</DropdownItem>
								<DropdownItem
									as="button"
									onClick={() => setFieldValue('duration', { sign: '<=', value: 16 })}
									disabled={values.duration !== null}
								>
									Leave Duration
								</DropdownItem>
								<DropdownItem
									as="button"
									onClick={() =>
										setFieldValue('dateRange', {
											fromDate: DateTime.now().toFormat('yyyy-MM-dd'),
											toDate: DateTime.now().toFormat('yyyy-MM-dd'),
										})
									}
									disabled={values.dateRange !== null}
								>
									Leave Date Range
								</DropdownItem>
								<DropdownItem
									as="button"
									onClick={() => setFieldValue('relativeDate', { sign: '<=', value: 0 })}
									disabled={values.relativeDate !== null}
								>
									Leave Relative Date
								</DropdownItem>
							</DropdownButton>
						</Col>
						{errors.leaveRuleID && (
							<Col>
								<Form.Control.Feedback type="invalid">{errors.leaveRuleID}</Form.Control.Feedback>
							</Col>
						)}
					</Row>

					<Row>
						<Col sm="1"></Col>
						<Col sm="auto" style={{ marginTop: '7px' }}>
							<Button
								type="submit"
								disabled={isSubmitting}
								variant="info"
								onClick={() => {
									submitForm()
									updateIsEditing(false)
								}}
							>
								{values.leaveRuleID.includes('new') ? 'Save' : 'Finish Editing'}
							</Button>
						</Col>
						<Col sm="auto" style={{ marginTop: '7px' }}>
							<Button
								type="button"
								disabled={isSubmitting}
								onClick={(event) => {
									event.stopPropagation()
									updateIsEditing(false)
									if (props.rule.leaveRuleID.includes('new')) {
										props.handleCancel(values)
									} else {
										resetForm()
									}
								}}
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</div>
			)}
		</Formik>
	)
}

export default LeaveRuleCard
