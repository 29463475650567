import React from 'react'
import { Col, Form, FormControlProps, Row } from 'react-bootstrap'

interface FormTextProps extends FormControlProps {
	name: string
	label?: string
	note?: string
	required?: boolean
	feedback?: string
	rows?: number
	rightSideContent?: React.JSX.Element
	labelCol?: number
	labelJustify?: 'start' | 'end' | 'center' | 'between' | 'around'
	alignCenter?: boolean
	placeholder?: string
}

const FormText = (props: FormTextProps) => {
	const { rightSideContent, labelCol, labelJustify, alignCenter, ...formProps } = props

	const control = (
		<>
			<Row>
				<Col>{props.readOnly ? <span>{props.value}</span> : <Form.Control {...formProps} placeholder={props.placeholder} />}</Col>
				{rightSideContent && <Col sm="auto">{rightSideContent}</Col>}
			</Row>
			{formProps.feedback ? <Form.Control.Feedback type="invalid">{formProps.feedback}</Form.Control.Feedback> : null}
		</>
	)

	return (
		<Form.Group
			controlId={formProps.name}
			as={labelCol !== undefined ? Row : undefined}
			className={labelCol !== undefined && (props.readOnly || alignCenter) ? 'align-items-center' : undefined}
		>
			{formProps.label ? (
				<Form.Label
					column={labelCol !== undefined ? true : undefined}
					sm={labelCol || undefined}
					className={labelJustify ? `d-flex justify-content-${labelJustify}` : undefined}
				>
					{`${formProps.label}${formProps.required ? ' *' : ''}`}
					{formProps.note ? <span className="text-muted"> {formProps.note}</span> : null}
				</Form.Label>
			) : null}

			{labelCol !== undefined ? <Col sm={12 - labelCol}>{control}</Col> : control}
		</Form.Group>
	)
}

export { FormText }
